import React from 'react';
import {
  ConfigField,
  Label,
  Input,
  Select,
  Button,
  ColumnList,
  ColumnItem,
  SmallButton,
  ColorInput,
  IconInputWrapper,
  IconPreview,
  IconInput,
  IconSelectorWrapper
} from './shared/ConfigurationStyles';
import IconSelector from './IconSelector';
import DataSourceButton from './shared/DataSourceButton';
import DataSourceSelect from './shared/DataSourceSelect';

const TableConfiguration = ({ tempConfig, handleChange, handleColumnChange, addColumn, removeColumn }) => {
  return (
    <>
      <DataSourceSelect 
        selectedSource={tempConfig.dataSource}
        onSourceSelect={(source) => handleChange('dataSource', source)}
      />
      <ConfigField>
        <Label>Title</Label>
        <Input
          type="text"
          value={tempConfig?.title || ''}
          onChange={(e) => handleChange('title', e.target.value)}
        />
      </ConfigField>

      <ConfigField>
        <Label>Icon</Label>
        <IconSelectorWrapper>
          <IconSelector
            currentIcon={tempConfig.icon}
            onSelectIcon={(icon) => handleChange('icon', icon)}
          />
        </IconSelectorWrapper>
      </ConfigField>

      <ConfigField>
        <Label>Color</Label>
        <ColorInput
          type="color"
          value={tempConfig.color}
          onChange={(e) => handleChange('color', e.target.value)}
        />
      </ConfigField>

      <ConfigField>
        <Label>Columns</Label>
        <Button className="secondary" onClick={addColumn}>
          <span className="material-icons">add</span>
          Add Column
        </Button>
        <ColumnList>
          {tempConfig?.columns?.map((column, index) => (
            <ColumnItem key={index}>
              <Input
                type="text"
                placeholder="Key"
                value={column.key}
                onChange={(e) => handleColumnChange(index, 'key', e.target.value)}
              />
              <Input
                type="text"
                placeholder="Label"
                value={column.label}
                onChange={(e) => handleColumnChange(index, 'label', e.target.value)}
              />
              <Select
                value={column.type}
                onChange={(e) => handleColumnChange(index, 'type', e.target.value)}
              >
                <option value="text">Text</option>
                <option value="currency">Currency</option>
                <option value="date">Date</option>
                <option value="status">Status</option>
              </Select>
              <SmallButton 
                className="secondary" 
                onClick={() => removeColumn(index)}
              >
                <span className="material-icons">delete</span>
              </SmallButton>
            </ColumnItem>
          ))}
        </ColumnList>
      </ConfigField>
    </>
  );
};

export default TableConfiguration;