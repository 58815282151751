import styled from '@emotion/styled';

export const ConfigField = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  font-size: 1rem;
`;

export const Button = styled.button`
  padding: 0.75rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-height: 40px;
  transition: background-color 0.3s ease;
  
  &.primary {
    background: #007bff;
    color: white;
    
    &:hover {
      background: #0056b3;
    }
  }
  
  &.secondary {
    background: #f8f9fa;
    border: 1px solid #ddd;
    
    &:hover {
      background: #e9ecef;
    }
  }
`;

export const ColorInput = styled(Input)`
  width: 100px;
  height: 40px;
  padding: 0.25rem;
  cursor: pointer;
`;

export const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

export const FieldItem = styled.div`
  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const StepItem = styled.div`
  background: #ffffff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const ColumnItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 4px;

  input, select {
    flex: 1;
  }

  button {
    flex-shrink: 0;
  }
`;

export const SmallButton = styled(Button)`
  padding: 0.25rem;
  min-width: 32px;
  height: 32px;
`;

export const IconInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const IconPreview = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 4px;
  
  .material-icons {
    font-size: 24px;
    color: ${props => props.color || '#666'};
  }
`;

export const IconInput = styled(Input)`
  flex: 1;
`;

export const IconSelectorWrapper = styled.div`
  position: relative;
  width: 100%;

  .icon-selector {
    width: 100%;
  }

  .icon-display {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    justify-content: space-between;

    &:hover {
      border-color: #007bff;
    }
  }

  .icon-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 300px;
    z-index: 99999;
  }

  .icon-search {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .icon-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
    padding: 0.5rem;
    max-height: 300px;
    overflow-y: auto;
  }

  .icon-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background: #f5f5f5;
    }
  }
`;
