import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import FormConfiguration from './FormConfiguration';
import TableConfiguration from './TableConfiguration';
import StatisticsConfiguration from './StatisticsConfiguration';
import TextConfiguration from './TextConfiguration';
import TodoListConfiguration from './TodoListConfiguration';
import SummaryConfiguration from './SummaryConfiguration';

const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 500px;
  background: #ffffff;
  box-shadow: -2px 0 8px rgba(0,0,0,0.1);
  padding: 1.5rem;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
`;

const ConfigField = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const ColorInput = styled(Input)`
  width: 100px;
  padding: 0.25rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid #eee;
`;

const Button = styled.button`
  flex: 1;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-height: 40px;
  
  &.primary {
    background: #007bff;
    color: white;
    
    &:hover {
      background: #0056b3;
    }
  }
  
  &.secondary {
    background: #f8f9fa;
    border: 1px solid #ddd;
    
    &:hover {
      background: #e9ecef;
    }
  }
`;

const ColumnList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const ColumnItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 4px;

  input, select {
    flex: 1;
  }

  button {
    flex-shrink: 0;
  }
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
`;

const SmallButton = styled(Button)`
  padding: 0.25rem;
  min-width: 32px;
  height: 32px;
`;

const DeleteButton = styled(Button)`
  background: #fee2e2;
  color: #991b1b;
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  flex: none;
  
  &:hover {
    background: #fecaca;
  }
`;

const FieldList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const FieldItem = styled.div`
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 1rem;
`;

const StepList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const StepItem = styled.div`
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 1rem;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  background: white;
  border: 2px dashed #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.5rem;

  &:hover {
    border-color: #999;
  }
`;

const ConfigurationSidebar = ({ widget, onUpdate, onClose, onDelete }) => {
  const [tempConfig, setTempConfig] = useState(widget?.config || {});

  useEffect(() => {
    if (widget?.config) {
      setTempConfig(widget.config);
    }
  }, [widget]);

  const handleChange = (field, value) => {
    setTempConfig(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleColumnChange = (index, field, value) => {
    if (!tempConfig?.columns) return;
    const newColumns = [...tempConfig.columns];
    newColumns[index] = { ...newColumns[index], [field]: value };
    handleChange('columns', newColumns);
  };

  const addColumn = () => {
    const newColumns = [
      ...(tempConfig?.columns || []),
      {
        key: `column${(tempConfig?.columns?.length || 0) + 1}`,
        label: 'New Column',
        type: 'text'
      }
    ];
    handleChange('columns', newColumns);
  };

  const removeColumn = (index) => {
    if (!tempConfig?.columns) return;
    const newColumns = tempConfig.columns.filter((_, i) => i !== index);
    handleChange('columns', newColumns);
  };

  const handleAddStep = () => {
    setTempConfig(prev => ({
      ...prev,
      steps: [
        ...prev.steps,
        {
          title: `Step ${prev.steps.length + 1}`,
          fields: []
        }
      ]
    }));
  };

  const handleAddField = (stepIndex) => {
    const newField = {
      id: `field-${Date.now()}`,
      type: 'text',
      label: 'New Field',
      placeholder: '',
      required: false
    };

    setTempConfig(prev => ({
      ...prev,
      steps: prev.steps.map((step, idx) =>
        idx === stepIndex ? {
          ...step,
          fields: [...step.fields, newField]
        } : step
      )
    }));
  };

  const handleStepChange = (stepIndex, field, value) => {
    setTempConfig(prev => ({
      ...prev,
      steps: prev.steps.map((step, idx) =>
        idx === stepIndex ? {
          ...step,
          [field]: value
        } : step
      )
    }));
  };

  const handleFieldChange = (stepIndex, fieldIndex, field, value) => {
    setTempConfig(prev => ({
      ...prev,
      steps: prev.steps.map((step, sIdx) =>
        sIdx === stepIndex ? {
          ...step,
          fields: step.fields.map((f, fIdx) =>
            fIdx === fieldIndex ? {
              ...f,
              [field]: value
            } : f
          )
        } : step
      )
    }));
  };

  const handleDeleteStep = (stepIndex) => {
    setTempConfig(prev => ({
      ...prev,
      steps: prev.steps.filter((_, idx) => idx !== stepIndex)
    }));
  };

  const handleDeleteField = (stepIndex, fieldIndex) => {
    setTempConfig(prev => ({
      ...prev,
      steps: prev.steps.map((step, sIdx) =>
        sIdx === stepIndex ? {
          ...step,
          fields: step.fields.filter((_, fIdx) => fIdx !== fieldIndex)
        } : step
      )
    }));
  };

  const handleSave = () => {
    const updatedWidget = {
      ...widget,
      config: tempConfig
    };
    onUpdate(widget.id, updatedWidget);
    onClose();
  };

  const handleDiscard = () => {
    onClose();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this widget?')) {
      onDelete(widget.id);
      onClose();
    }
  };

  const handleDataSourceConnect = (widgetType) => {
    console.log(`Connecting data source for ${widgetType}`);
  };

  if (!widget) return null;

  const renderWidgetSpecificFields = () => {
    switch (widget.type) {
      case 'Form':
        return (
          <FormConfiguration
            tempConfig={tempConfig}
            handleChange={handleChange}
            handleAddStep={handleAddStep}
            handleAddField={handleAddField}
            handleStepChange={handleStepChange}
            handleFieldChange={handleFieldChange}
            handleDeleteStep={handleDeleteStep}
            handleDeleteField={handleDeleteField}
            onDataSourceConnect={() => handleDataSourceConnect('Form')}
          />
        );
      case 'Table':
        return (
          <TableConfiguration
            tempConfig={tempConfig}
            handleChange={handleChange}
            handleColumnChange={handleColumnChange}
            addColumn={addColumn}
            removeColumn={removeColumn}
            onDataSourceConnect={() => handleDataSourceConnect('Table')}
          />
        );
      case 'Statistics':
        return (
          <StatisticsConfiguration
            tempConfig={tempConfig}
            handleChange={handleChange}
            onDataSourceConnect={() => handleDataSourceConnect('Statistics')}
          />
        );
      case 'Text':
        return (
          <TextConfiguration
            tempConfig={tempConfig}
            handleChange={handleChange}
          />
        );
      case 'TodoList':
        return (
          <TodoListConfiguration
            tempConfig={tempConfig}
            handleChange={handleChange}
          />
        );
      case 'Summary':
        return (
          <SummaryConfiguration
            tempConfig={tempConfig}
            handleChange={handleChange}
            onDataSourceConnect={() => handleDataSourceConnect('Summary')}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Sidebar>
      <Header>
        <h3>Configure Widget</h3>
        <button
          onClick={handleDiscard}
          style={{
            border: 'none',
            background: 'none',
            padding: '0.5rem',
            cursor: 'pointer',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background 0.2s'
          }}
          onMouseEnter={e => e.target.style.background = '#f1f3f5'}
          onMouseLeave={e => e.target.style.background = 'none'}
        >
          <span className="material-icons" style={{ color: '#666' }}>close</span>
        </button>
      </Header>

      {renderWidgetSpecificFields()}

      <ButtonGroup>
        <Button
          className="secondary"
          onClick={handleDiscard}
          style={{ height: '40px' }}
        >
          <span className="material-icons">close</span>
          Discard
        </Button>
        <Button
          className="primary"
          onClick={handleSave}
          style={{ height: '40px' }}
        >
          <span className="material-icons">save</span>
          Save Changes
        </Button>
      </ButtonGroup>

      <DeleteButton
        onClick={handleDelete}
        style={{ height: '40px' }}
      >
        <span className="material-icons">delete</span>
        Delete Widget
      </DeleteButton>
    </Sidebar>
  );
};

export default ConfigurationSidebar;
