import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DashboardIllustration from './DashboardIllustration';
import { supabase } from '../supabase';

const LoginContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background: #f5f5f5;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const IllustrationColumn = styled.div`
  flex: 1.6;
  background: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const LoginColumn = styled.div`
  flex: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const LoginCard = styled.div`
  background: transparent;
  padding: 2rem;
  width: 100%;
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Button = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s;
  
  &:hover {
    background: #0056b3;
  }
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  margin-top: 1rem;
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [attempts, setAttempts] = useState(0);
  const [lastAttempt, setLastAttempt] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Rate limiting
    if (attempts >= 5 && Date.now() - lastAttempt < 15 * 60 * 1000) {
      setError('Too many attempts. Please try again later.');
      return;
    }
    
    setIsLoading(true);
    setError('');

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      
      if (error) throw error;

      if (data?.session) {
        navigate('/', { replace: true });
      }
    } catch (error) {
      console.warn('Authentication failed:', error.message);  // Generic logging
      setError('Invalid credentials');  // Generic error message
      setAttempts(prev => prev + 1);
      setLastAttempt(Date.now());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginContainer>
      <IllustrationColumn>
        <DashboardIllustration />
      </IllustrationColumn>
      <LoginColumn>
        <LoginCard>
          <Title>Login</Title>
          <Form onSubmit={handleSubmit}>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Button type="submit" disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Login'}
            </Button>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Form>
        </LoginCard>
      </LoginColumn>
    </LoginContainer>
  );
};

export default Login;
