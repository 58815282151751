import React from 'react';
import styled from '@emotion/styled';

const ConnectButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background: #f0f9ff;
  color: #0369a1;
  border: 1px dashed #0369a1;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 1.5rem;
  transition: all 0.2s;

  &:hover {
    background: #e0f2fe;
  }

  &.connected {
    background: #f0fdf4;
    color: #166534;
    border: 1px solid #166534;
  }
`;

const DataSourceButton = ({ isConnected, onConnect }) => {
  return (
    <ConnectButton 
      className={isConnected ? 'connected' : ''} 
      onClick={onConnect}
    >
      <span className="material-icons">
        {isConnected ? 'link' : 'add_link'}
      </span>
      {isConnected ? 'Connected to Data Source' : 'Connect to Data Source'}
    </ConnectButton>
  );
};

export default DataSourceButton;