import React from 'react';
import styled from '@emotion/styled';

const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 2rem;

  &:hover .actions {
    opacity: 1;
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.5rem;
  border-radius: 4px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;

  &:hover {
    color: #e0e0e0;
  }

  .material-icons {
    font-size: 1.2rem;
  }
`;

const LogoDisplay = ({ logo, settings, isEditing, onEdit, onReplace, onRemove }) => {
    const getFilterString = () => {
        const filters = settings?.filters || {};
        return `
      invert(${filters.invert || 0}%)
      brightness(${filters.brightness || 100}%)
      contrast(${filters.contrast || 100}%)
      saturate(${filters.saturate || 100}%)
    `;
    };

    return (
        <LogoContainer>
            <img
                src={logo}
                alt="Dashboard Logo"
                style={{
                    maxWidth: settings?.width || '100%',
                    height: settings?.height || '40px',
                    padding: settings?.padding || '0',
                    filter: getFilterString(),
                    objectFit: 'contain'
                }}
            />
            {isEditing && (
                <Actions className="actions">
                    <ActionButton onClick={onEdit}>
                        <span className="material-icons">tune</span>
                        Edit
                    </ActionButton>
                    <ActionButton onClick={onReplace}>
                        <span className="material-icons">edit</span>
                        Replace
                    </ActionButton>
                    <ActionButton onClick={onRemove}>
                        <span className="material-icons">delete</span>
                        Remove
                    </ActionButton>
                </Actions>
            )}
        </LogoContainer>
    );
};

export default LogoDisplay;