import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
  margin: 0 0.25rem;

  &:hover {
  background: rgba(255, 255, 255, 0.5);
    border-color: #999;
  }

  .material-icons {
    font-size: 1.5rem;
    color: #666;
  }
`;

const AddWidgetButton = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <span className="material-icons">add_circle</span>
      <span>Add Widget</span>
    </StyledButton>
  );
};

export default AddWidgetButton;