import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import StatisticsWidget from './StatisticsWidget';
import TableWidget from './TableWidget';
import FormWidget from './FormWidget';
import TodoListWidget from './TodoListWidget';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TextWidget from './TextWidget';
import EmptySpaceWidget from './EmptySpaceWidget';
import SummaryWidget from './SummaryWidget';

const GridItem = styled.div`
  padding: ${props => props.type === 'text' ? '0' : props.settings?.components?.card?.padding || '1rem'};
  border-radius: ${props => props.type === 'text' ? '0' : props.settings?.components?.card?.borderRadius || '8px'};
  box-shadow: none;
  background: ${props => props.type === 'text' ? 'transparent' : 'white'};
  cursor: ${'default'};
  grid-column: span ${props => props.width || 3};
  grid-row: span ${props => props.height || 1};
  transform: translate3d(0, 0, 0);
  will-change: transform;
  backface-visibility: hidden;
  position: relative;
  user-select: ${props => props.isEditing ? 'none' : 'text'};

  &:not([data-type="text"]) {
    box-shadow: ${props => {
        const intensity = props.settings?.components?.card?.shadowIntensity || 'medium';
        const shadows = {
            none: 'none',
            light: '0 1px 2px rgba(0,0,0,0.1)',
            medium: '0 2px 4px rgba(0,0,0,0.1)',
            strong: '0 4px 8px rgba(0,0,0,0.1)'
        };
        return shadows[intensity];
    }};
  }

  &.selected {
    outline: 2px solid #007bff;
  }

  &.dragging {
    opacity: 0.5;
  }
`;

const DragHandle = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: move;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;
  
  .grid-item:hover & {
    opacity: 1;
  }
`;

const EditButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;
  
  .grid-item:hover & {
    opacity: 1;
  }
`;

const ResizeHandle = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: se-resize;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .grid-item:hover & {
    opacity: 1;
  }

  &.dragging {
    opacity: 1;
  }

  .material-icons {
    pointer-events: none;
    font-size: 20px;
    color: #666;
  }
`;

const SettingsMenu = styled.div`
  position: absolute;
  top: 40px;
  right: 8px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  z-index: 100;
  overflow: hidden;
`;

const MenuItem = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: #666;
  transition: all 0.2s;

  &:hover {
    background: #f5f5f5;
    color: #ff4444;
  }

  .material-icons {
    font-size: 18px;
  }
`;

const DraggableWidget = ({
    widget,
    isEditing,
    onResize,
    isSelected,
    onSelect,
    onDelete,
    onUpdate,
    onShowConfig,
    settings
}) => {
    const [showSettings, setShowSettings] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const settingsRef = React.useRef(null);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (settingsRef.current && !settingsRef.current.contains(event.target)) {
                setShowSettings(false);
            }
        };

        if (showSettings) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSettings]);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: widget.id,
        disabled: !isEditing
    });

    const renderWidget = () => {
        switch (widget.type) {
            case 'Statistics':
                return (
                    <StatisticsWidget 
                        config={widget.config}
                        isEditing={isEditing}
                        onUpdate={(updates) => {
                            onSelect();
                            onUpdate(widget.id, updates);
                        }}
                    />
                );
            case 'Table':
                return (
                    <TableWidget 
                        config={widget.config} 
                        isEditing={isEditing}
                        onUpdate={(updates) => {
                            onSelect();
                            onUpdate(widget.id, updates);
                        }}
                    />
                );
            case 'Form':
                return (
                    <FormWidget 
                        config={widget.config}
                        isEditing={isEditing}
                        onUpdate={(updates) => {
                            onSelect();
                            onUpdate(widget.id, updates);
                        }}
                    />
                );
            case 'Text':
                return (
                    <TextWidget 
                        config={widget.config}
                        isEditing={isEditing}
                        onUpdate={(updates) => {
                            onSelect();
                            onUpdate(widget.id, updates);
                        }}
                    />
                );
            case 'TodoList':
                return (
                    <TodoListWidget 
                        config={widget.config}
                        isEditing={isEditing}
                        onUpdate={(updates) => {
                            onSelect();
                            onUpdate(widget.id, updates);
                        }}
                    />
                );
            case 'EmptySpace':
                return (
                    <EmptySpaceWidget 
                        config={widget.config}
                        isEditing={isEditing}
                    />
                );
            case 'Summary':
                return (
                    <SummaryWidget
                        config={widget.config}
                        isEditing={isEditing}
                        onUpdate={(updates) => {
                            onSelect();
                            onUpdate(widget.id, updates);
                        }}
                    />
                );
            default:
                return (
                    <>
                        <span className="material-icons">{widget.icon}</span>
                        <span>{widget.label}</span>
                    </>
                );
        }
    };

    const handleResizeStart = (e) => {
        e.stopPropagation();
        const startX = e.clientX;
        const startY = e.clientY;
        const startWidth = widget.width;
        const startHeight = widget.height;

        const handleMouseMove = (e) => {
            const deltaX = e.clientX - startX;
            const deltaY = e.clientY - startY;

            const columnWidth = window.innerWidth / 12;
            const newWidth = Math.max(1, Math.min(12,
                startWidth + Math.round(deltaX / columnWidth)
            ));
            const newHeight = Math.max(1,
                startHeight + Math.round(deltaY / 100)
            );

            onResize(widget.id, newWidth, newHeight);
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        setShowSettings(false);
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirm = () => {
        onDelete?.(widget.id);
        setShowDeleteConfirmation(false);
    };

    return (
        <>
            <GridItem
                ref={setNodeRef}
                width={widget.width}
                height={widget.height}
                style={{
                    transform: CSS.Transform.toString(transform),
                    transition,
                }}
                className={`grid-item ${isSelected ? 'selected' : ''} ${isDragging ? 'dragging' : ''}`}
                settings={settings}
                type={widget.type.toLowerCase()}
                data-type={widget.type.toLowerCase()}
                isEditing={isEditing}
            >
                {isEditing && (
                    <>
                        <DragHandle {...attributes} {...listeners}>
                            <span className="material-icons">drag_indicator</span>
                        </DragHandle>
                        {widget.type === 'EmptySpace' ? (
                            <EditButton onClick={handleDeleteClick}>
                                <span className="material-icons">delete</span>
                            </EditButton>
                        ) : (
                            <>
                                <EditButton onClick={(e) => {
                                    e.stopPropagation();
                                    setShowSettings(!showSettings);
                                }}>
                                    <span className="material-icons">settings</span>
                                </EditButton>
                                {showSettings && (
                                    <SettingsMenu ref={settingsRef}>
                                        <MenuItem onClick={() => {
                                            setShowSettings(false);
                                            onShowConfig?.(widget);
                                        }}>
                                            <span className="material-icons">tune</span>
                                            Configure Widget
                                        </MenuItem>
                                        <MenuItem onClick={handleDeleteClick}>
                                            <span className="material-icons">delete</span>
                                            Delete Widget
                                        </MenuItem>
                                    </SettingsMenu>
                                )}
                            </>
                        )}
                        <ResizeHandle onMouseDown={handleResizeStart}>
                            <span className="material-icons">drag_handle</span>
                        </ResizeHandle>
                    </>
                )}
                {renderWidget()}
            </GridItem>
            {showDeleteConfirmation && (
                <DeleteConfirmationModal
                    onConfirm={handleDeleteConfirm}
                    onCancel={() => setShowDeleteConfirmation(false)}
                />
            )}
        </>
    );
};

export default DraggableWidget;