import React, { useState } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import styled from '@emotion/styled';

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-weight: 500;
  color: #666;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const SaveButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s;
  
  &:hover {
    background: #0056b3;
  }
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const Profile = () => {
    const session = useSession();
    const supabase = useSupabaseClient();
    const [displayName, setDisplayName] = useState(session?.user?.user_metadata?.display_name || '');
    const [email, setEmail] = useState(session?.user?.email || '');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            const { error } = await supabase.auth.updateUser({
                email: email,
                data: { 
                    display_name: displayName 
                }
            });
            if (error) throw error;
            setSuccess('Profile updated successfully!');
        } catch (err) {
            setError('Error updating profile');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div style={{ height: '4rem' }} />
            <PageContainer>
                <Card>
                    <Title>Profile Settings</Title>
                    <Form onSubmit={handleProfileUpdate}>
                        <FormGroup>
                            <Label>Display Name</Label>
                            <Input
                                type="text"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                placeholder="Enter your name"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                            />
                        </FormGroup>
                        {error && <div style={{ color: '#dc3545' }}>{error}</div>}
                        {success && <div style={{ color: '#28a745' }}>{success}</div>}
                        <SaveButton type="submit" disabled={isLoading}>
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </SaveButton>
                    </Form>
                </Card>
            </PageContainer>
        </>
    );
};

export default Profile;
