import React from 'react';
import styled from '@emotion/styled';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1300;
`;

const ModalContent = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

const ModalHeader = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333;
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;

  &.cancel {
    background: #f5f5f5;
    color: #666;

    &:hover {
      background: #eee;
    }
  }

  &.delete {
    background: #ff4444;
    color: white;

    &:hover {
      background: #ff2020;
    }
  }
`;

const DeleteConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <ModalOverlay onClick={onCancel}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <ModalHeader>Delete Widget</ModalHeader>
        <div>Are you sure you want to delete this widget? This action cannot be undone.</div>
        <ModalButtons>
          <Button className="cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="delete" onClick={onConfirm}>
            Delete
          </Button>
        </ModalButtons>
      </ModalContent>
    </ModalOverlay>
  );
};

export default DeleteConfirmationModal;
