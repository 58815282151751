import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useNavigate, useLocation } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';
import { useSession } from '@supabase/auth-helpers-react';

const Drawer = styled.div`
  position: fixed;
  left: ${props => props.open ? '0' : '-300px'};
  top: 0;
  bottom: 0;
  width: 300px;
  background: white;
  box-shadow: ${props => props.open ? '2px 0 8px rgba(0,0,0,0.1)' : 'none'};
  transition: left 0.3s ease;
  z-index: 1000;
`;

const DrawerHeader = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const UserAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
`;

const UserInfo = styled.div`
  flex: 1;
  h3 {
    margin: 0;
    font-size: 1rem;
  }
  p {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
  }
`;

const DrawerContent = styled.div`
  padding: 1rem;
`;

const MenuItem = styled.button`
  width: 100%;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border: none;
  background: ${props => props.selected ? '#f0f7ff' : 'none'};
  cursor: pointer;
  color: ${props => props.danger ? '#dc3545' : props.selected ? '#007bff' : '#333'};
  border-radius: 4px;
  
  &:hover {
    background: ${props => props.selected ? '#e6f0ff' : '#f8f9fa'};
  }
  
  .material-icons {
    font-size: 1.2rem;
    color: ${props => props.selected ? '#007bff' : 'inherit'};
  }
`;

const AccountDrawer = ({ open, onClose }) => {
  const supabase = useSupabaseClient();
  const navigate = useNavigate();
  const location = useLocation();
  const session = useSession();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  return (
    <>
      <Drawer open={open}>
        <DrawerHeader>
          <UserAvatar>
            {session?.user?.email?.[0].toUpperCase()}
          </UserAvatar>
          <UserInfo>
            <h3>Welcome</h3>
            <p>{session?.user?.email}</p>
          </UserInfo>
        </DrawerHeader>
        <DrawerContent>
          <MenuItem 
            onClick={() => handleNavigation('/')}
            selected={location.pathname === '/'}
          >
            <span className="material-icons">dashboard</span>
            My Dashboards
          </MenuItem>
          <MenuItem 
            onClick={() => handleNavigation('/account-settings')}
            selected={location.pathname === '/account-settings'}
          >
            <span className="material-icons">settings</span>
            Account Settings
          </MenuItem>
          <MenuItem 
            onClick={() => handleNavigation('/billing')}
            selected={location.pathname === '/billing'}
          >
            <span className="material-icons">payments</span>
            Billing & Plans
          </MenuItem>
          <MenuItem 
            onClick={() => handleNavigation('/usage')}
            selected={location.pathname === '/usage'}
          >
            <span className="material-icons">data_usage</span>
            Usage & Limits
          </MenuItem>
          <MenuItem 
            onClick={() => handleNavigation('/extensions')}
            selected={location.pathname === '/extensions'}
          >
            <span className="material-icons">extension</span>
            Extensions
          </MenuItem>
          <MenuItem 
            danger 
            onClick={() => setShowLogoutConfirm(true)}
          >
            <span className="material-icons">logout</span>
            Logout
          </MenuItem>
        </DrawerContent>
      </Drawer>
      {open && <div 
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0,0,0,0.3)',
          zIndex: 999
        }}
        onClick={onClose}
      />}

      {showLogoutConfirm && (
        <ConfirmDialog
          title="Confirm Logout"
          message="Are you sure you want to log out?"
          onConfirm={() => {
            setShowLogoutConfirm(false);
            handleLogout();
          }}
          onCancel={() => setShowLogoutConfirm(false)}
        />
      )}
    </>
  );
};

export default AccountDrawer;
