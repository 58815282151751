import React from 'react';
import {
  ConfigField,
  Label,
  Input,
  Select,
  Button,
  FieldList,
  FieldItem,
  StepItem,
  ColorInput,
  IconSelectorWrapper
} from './shared/ConfigurationStyles';
import IconSelector from './IconSelector';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DataSourceButton from './shared/DataSourceButton';
import DataSourceSelect from './shared/DataSourceSelect';

const FormConfiguration = ({
  tempConfig,
  handleChange,
  handleAddStep,
  handleAddField,
  handleStepChange,
  handleFieldChange,
  handleDeleteStep,
  handleDeleteField
}) => {
  const renderFieldControls = (stepIndex, fieldIndex, field) => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ConfigField>
          <Label>Field Label</Label>
          <Input
            type="text"
            value={field.label}
            onChange={(e) => handleFieldChange(stepIndex, fieldIndex, 'label', e.target.value)}
          />
        </ConfigField>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConfigField>
          <Label>Field Type</Label>
          <Select
            value={field.type}
            onChange={(e) => handleFieldChange(stepIndex, fieldIndex, 'type', e.target.value)}
          >
            {['text', 'email', 'number', 'tel', 'textarea', 'select'].map(type => (
              <option key={type} value={type}>
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </option>
            ))}
          </Select>
        </ConfigField>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConfigField>
          <Label>Placeholder</Label>
          <Input
            type="text"
            value={field.placeholder}
            onChange={(e) => handleFieldChange(stepIndex, fieldIndex, 'placeholder', e.target.value)}
          />
        </ConfigField>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConfigField>
          <Label>
            <input
              type="checkbox"
              checked={field.required}
              onChange={(e) => handleFieldChange(stepIndex, fieldIndex, 'required', e.target.checked)}
            />
            Required
          </Label>
        </ConfigField>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Delete this field">
          <Button className="secondary" onClick={() => handleDeleteField(stepIndex, fieldIndex)}>
            <span className="material-icons">delete</span>
            Delete Field
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderStep = (step, stepIndex) => (
    <StepItem key={stepIndex}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ConfigField>
            <Label>Step Title</Label>
            <Input
              type="text"
              value={step.title}
              onChange={(e) => handleStepChange(stepIndex, 'title', e.target.value)}
            />
          </ConfigField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Fields</Typography>
          <FieldList>
            {step.fields.map((field, fieldIndex) => (
              <FieldItem key={fieldIndex}>
                {renderFieldControls(stepIndex, fieldIndex, field)}
              </FieldItem>
            ))}
          </FieldList>
        </Grid>
        <Grid item xs={12}>
          <Tooltip title="Add a new field">
            <Button className="primary" onClick={() => handleAddField(stepIndex)}>
              <span className="material-icons">add</span>
              Add Field
            </Button>
          </Tooltip>
        </Grid>
        {tempConfig.steps.length > 1 && (
          <Grid item xs={12}>
            <Tooltip title="Delete this step">
              <Button className="secondary" onClick={() => handleDeleteStep(stepIndex)}>
                <span className="material-icons">delete</span>
                Delete Step
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </StepItem>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DataSourceSelect 
          selectedSource={tempConfig.dataSource}
          onSourceSelect={(source) => handleChange('dataSource', source)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Form Configuration</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConfigField>
          <Label>Form Title</Label>
          <Input
            type="text"
            value={tempConfig.title}
            onChange={(e) => handleChange('title', e.target.value)}
          />
        </ConfigField>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConfigField>
          <Label>Icon</Label>
          <IconSelectorWrapper>
            <IconSelector
              currentIcon={tempConfig.icon}
              onSelectIcon={(icon) => handleChange('icon', icon)}
            />
          </IconSelectorWrapper>
        </ConfigField>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConfigField>
          <Label>Color</Label>
          <ColorInput
            type="color"
            value={tempConfig.color}
            onChange={(e) => handleChange('color', e.target.value)}
          />
        </ConfigField>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Steps</Typography>
        <FieldList>
          {tempConfig.steps.map(renderStep)}
        </FieldList>
      </Grid>
      <Grid item xs={12}>
        <Tooltip title="Add a new step">
          <Button className="primary" onClick={handleAddStep}>
            <span className="material-icons">add</span>
            Add Step
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <ConfigField>
          <Label>Submit Button Label</Label>
          <Input
            type="text"
            value={tempConfig.submitButton?.label || 'Submit'}
            onChange={(e) => handleChange('submitButton', { ...tempConfig.submitButton, label: e.target.value })}
          />
        </ConfigField>
      </Grid>
    </Grid>
  );
};

export default FormConfiguration;
