import React from 'react';
import styled from '@emotion/styled';

const PageWrapper = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding-top: 4rem;
`;

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8f9fa;
  min-height: calc(100vh - 4rem);
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 1.5rem;
`;

const ExtensionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const ExtensionCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ExtensionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ExtensionIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: ${props => props.bgColor || '#e3f2fd'};
  color: ${props => props.color || '#1976d2'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  .material-icons {
    font-size: 24px;
  }
`;

const ExtensionInfo = styled.div`
  flex: 1;
  
  h3 {
    margin: 0;
    font-size: 1.1rem;
    color: #333;
  }
  
  p {
    margin: 0.25rem 0 0 0;
    color: #666;
    font-size: 0.9rem;
  }
`;

const ExtensionStatus = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background: ${props => props.installed ? '#dcfce7' : '#f3f4f6'};
  color: ${props => props.installed ? '#166534' : '#374151'};
`;

const Button = styled.button`
  background: ${props => props.installed ? '#007bff' : '#f3f4f6'};
  color: ${props => props.installed ? 'white' : '#374151'};
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.2s;
  
  &:hover {
    background: ${props => props.installed ? '#0056b3' : '#e5e7eb'};
  }
`;

const Extensions = () => {
    const extensions = [
        {
            id: 1,
            name: 'Login Kit',
            description: 'Add social login options and enhanced authentication features',
            icon: 'login',
            color: '#1976d2',
            bgColor: '#e3f2fd',
            installed: true
        },
        {
            id: 2,
            name: 'Email Notifications',
            description: 'Send automated emails based on dashboard events and triggers',
            icon: 'mail',
            color: '#0d9488',
            bgColor: '#ccfbf1',
            installed: false
        },
        {
            id: 3,
            name: 'Data Export',
            description: 'Export dashboard data in various formats (CSV, PDF, Excel)',
            icon: 'download',
            color: '#7c3aed',
            bgColor: '#ede9fe',
            installed: false
        },
        {
            id: 4,
            name: 'Custom Themes',
            description: 'Create and apply custom themes to your dashboards',
            icon: 'palette',
            color: '#ea580c',
            bgColor: '#ffedd5',
            installed: false
        },
        {
            id: 5,
            name: 'Analytics',
            description: 'Track dashboard usage and user engagement metrics',
            icon: 'analytics',
            color: '#2563eb',
            bgColor: '#dbeafe',
            installed: true
        },
        {
            id: 6,
            name: 'API Integration',
            description: 'Connect your dashboard to external APIs and services',
            icon: 'api',
            color: '#059669',
            bgColor: '#d1fae5',
            installed: false
        }
    ];

    const handleExtensionAction = (extension) => {
        // Handle installation/uninstallation logic here
        console.log(`${extension.installed ? 'Uninstalling' : 'Installing'} ${extension.name}`);
    };

    return (
        <PageWrapper>
            <PageContainer>
                <ExtensionGrid>
                    {extensions.map(extension => (
                        <ExtensionCard key={extension.id}>
                            <ExtensionHeader>
                                <ExtensionIcon color={extension.color} bgColor={extension.bgColor}>
                                    <span className="material-icons">{extension.icon}</span>
                                </ExtensionIcon>
                                <ExtensionInfo>
                                    <h3>{extension.name}</h3>
                                    <p>{extension.description}</p>
                                </ExtensionInfo>
                            </ExtensionHeader>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <ExtensionStatus installed={extension.installed}>
                                    {extension.installed ? 'Installed' : 'Available'}
                                </ExtensionStatus>
                                <Button
                                    installed={extension.installed}
                                    onClick={() => handleExtensionAction(extension)}
                                >
                                    {extension.installed ? 'Manage' : 'Install'}
                                </Button>
                            </div>
                        </ExtensionCard>
                    ))}
                </ExtensionGrid>
            </PageContainer>
        </PageWrapper>
    );
};

export default Extensions;