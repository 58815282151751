import React from 'react';
import styled from '@emotion/styled';
import DashboardEditMode from './DashboardEditMode';
import DashboardPreviewMode from './DashboardPreviewMode';

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Dashboard = ({ 
  components,
  onUpdateComponents,
  isEditing,
  settings,
  availableComponents,
  onUndo,
  onRedo,
  canUndo,
  canRedo,
  hasUnsavedChanges,
  addEdit,
  selectedMenuItem
}) => {
  return (
    <Container>
      {isEditing ? (
        <DashboardEditMode
          components={components}
          onUpdateComponents={onUpdateComponents}
          settings={settings}
          availableComponents={availableComponents}
          onUndo={onUndo}
          onRedo={onRedo}
          canUndo={canUndo}
          canRedo={canRedo}
          hasUnsavedChanges={hasUnsavedChanges}
          addEdit={addEdit}
          selectedMenuItem={selectedMenuItem}
        />
      ) : (
        <DashboardPreviewMode
          components={components}
          settings={settings}
        />
      )}
    </Container>
  );
};

export default Dashboard;