import React from 'react';
import styled from '@emotion/styled';
import {
    ConfigField,
    Label,
    Input,
    ColorInput,
    IconSelectorWrapper
} from './shared/ConfigurationStyles';
import IconSelector from './IconSelector';
import DataSourceSelect from './shared/DataSourceSelect';

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
`;

const SummaryConfiguration = ({ tempConfig, handleChange }) => {
    return (
        <>
            <DataSourceSelect
                selectedSource={tempConfig.dataSource}
                onSourceSelect={(source) => handleChange('dataSource', source)}
            />

            <ConfigField>
                <Label>Color</Label>
                <ColorInput
                    type="color"
                    value={tempConfig?.color || '#333333'}
                    onChange={(e) => handleChange('color', e.target.value)}
                />
            </ConfigField>

            <ConfigField>
                <Label>Prefix</Label>
                <Input
                    type="text"
                    value={tempConfig?.prefix || ''}
                    onChange={(e) => handleChange('prefix', e.target.value)}
                    placeholder="e.g. $, £"
                />
            </ConfigField>

            <ConfigField>
                <Label>Suffix</Label>
                <Input
                    type="text"
                    value={tempConfig?.suffix || ''}
                    onChange={(e) => handleChange('suffix', e.target.value)}
                    placeholder="e.g. %, pts"
                />
            </ConfigField>
        </>
    );
};

export default SummaryConfiguration;