import React, { useState } from 'react';
import styled from '@emotion/styled';
import IconPickerModal from './IconPickerModal';

const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  background: white;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;
`;

const TableIcon = styled.span`
  color: ${props => props.color || '#666'};
  font-size: 1.25rem;
`;

const TableTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  color: #181AEE;
`;

const TableContent = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 0.75rem;
  text-align: left;
  color: #666;
  font-weight: 500;
  border-bottom: 2px solid #eee;
  position: relative;
  background: white;
  z-index: 1;

  &:hover .column-actions {
    opacity: 1;
  }
`;

const Td = styled.td`
  padding: 0.75rem;
  border-bottom: 1px solid #eee;
  color: #333;
`;

const Tr = styled.tr`
  &:hover {
    background: #f8f9fa;
  }
`;

const StatusBadge = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  background: ${props => {
    switch (props.status?.toLowerCase()) {
      case 'completed': return '#dcfce7';
      case 'pending': return '#fef9c3';
      case 'cancelled': return '#fee2e2';
      default: return '#f3f4f6';
    }
  }};
  color: ${props => {
    switch (props.status?.toLowerCase()) {
      case 'completed': return '#166534';
      case 'pending': return '#854d0e';
      case 'cancelled': return '#991b1b';
      default: return '#374151';
    }
  }};
`;

const EditableInput = styled.input`
  background: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  width: 100%;
  padding: 0;
  margin: 0;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  &:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
  }
`;

const EditableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  font-weight: bold;
  font-size: 1.125rem;
  color: #181AEE;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: #2563eb;
  opacity: 1;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  white-space: nowrap;

  &:hover {
    color: #1d4ed8;
  }

  .material-icons {
    font-size: 18px;
  }

  &.add-row {
    width: 100%;
    padding: 0.5rem;
    justify-content: center;
  }
`;

const ColumnActions = styled.div`
  display: flex;
  gap: 0.25rem;
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  padding-left: 0.5rem;
  class-name: column-actions;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: ${props => props.delete ? '#dc2626' : '#2563eb'};
  
  &:hover {
    color: ${props => props.delete ? '#991b1b' : '#1d4ed8'};
  }

  .material-icons {
    font-size: 18px;
  }
`;

const NumericValue = styled.span`
  font-weight: 600;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
`;

const DateBadge = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  background: #f3f4f6;
  color: #374151;
`;

const BoldCell = styled.span`
  font-weight: 700;
`;

const TableWidget = ({ config = {}, isEditing, onUpdate }) => {
  const [showIconPicker, setShowIconPicker] = useState(false);
  const iconRef = React.useRef(null);

  const {
    title = 'Table',
    icon = 'table_chart',
    color = '#666',
    columns = [],
    data = []
  } = config || {};

  const handleUpdate = (updates) => {
    if (onUpdate) {
      onUpdate({
        ...config,
        ...updates
      });
    }
  };

  const handleColumnUpdate = (index, updates) => {
    const newColumns = [...columns];
    newColumns[index] = { ...newColumns[index], ...updates };
    handleUpdate({ columns: newColumns });
  };

  const handleDataUpdate = (rowIndex, colIndex, value) => {
    const newData = [...data];
    newData[rowIndex] = {
      ...newData[rowIndex],
      [columns[colIndex].key]: value
    };
    handleUpdate({ data: newData });
  };

  const formatValue = (value, columnKey, rowIndex, colIndex) => {
    if (isEditing) {
      return (
        <EditableInput
          value={value || ''}
          onChange={(e) => handleDataUpdate(rowIndex, colIndex, e.target.value)}
          placeholder="Enter value"
        />
      );
    }

    // If no value, return empty string
    if (!value) return '';

    // Convert column key and label to lowercase for comparison
    const columnLower = columnKey.toLowerCase();
    const labelLower = columns[colIndex].label.toLowerCase();

    // Type columns - make bold
    if (columnLower.includes('type') || labelLower.includes('type')) {
      return <BoldCell>{value}</BoldCell>;
    }

    // Status-like columns
    if (columnLower.includes('status') ||
      columnLower.includes('state') ||
      columnLower.includes('condition') ||
      labelLower.includes('status') ||
      labelLower.includes('state') ||
      labelLower.includes('condition')) {
      return <StatusBadge status={value}>{value}</StatusBadge>;
    }

    // Date columns
    if (columnLower.includes('date') ||
      columnLower.includes('time') ||
      columnLower.includes('when') ||
      labelLower.includes('date') ||
      labelLower.includes('time') ||
      labelLower.includes('when')) {
      return <DateBadge>{value}</DateBadge>;
    }

    // Number-like columns
    if ((columnLower.includes('amount') ||
      columnLower.includes('price') ||
      columnLower.includes('quantity') ||
      columnLower.includes('number') ||
      labelLower.includes('amount') ||
      labelLower.includes('price') ||
      labelLower.includes('quantity') ||
      labelLower.includes('number')) &&
      !isNaN(value)) {
      return <NumericValue>{Number(value).toLocaleString()}</NumericValue>;
    }

    // Percentage columns
    if (columnLower.includes('percent') || 
      columnLower.includes('rate') ||
      labelLower.includes('percent') ||
      labelLower.includes('rate')) {
      const num = parseFloat(value);
      if (!isNaN(num)) {
        return <NumericValue>{num}%</NumericValue>;
      }
    }

    // Currency columns
    if (columnLower.includes('price') ||
      columnLower.includes('cost') ||
      columnLower.includes('revenue') ||
      labelLower.includes('price') ||
      labelLower.includes('cost') ||
      labelLower.includes('revenue')) {
      const num = parseFloat(value);
      if (!isNaN(num)) {
        return <NumericValue>${num.toLocaleString()}</NumericValue>;
      }
    }

    // Default text value
    return value;
  };

  const handleDeleteColumn = (index) => {
    const newColumns = columns.filter((_, i) => i !== index);
    const newData = data.map(row => {
      const newRow = { ...row };
      delete newRow[columns[index].key];
      return newRow;
    });

    handleUpdate({
      columns: newColumns,
      data: newData
    });
  };

  const handleDeleteRow = (rowIndex) => {
    const newData = data.filter((_, i) => i !== rowIndex);
    handleUpdate({ data: newData });
  };

  const handleAddColumn = () => {
    const newColumnKey = `col${columns.length + 1}`;
    const newColumns = [
      ...columns,
      {
        key: newColumnKey,
        label: 'New Column',
        type: 'text'
      }
    ];

    const newData = data.map(row => ({
      ...row,
      [newColumnKey]: ''
    }));

    handleUpdate({
      columns: newColumns,
      data: newData
    });
  };

  const handleAddRow = () => {
    const newRow = {};
    columns.forEach(col => newRow[col.key] = '');
    handleUpdate({
      data: [...data, newRow]
    });
  };

  return (
    <TableContainer>
      <TableHeader className="table-header">
        <EditableHeader>
          <TableIcon
            ref={iconRef}
            className="material-icons"
            color={color}
            style={{ cursor: isEditing ? 'pointer' : 'default' }}
            onClick={() => isEditing && setShowIconPicker(true)}
          >
            {icon}
          </TableIcon>

          {isEditing ? (
            <EditableInput
              value={title}
              onChange={(e) => handleUpdate({ title: e.target.value })}
              placeholder="Enter table title"
            />
          ) : (
            <TableTitle>{title}</TableTitle>
          )}
        </EditableHeader>

        {isEditing && (
          <EditButton onClick={handleAddColumn}>
            <span className="material-icons">add</span>Add Column
          </EditButton>
        )}
      </TableHeader>

      {showIconPicker && isEditing && (
        <IconPickerModal
          onClose={() => setShowIconPicker(false)}
          onSelect={(newIcon) => {
            handleUpdate({ icon: newIcon });
            setShowIconPicker(false);
          }}
          icons={[
            'table_chart', 'grid_view', 'view_list', 'view_module',
            'view_column', 'view_timeline', 'table_rows', 'table_view'
          ]}
        />
      )}

      <TableContent>
        <Table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <Th key={index}>
                  {isEditing ? (
                    <>
                      <EditableInput
                        value={column.label}
                        onChange={(e) => handleColumnUpdate(index, { label: e.target.value })}
                        placeholder="Column name"
                      />
                      <ColumnActions className="column-actions">
                        <ActionButton delete onClick={() => handleDeleteColumn(index)}>
                          <span className="material-icons">delete</span>
                        </ActionButton>
                      </ColumnActions>
                    </>
                  ) : (
                    column.label
                  )}
                </Th>
              ))}
              {isEditing && <Th></Th>}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <Td key={colIndex}>
                    {formatValue(row[column.key], column.key, rowIndex, colIndex)}
                  </Td>
                ))}
                {isEditing && (
                  <Td>
                    <ActionButton delete onClick={() => handleDeleteRow(rowIndex)}>
                      <span className="material-icons">delete</span>
                    </ActionButton>
                  </Td>
                )}
              </Tr>
            ))}
            {isEditing && (
              <Tr>
                <Td colSpan={columns.length + 1}>
                  <EditButton
                    className="add-row"
                    onClick={handleAddRow}
                  >
                    <span className="material-icons">add</span> Add Row
                  </EditButton>
                </Td>
              </Tr>
            )}
          </tbody>
        </Table>
      </TableContent>
    </TableContainer>
  );
};

export default TableWidget;