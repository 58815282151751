import React, { useState } from 'react';
import styled from '@emotion/styled';
import { plans } from '../config/plans';
// import { auth } from '../firebase';

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8f9fa;
  min-height: calc(100vh - 4rem);
`;

const PageWrapper = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding-top: 4rem;
`;

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PlanCard = styled.div`
  background: white;
  border: ${props => props.active ? '2px solid #007bff' : '1px solid #e5e7eb'};
  border-radius: 12px;
  padding: 2rem;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;

  ${props => props.popular && `
    &:before {
      content: 'Most Popular';
      position: absolute;
      top: 12px;
      right: -32px;
      background: #10b981;
      color: white;
      padding: 4px 40px;
      font-size: 0.75rem;
      transform: rotate(45deg);
    }
  `}

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
`;

const PlanHeader = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const PlanName = styled.h3`
  margin: 0;
  color: #111827;
  font-size: 1.25rem;
  font-weight: 600;
`;

const PlanPrice = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #111827;
  margin: 0.75rem 0;

  span {
    font-size: 0.875rem;
    color: #6b7280;
    font-weight: 400;
  }
  
  .starts-at {
    font-size: 1rem;
    color: #6b7280;
    font-weight: 500;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  
  li {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
    color: #4b5563;
    font-size: 0.95rem;
    
    .material-icons {
      color: #10b981;
      font-size: 1.25rem;
    }
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  
  ${props => props.disabled ? `
    background: #f3f4f6;
    color: #9ca3af;
    cursor: not-allowed;
  ` : `
    background: #007bff;
    color: white;
    border: none;
    
    &:hover {
      background: #0056b3;
    }
  `}
`;

const CurrentPlan = styled.div`
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  padding: 2rem;
  margin-top: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
`;

const CurrentPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e5e7eb;

  @media (max-width: 640px) {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
`;

const PlanInfo = styled.div`
  h4 {
    margin: 0 0 0.5rem 0;
    color: #111827;
    font-size: 1.25rem;
    font-weight: 600;
  }
`;

const PlanStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${props => props.active ? '#059669' : '#DC2626'};
  font-size: 0.875rem;
  font-weight: 500;

  .material-icons {
    font-size: 1.25rem;
  }
`;

const PlanDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 1rem 0;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const DetailItem = styled.div`
  h5 {
    margin: 0 0 0.5rem 0;
    color: #6B7280;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    color: #111827;
    font-size: 1rem;
    font-weight: 500;
  }
`;

const TabsContainer = styled.div`
  margin-bottom: 2rem;
`;

const TabsList = styled.div`
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 2rem;
`;

const TabButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: ${props => props.selected ? '#111827' : '#6B7280'};
  border-bottom: 2px solid ${props => props.selected ? '#007bff' : 'transparent'};
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #111827;
  }
`;

const PaymentMethodCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    width: 40px;
    height: 40px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem;
  background: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
  color: #6b7280;
  font-weight: 500;
  font-size: 0.875rem;
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
  color: #111827;
`;

const StatusBadge = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  background: ${props => {
    switch (props.status.toLowerCase()) {
      case 'paid':
        return '#dcfce7';
      case 'pending':
        return '#fef9c3';
      case 'failed':
        return '#fee2e2';
      default:
        return '#f3f4f6';
    }
  }};
  color: ${props => {
    switch (props.status.toLowerCase()) {
      case 'paid':
        return '#166534';
      case 'pending':
        return '#854d0e';
      case 'failed':
        return '#991b1b';
      default:
        return '#374151';
    }
  }};
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 1.5rem;
`;

const Billing = () => {
    const [subscription] = useState({
        planId: 'basic',
        planName: 'Basic Plan',
        status: 'active',
        currentPeriodEnd: new Date('2024-12-31').getTime()
    });

    const [paymentMethods] = useState([
        {
            id: 1,
            type: 'visa',
            last4: '4242',
            expiryMonth: 12,
            expiryYear: 2024,
            isDefault: true,
            cardholderName: 'John Doe'
        },
        {
            id: 2,
            type: 'mastercard',
            last4: '8888',
            expiryMonth: 6,
            expiryYear: 2025,
            isDefault: false,
            cardholderName: 'John Doe'
        }
    ]);

    const [billingHistory] = useState([
        {
            id: 1,
            date: '2024-01-15',
            description: 'Monthly Subscription - Professional Plan',
            amount: 49.99,
            status: 'Paid',
            invoice: '#INV-2024-001'
        },
        {
            id: 2,
            date: '2023-12-15',
            description: 'Monthly Subscription - Professional Plan',
            amount: 49.99,
            status: 'Paid',
            invoice: '#INV-2023-012'
        },
        {
            id: 3,
            date: '2023-11-15',
            description: 'Monthly Subscription - Basic Plan',
            amount: 29.99,
            status: 'Paid',
            invoice: '#INV-2023-011'
        }
    ]);

    const [activeTab, setActiveTab] = useState(0);

    const handleSubscribe = async (planId) => {
        try {
            // const response = await fetch('/functions/stripe-checkout', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         planId,
            //         userId: auth.currentUser.uid,
            //     }),
            // });

            // const data = await response.json();
            // if (data.error) throw new Error(data.error);
            
            // window.location.href = data.url;
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <PageWrapper>
            <PageContainer>
                <Card>
                    <Title>Billing & Plans</Title>
                    <TabsContainer>
                        <TabsList>
                            {['Plans', 'Current Subscription', 'Payment Methods', 'Billing History'].map((tab, index) => (
                                <TabButton
                                    key={tab}
                                    selected={activeTab === index}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab}
                                </TabButton>
                            ))}
                        </TabsList>
                    </TabsContainer>

                    {/* Plans Tab */}
                    {activeTab === 0 && (
                        <PlansContainer>
                            {plans.map((plan) => (
                                <PlanCard
                                    key={plan.id}
                                    active={subscription?.planId === plan.id}
                                >
                                    <PlanHeader>
                                        <PlanName>{plan.name}</PlanName>
                                        <PlanPrice>
                                            {plan.id === 'enterprise' ? (
                                                <>
                                                    <span className="starts-at">Starts at </span>
                                                    ${plan.price}<span>/month</span>
                                                </>
                                            ) : (
                                                <>
                                                    ${plan.price}<span>/month</span>
                                                </>
                                            )}
                                        </PlanPrice>
                                    </PlanHeader>
                                    <FeatureList>
                                        {plan.features.map((feature) => (
                                            <li key={feature}>
                                                <span className="material-icons">check_circle</span>
                                                {feature}
                                            </li>
                                        ))}
                                    </FeatureList>
                                    <Button
                                        onClick={() => handleSubscribe(plan.id)}
                                        disabled={subscription?.planId === plan.id}
                                    >
                                        {subscription?.planId === plan.id ? 'Current Plan' : 'Subscribe'}
                                    </Button>
                                </PlanCard>
                            ))}
                        </PlansContainer>
                    )}

                    {/* Current Subscription Tab */}
                    {activeTab === 1 && subscription && (
                        <CurrentPlan>
                            <CurrentPlanHeader>
                                <PlanInfo>
                                    <h4>Current Subscription</h4>
                                    <PlanStatus active={subscription.status === 'active'}>
                                        <span className="material-icons">
                                            {subscription.status === 'active' ? 'check_circle' : 'error'}
                                        </span>
                                        {subscription.status === 'active' ? 'Active' : 'Inactive'}
                                    </PlanStatus>
                                </PlanInfo>
                                <Button
                                    onClick={() => handleSubscribe(subscription.planId)}
                                    disabled={subscription?.planId === subscription.planId}
                                >
                                    Manage Subscription
                                </Button>
                            </CurrentPlanHeader>
                            <PlanDetails>
                                <DetailItem>
                                    <h5>Plan</h5>
                                    <p>{subscription.planName}</p>
                                </DetailItem>
                                <DetailItem>
                                    <h5>Billing Period</h5>
                                    <p>Monthly</p>
                                </DetailItem>
                                <DetailItem>
                                    <h5>Next Billing Date</h5>
                                    <p>{new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
                                </DetailItem>
                            </PlanDetails>
                        </CurrentPlan>
                    )}

                    {/* Payment Methods Tab */}
                    {activeTab === 2 && (
                        <div>
                            <h3>Payment Methods</h3>
                            {paymentMethods.map(method => (
                                <PaymentMethodCard key={method.id}>
                                    <CardInfo>
                                        <img 
                                            src={`/images/${method.type}.svg`} 
                                            alt={method.type}
                                        />
                                        <div>
                                            <p style={{ fontWeight: '500', marginBottom: '0.25rem' }}>
                                                {method.cardholderName}
                                            </p>
                                            <p style={{ color: '#6b7280' }}>
                                                •••• •••• •••• {method.last4}
                                            </p>
                                            <p style={{ color: '#6b7280', fontSize: '0.875rem' }}>
                                                Expires {method.expiryMonth}/{method.expiryYear}
                                            </p>
                                        </div>
                                    </CardInfo>
                                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                        {method.isDefault && (
                                            <span style={{
                                                padding: '0.25rem 0.75rem',
                                                background: '#f3f4f6',
                                                borderRadius: '9999px',
                                                fontSize: '0.875rem'
                                            }}>
                                                Default
                                            </span>
                                        )}
                                        <Button style={{ width: 'auto' }}>Edit</Button>
                                        <Button 
                                            style={{ 
                                                width: 'auto', 
                                                background: 'transparent',
                                                color: '#dc2626',
                                                border: '1px solid #dc2626'
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                </PaymentMethodCard>
                            ))}
                            <Button style={{ marginTop: '1rem' }}>Add Payment Method</Button>
                        </div>
                    )}

                    {/* Billing History Tab */}
                    {activeTab === 3 && (
                        <div>
                            <h3>Billing History</h3>
                            <Table>
                                <thead>
                                    <tr>
                                        <Th>Date</Th>
                                        <Th>Description</Th>
                                        <Th>Amount</Th>
                                        <Th>Status</Th>
                                        <Th>Invoice</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {billingHistory.map(item => (
                                        <tr key={item.id}>
                                            <Td>{new Date(item.date).toLocaleDateString()}</Td>
                                            <Td>{item.description}</Td>
                                            <Td>${item.amount.toFixed(2)}</Td>
                                            <Td>
                                                <StatusBadge status={item.status}>
                                                    {item.status}
                                                </StatusBadge>
                                            </Td>
                                            <Td>
                                                <Button 
                                                    style={{ 
                                                        width: 'auto', 
                                                        padding: '0.5rem 1rem',
                                                        background: 'transparent',
                                                        color: '#007bff',
                                                        border: '1px solid #007bff'
                                                    }}
                                                >
                                                    Download
                                                </Button>
                                            </Td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </Card>
            </PageContainer>
        </PageWrapper>
    );
};

export default Billing; 