export const usageLimits = {
    basic: {
        dashboardLimit: 3,
        widgetLimit: 10,
        storageLimit: 1000, // 1GB in MB
        apiCallsLimit: 0,
        customThemesEnabled: false,
        whiteLabelingEnabled: false,
        customIntegrationsEnabled: false,
        prioritySupport: false,
        teamMemberLimit: 2,
        dataSourceLimit: 3
    },
    pro: {
        dashboardLimit: 999999,
        widgetLimit: 999999,
        storageLimit: 10000, // 10GB in MB
        apiCallsLimit: 10000,
        customThemesEnabled: true,
        whiteLabelingEnabled: false,
        customIntegrationsEnabled: false,
        prioritySupport: true,
        teamMemberLimit: 10,
        dataSourceLimit: 15
    },
    enterprise: {
        dashboardLimit: 999999,
        widgetLimit: 999999,
        storageLimit: 999999,
        apiCallsLimit: 999999,
        customThemesEnabled: true,
        whiteLabelingEnabled: true,
        customIntegrationsEnabled: true,
        prioritySupport: true,
        teamMemberLimit: 999999,
        dataSourceLimit: 999999
    }
};