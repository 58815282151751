import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSupabaseClient } from '@supabase/auth-helpers-react';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 90vw;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  
  h2 {
    margin: 0;
    color: #333;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  
  &:hover {
    color: #333;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid #e5e7eb;
  color: #6b7280;
  font-weight: 500;
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const PageButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid #e5e7eb;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  
  &:disabled {
    background: #f3f4f6;
    cursor: not-allowed;
  }
  
  &:hover:not(:disabled) {
    background: #f3f4f6;
  }
`;

const TableViewModal = ({ schemaName, tableName, onClose }) => {
  const supabase = useSupabaseClient();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setLoading(true);
        
        // Get column information
        const { data: columnData, error: columnError } = await supabase
          .rpc('get_table_columns', { 
            p_schema_name: schemaName,
            p_table_name: tableName
          });
          
        if (columnError) throw columnError;
        setColumns(columnData || []);

        // Get total count using RPC to ensure proper schema access
        const { data: countData, error: countError } = await supabase
          .rpc('get_table_row_count', {
            p_schema_name: schemaName,
            p_table_name: tableName
          });
          
        if (countError) throw countError;
        setTotalRows(countData);

        // Get data for current page using RPC
        const { data: tableData, error: dataError } = await supabase
          .rpc('get_table_data', {
            p_schema_name: schemaName,
            p_table_name: tableName,
            p_offset: page * pageSize,
            p_limit: pageSize
          });
          
        if (dataError) throw dataError;
        setData(tableData || []);
        
      } catch (err) {
        console.error('Error fetching table data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTableData();
  }, [schemaName, tableName, page, supabase]);

  return (
    <ModalOverlay onClick={e => e.target === e.currentTarget && onClose()}>
      <ModalContent>
        <ModalHeader>
          <h2>{tableName}</h2>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>

        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div style={{ color: '#dc3545' }}>{error}</div>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  {columns.map(col => (
                    <Th key={col.column_name}>{col.column_name}</Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, i) => (
                  <tr key={i}>
                    {columns.map(col => (
                      <Td key={col.column_name}>
                        {typeof row[col.column_name] === 'object' 
                          ? JSON.stringify(row[col.column_name])
                          : row[col.column_name]}
                      </Td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Pagination>
              <PageButton 
                onClick={() => setPage(p => p - 1)}
                disabled={page === 0}
              >
                Previous
              </PageButton>
              <span>
                Page {page + 1} of {Math.ceil(totalRows / pageSize)}
              </span>
              <PageButton 
                onClick={() => setPage(p => p + 1)}
                disabled={(page + 1) * pageSize >= totalRows}
              >
                Next
              </PageButton>
            </Pagination>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default TableViewModal;
