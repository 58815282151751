import React, { useState, useEffect } from 'react';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor
} from '@dnd-kit/core';
import { BrowserRouter as Router, Routes, Route, useNavigate, useParams, useLocation, Navigate } from 'react-router-dom';
import DashboardGallery from './components/DashboardGallery';
import Login from './components/Login';
import Profile from './components/Profile';
import AccountSettings from './components/AccountSettings';
import Billing from './components/Billing';
import Usage from './components/Usage';
import AccountDrawer from './components/AccountDrawer';
import './App.css';
import AppHeader from './components/AppHeader';
import DashboardEditor from './components/DashboardEditor';
import { QueryClient, QueryClientProvider, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Extensions from './components/Extensions';
import { supabase } from './supabase';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import { useDashboards } from './hooks/useQueries';

// Wrapper component that has access to router hooks
function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const session = useSession();
  const supabase = useSupabaseClient();
  const queryClient = useQueryClient();

  const { data: dashboards = [], isLoading } = useDashboards();

  const [isEditing, setIsEditing] = useState(false);

  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);

  const handleCreateDashboard = async () => {
    try {
      const { data, error } = await supabase
        .from('dashboards')
        .insert([
          {
            name: 'New Dashboard',
            owner_id: session.user.id,
            theme: {
              background: '#f8f9fa'
            },
            settings: {
              layout: 'grid',
              spacing: 16
            }
          }
        ])
        .select()
        .single();

      if (error) throw error;

      // Create default section
      const { error: sectionError } = await supabase
        .from('dashboard_sections')
        .insert([
          {
            dashboard_id: data.id,
            label: 'Main Section',
            icon: 'dashboard',
            order: 0
          }
        ]);

      if (sectionError) throw sectionError;

      // Invalidate dashboards cache
      await queryClient.invalidateQueries(['dashboards']);

      navigate(`/dashboard/${data.id}`);
    } catch (err) {
      console.error('Error creating dashboard:', err);
    }
  };

  // Add this function to determine the current page title
  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/':
        return 'My Dashboards';
      case '/profile':
        return 'Profile';
      case '/account-settings':
        return 'Account Settings';
      case '/billing':
        return 'Billing';
      case '/usage':
        return 'Usage';
      case '/extensions':
        return 'Extensions';
      default:
        if (pathname.startsWith('/dashboard/')) {
          return 'Dashboard Editor';
        }
        return 'Dashboard Builder';
    }
  };

  const availableComponents = [
    {
      type: 'Statistics',
      icon: 'trending_up',
      label: 'Statistics',
      width: 3,
      height: 1,
      config: {
        label: 'New Statistic',
        value: '0',
        color: '#181aef'
      }
    },
    {
      type: 'Summary',
      icon: 'summarize',
      label: 'Summary',
      width: 3,
      height: 1,
      config: {
        label: 'New Summary',
        value: '0',
        color: '#181aef'
      }
    },
    {
      type: 'Table',
      icon: 'table_chart',
      label: 'Table',
      width: 12,
      height: 2,
      config: {
        title: 'New Table',
        columns: []
      }
    },
    {
      type: 'Form',
      icon: 'dynamic_form',
      label: 'Form',
      width: 6,
      height: 2,
      config: {
        title: 'New Form',
        steps: []
      }
    },
    {
      type: 'Text',
      icon: 'text_fields',
      label: 'Text',
      width: 4,
      height: 1,
      config: {
        content: 'New text content'
      }
    },
    {
      type: 'TodoList',
      icon: 'checklist',
      label: 'Todo List',
      width: 4,
      height: 2,
      config: {
        title: 'New Todo List',
        todos: []
      }
    },
    {
      type: 'EmptySpace',
      label: 'Empty Area',
      icon: 'space_bar',
      width: 12,
      height: 1,
      config: {
        height: '1rem'
      }
    },

    // Charts / Visualizations
    {
      type: 'LineChart',
      icon: 'show_chart',
      label: 'Line Chart',
      width: 6,
      height: 2,
      isAvailable: false,
      config: {
        title: 'New Line Chart',
        data: [],
        xAxis: '',
        yAxis: ''
      }
    },
    {
      type: 'BarChart',
      icon: 'bar_chart',
      label: 'Bar Chart',
      width: 6,
      height: 2,
      isAvailable: false,
      config: {
        title: 'New Bar Chart',
        data: [],
        orientation: 'vertical'
      }
    },
    {
      type: 'PieChart',
      icon: 'pie_chart',
      label: 'Pie Chart',
      width: 4,
      height: 2,
      isAvailable: false,
      config: {
        title: 'New Pie Chart',
        data: [],
        showLegend: true
      }
    },

    // Status/Progress Components 
    {
      type: 'ProgressBar',
      icon: 'linear_scale',
      label: 'Progress Bar',
      width: 6,
      height: 1,
      isAvailable: false,
      config: {
        title: 'Progress',
        value: 0,
        max: 100,
        showPercentage: true
      }
    },
    {
      type: 'StatusCard',
      icon: 'info',
      label: 'Status Card',
      width: 3,
      height: 1,
      isAvailable: false,
      config: {
        title: 'Status',
        status: 'active',
        color: 'green'
      }
    },

    // Interactive Components
    {
      type: 'FilterPanel',
      icon: 'filter_list',
      label: 'Filter Panel',
      width: 12,
      height: 1,
      isAvailable: false,
      config: {
        filters: [],
        orientation: 'horizontal'
      }
    },
    {
      type: 'DateRangePicker',
      icon: 'date_range',
      label: 'Date Range',
      width: 4,
      height: 1,
      isAvailable: false,
      config: {
        label: 'Select Date Range',
        defaultRange: '7d'
      }
    },
    {
      type: 'SearchBar',
      icon: 'search',
      label: 'Search',
      width: 4,
      height: 1,
      isAvailable: false,
      config: {
        placeholder: 'Search...',
        searchFields: []
      }
    },

    // Information Display
    {
      type: 'Alert',
      icon: 'notification_important',
      label: 'Alert Box',
      width: 12,
      height: 1,
      isAvailable: false,
      config: {
        type: 'info',
        message: 'New alert message',
        dismissible: true
      }
    },
    {
      type: 'Timeline',
      icon: 'timeline',
      label: 'Timeline',
      width: 6,
      height: 3,
      isAvailable: false,
      config: {
        title: 'Activity Timeline',
        items: []
      }
    },
    {
      type: 'MetricCard',
      icon: 'analytics',
      label: 'Metric Card',
      width: 3,
      height: 1,
      isAvailable: false,
      config: {
        title: 'Metric',
        value: '0',
        trend: '+0%',
        comparison: 'vs last period'
      }
    },

    // Layout Components
    {
      type: 'Divider',
      icon: 'horizontal_rule',
      label: 'Divider',
      width: 12,
      height: 1,
      isAvailable: false,
      config: {
        style: 'solid',
        color: '#e0e0e0'
      }
    },
    {
      type: 'Card',
      icon: 'dashboard_customize',
      label: 'Card Container',
      width: 6,
      height: 2,
      isAvailable: false,
      config: {
        title: 'Card Title',
        elevation: 1,
        padding: '1rem'
      }
    },
    {
      type: 'Tabs',
      icon: 'tab',
      label: 'Tab Container',
      width: 12,
      height: 3,
      isAvailable: false,
      config: {
        tabs: [],
        orientation: 'horizontal'
      }
    },
  ];

  return (
    <>
      <AppHeader
        isVisible={!isAccountDrawerOpen && !location.pathname.startsWith('/dashboard/')}
        onMenuClick={() => setIsAccountDrawerOpen(true)}
        title={getPageTitle(location.pathname)}
      />

      <AccountDrawer
        open={isAccountDrawerOpen}
        onClose={() => setIsAccountDrawerOpen(false)}
      />

      <Routes>
        <Route path="/" element={
          <DashboardGallery
            dashboards={dashboards}
            onCreateNew={handleCreateDashboard}
            isLoading={isLoading}
          />
        } />
        <Route
          path="/dashboard/:id"
          element={
            <DashboardEditor
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              dashboards={dashboards}
              availableComponents={availableComponents}
            />
          }
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/account-settings" element={<AccountSettings />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/usage" element={<Usage />} />
        <Route path="/extensions" element={<Extensions />} />
      </Routes>
    </>
  );
}

// Main App component just provides the router context
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60,
        cacheTime: 1000 * 60 * 5,
      },
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check for existing session on mount
    const initializeAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession()
        if (session) {
          console.log('Existing session found')
        }
      } finally {
        setIsLoading(false)
      }
    }
    initializeAuth()
  }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SessionContextProvider supabaseClient={supabase}>
        <Router>
          <AuthenticatedApp />
        </Router>
      </SessionContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

function AuthenticatedApp() {
  const session = useSession()
  const supabase = useSupabaseClient()

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, currentSession) => {
      console.log('Auth state changed:', event)

      if (event === 'TOKEN_REFRESHED') {
        console.log('Token refreshed successfully')
      }

      if (event === 'SIGNED_OUT') {
        // Clear any application state
        window.localStorage.removeItem('sb-auth-token')
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [supabase])

  return (
    <Routes>
      {!session ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/*" element={<AppContent />} />
        </>
      )}
    </Routes>
  );
}

export default App;
