import React, { useState } from 'react';
import styled from '@emotion/styled';

const FormContainer = styled.div`
  width: 100%;
  min-height: 400px;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding-bottom: 1rem;
  
  h3 {
    margin: 0;
    color: ${props => props.color};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  margin: 2rem 0;
  flex-wrap: wrap;
  position: relative;
`;

const StepItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 8px;
`;

const StepDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.active ? props.color : '#e5e7eb'};
  transition: background-color 0.2s;
  position: relative;
  z-index: 1;
`;

const FormField = styled.div`
  width: 100%;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  input {
    width: 100%;
    padding: 0.75rem 0.5rem;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 100%;
    background: ${props => props.isEditing ? 'transparent' : '#F3F4F6'};
    transition: background-color 0.2s ease;
    
    &:hover {
      background: #F3F4F6;
    }
    
    &:focus {
      background: #F3F4F6;
      outline: none;
      border-color: ${props => props.color};
      box-shadow: 0 0 0 2px ${props => props.color}20;
    }
  }

  select {
    width: 100%;
    padding: 0.75rem 0.5rem;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    max-width: 100%;
    background: #F3F4F6;
    transition: background-color 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: ${props => props.color};
      box-shadow: 0 0 0 2px ${props => props.color}20;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: ${props => props.primary ? props.color : 'transparent'};
  color: ${props => props.primary ? 'white' : props.color};
  border: 1px solid ${props => props.color};
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const EditableInput = styled.input`
  background: transparent;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  box-sizing: border-box;
  max-width: 100%;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  &:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
  }
`;

const EditButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
  color: #2563eb;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  
  &:hover {
    color: #1d4ed8;
  }

  .material-icons {
    font-size: 18px;
  }
`;

const FieldControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  
  .field-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const NavButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .material-icons {
    font-size: 20px;
    margin: 0;
  }
`;

const FormContent = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const FieldsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: 'transparent';
  border-radius: 12px;
  border: 'none';
`;

const FieldWrapper = styled.div`
  margin-bottom: 1rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const FileUploadArea = styled.div`
  width: 100%;
  padding: 2rem;
  border: 2px dashed #ddd;
  border-radius: 8px;
  background: #f9fafb;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
  box-sizing: border-box;

  &:hover {
    background: #f3f4f6;
    border-color: #ccc;
  }

  input[type="file"] {
    display: none;
  }

  .material-icons {
    font-size: 2rem;
    color: #666;
  }

  p {
    margin: 0;
    color: #666;
    text-align: center;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  .file-name {
    color: #2563eb;
    font-size: 0.875rem;
    word-break: break-all;
    max-width: 100%;
  }
`;

const FormWidget = ({ config, isEditing, onUpdate }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});

  if (!config.steps || !Array.isArray(config.steps)) {
    config.steps = [{
      title: 'Step 1',
      fields: []
    }];
  }

  if (currentStep >= config.steps.length) {
    setCurrentStep(config.steps.length - 1);
  }

  const currentStepData = config.steps[currentStep] || { fields: [] };

  const handleUpdate = (updates) => {
    if (onUpdate) {
      onUpdate({
        ...config,
        ...updates
      });
    }
  };

  const handleFieldChange = (fieldId, value) => {
    setFormData(prev => ({
      ...prev,
      [fieldId]: value
    }));
  };

  const handleAddField = (stepIndex) => {
    const newSteps = [...config.steps];
    const newField = {
      id: `field${Date.now()}`,
      type: 'text',
      label: 'New Field',
      placeholder: 'Enter value',
      required: false
    };
    newSteps[stepIndex].fields.push(newField);
    handleUpdate({ steps: newSteps });
  };

  const handleDeleteField = (stepIndex, fieldId) => {
    const newSteps = [...config.steps];
    newSteps[stepIndex].fields = newSteps[stepIndex].fields.filter(
      field => field.id !== fieldId
    );
    handleUpdate({ steps: newSteps });
  };

  const handleFieldUpdate = (stepIndex, fieldId, updates) => {
    const newSteps = [...config.steps];
    const fieldIndex = newSteps[stepIndex].fields.findIndex(f => f.id === fieldId);
    newSteps[stepIndex].fields[fieldIndex] = {
      ...newSteps[stepIndex].fields[fieldIndex],
      ...updates
    };
    handleUpdate({ steps: newSteps });
  };

  const handleAddStep = () => {
    const newSteps = [
      ...(Array.isArray(config.steps) ? config.steps : []),
      {
        title: `Step ${(config.steps?.length || 0) + 1}`,
        fields: []
      }
    ];
    handleUpdate({ steps: newSteps });
  };

  const cleanupEmptySteps = () => {
    if (!config.steps || !Array.isArray(config.steps)) return;

    const nonEmptySteps = config.steps.filter(step =>
      step && Array.isArray(step.fields) && step.fields.length > 0
    );

    if (nonEmptySteps.length === 0) {
      nonEmptySteps.push({
        title: 'Step 1',
        fields: []
      });
    }

    if (nonEmptySteps.length !== config.steps.length) {
      handleUpdate({ steps: nonEmptySteps });

      if (currentStep >= nonEmptySteps.length) {
        setCurrentStep(Math.max(0, nonEmptySteps.length - 1));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <FormContainer>
      <FormHeader color={config.color}>
        {isEditing ? (
          <EditableInput
            value={config.title}
            onChange={(e) => handleUpdate({ title: e.target.value })}
            placeholder="Enter form title"
          />
        ) : (
          <h3>{config.title}</h3>
        )}
      </FormHeader>

      <FormContent onSubmit={handleSubmit}>
        <FieldsContainer isEditing={isEditing}>
          {currentStepData.fields?.map(field => (
            <FieldWrapper key={field.id}>
              <FormField isEditing={isEditing}>
                {isEditing ? (
                  <FieldControls>
                    <div className="field-header">
                      <EditableInput
                        value={field.label}
                        onChange={(e) => handleFieldUpdate(currentStep, field.id, { label: e.target.value })}
                        placeholder="Field label"
                      />
                      <EditButton onClick={() => handleDeleteField(currentStep, field.id)}>
                        <span className="material-icons">delete</span>
                      </EditButton>
                    </div>
                    <select
                      value={field.type}
                      onChange={(e) => handleFieldUpdate(currentStep, field.id, { type: e.target.value })}
                    >
                      <option value="text">Text</option>
                      <option value="email">Email</option>
                      <option value="number">Number</option>
                      <option value="tel">Phone</option>
                      <option value="date">Date</option>
                      <option value="file">File Upload</option>
                    </select>
                  </FieldControls>
                ) : (
                  <>
                    <label>{field.label}{field.required && ' *'}</label>
                    {field.type === 'file' ? (
                      <FileUploadArea
                        onClick={() => document.getElementById(`file-${field.id}`).click()}
                      >
                        <input
                          id={`file-${field.id}`}
                          type="file"
                          onChange={(e) => handleFieldChange(field.id, e.target.files[0])}
                          required={field.required}
                        />
                        <span className="material-icons">upload_file</span>
                        <p>
                          {formData[field.id] 
                            ? <span className="file-name">{formData[field.id].name}</span>
                            : 'Click to upload or drag and drop'}
                        </p>
                      </FileUploadArea>
                    ) : (
                      <input
                        type={field.type}
                        placeholder={field.placeholder}
                        value={formData[field.id] || ''}
                        onChange={(e) => handleFieldChange(field.id, e.target.value)}
                        required={field.required}
                      />
                    )}
                  </>
                )}
              </FormField>
            </FieldWrapper>
          ))}

          {isEditing && (
            <FieldActions>
              <EditButton onClick={() => handleAddField(currentStep)}>
                <span className="material-icons">add</span>
                Add Field
              </EditButton>
              
              <EditButton 
                onClick={() => {
                  const newSteps = config.steps.filter((_, index) => index !== currentStep);
                  if (newSteps.length === 0) {
                    newSteps.push({
                      title: 'Step 1',
                      fields: []
                    });
                  }
                  handleUpdate({ steps: newSteps });
                  if (currentStep >= newSteps.length) {
                    setCurrentStep(Math.max(0, newSteps.length - 1));
                  }
                }}
              >
                <span className="material-icons">delete</span>
                Delete Section
              </EditButton>
            </FieldActions>
          )}
        </FieldsContainer>

        <ButtonGroup>
          <div>
            {currentStep > 0 && (
              <NavButton
                type="button"
                onClick={() => setCurrentStep(prev => prev - 1)}
                color={config.color}
              >
                <span className="material-icons">arrow_back</span>
              </NavButton>
            )}
          </div>

          {(config.steps.length > 1 || isEditing) && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {config.steps.map((step, index) => (
                <StepItem
                  key={index}
                  active={index <= currentStep}
                  color={config.color}
                >
                  <StepDot
                    active={index <= currentStep}
                    color={config.color}
                    onClick={() => isEditing && setCurrentStep(index)}
                    style={{ cursor: isEditing ? 'pointer' : 'default' }}
                  />
                </StepItem>
              ))}
              {isEditing && (
                <EditButton onClick={handleAddStep}>
                  <span className="material-icons">add</span>
                </EditButton>
              )}
            </div>
          )}

          <div>
            {currentStep < config.steps.length - 1 ? (
              <NavButton
                type="button"
                onClick={() => {
                  if (isEditing) {
                    const nextStep = currentStep + 1;
                    if (nextStep < config.steps.length) {
                      setCurrentStep(nextStep);
                    }
                  } else {
                    setCurrentStep(prev => prev + 1);
                  }
                }}
                primary
                color={config.color}
              >
                <span className="material-icons">arrow_forward</span>
              </NavButton>
            ) : (
              !isEditing && (
                <Button
                  type="submit"
                  primary
                  color={config.color}
                >
                  {config.submitButton?.label || 'Submit'}
                </Button>
              )
            )}
          </div>
        </ButtonGroup>
      </FormContent>
    </FormContainer>
  );
};

export default FormWidget;
