import React from 'react';
import styled from '@emotion/styled';

const SpaceContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${props => props.height || '1rem'};
  background: transparent;
  border: ${props => props.isEditing ? '1px dashed #ccc' : 'none'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.875rem;
`;

const EmptySpaceWidget = ({ config, isEditing }) => {
    return (
        <SpaceContainer
            height={config?.height}
            isEditing={isEditing}
        >
            {isEditing && <span>Empty Area</span>}
        </SpaceContainer>
    );
};

export default EmptySpaceWidget;