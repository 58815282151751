import React from 'react';
import styled from '@emotion/styled';
import { useDataSources } from '../../hooks/useQueries';
import { ConfigField, Label, Select } from './ConfigurationStyles';
import StorageIcon from '@mui/icons-material/Storage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const ConnectionStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: ${props => props.connected ? '#059669' : '#DC2626'};

  svg {
    font-size: 1rem;
  }
`;

const LabelWithIcon = styled(Label)`
  gap: 0.5rem;
  margin-left: 0;
  padding-left: 0;
  align-self: flex-start;
  align-items: flex-start;
  display: flex;

  svg {
    font-size: 1.2rem;
    color: #666;
    margin-left: 0;
  }
`;

const DataSourceSelect = ({ selectedSource, onSourceSelect }) => {
    const { data: dataSources = [], isLoading } = useDataSources();

    return (
        <ConfigField>
            <LabelWithIcon>
                <StorageIcon />
                Data Source
            </LabelWithIcon>
            <Select
                value={selectedSource?.id || ''}
                onChange={(e) => {
                    const source = dataSources.find(s => s.id === parseInt(e.target.value));
                    onSourceSelect(source || null);
                }}
                disabled={isLoading}
            >
                <option value="">Select a data source</option>
                {dataSources.map(source => (
                    <option key={source.id} value={source.id}>
                        {source.name} ({source.type})
                    </option>
                ))}
            </Select>
            {selectedSource && (
                <ConnectionStatus connected={selectedSource.status === 'Connected'}>
                    {selectedSource.status === 'Connected' ? 
                        <CheckCircleIcon /> : 
                        <ErrorIcon />
                    }
                    {selectedSource.status}
                </ConnectionStatus>
            )}
        </ConfigField>
    );
};

export default DataSourceSelect;