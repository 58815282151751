import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useDataSources, useRemoveDataSource } from '../hooks/useQueries';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabase';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 900px;
  max-width: 95vw;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  
  h2 {
    margin: 0;
    color: #333;
  }
`;

const TabContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
`;

const Tab = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  cursor: pointer;
  color: ${props => props.active ? '#007bff' : '#666'};
  border-bottom: 2px solid ${props => props.active ? '#007bff' : 'transparent'};
  transition: all 0.2s;

  &:hover {
    color: #007bff;
  }
`;

const Section = styled.div`
  margin-bottom: 2rem;
  
  h3 {
    margin-bottom: 1rem;
    color: #444;
  }
`;

const SettingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #555;
`;

const ColorInput = styled.input`
  width: 50px;
  height: 30px;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  
  &.primary {
    background: #007bff;
    color: white;
    
    &:hover {
      background: #0056b3;
    }
  }
  
  &.secondary {
    background: #f8f9fa;
    border: 1px solid #ddd;
    
    &:hover {
      background: #e9ecef;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`;

const RangeInput = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  input[type="range"] {
    flex: 1;
  }

  .value {
    min-width: 45px;
    color: #666;
  }
`;

const DataSourceList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DataSourceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 6px;
  border: 1px solid #eee;
`;

const DataSourceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .icon {
    width: 40px;
    height: 40px;
    background: #e9ecef;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .material-icons {
      color: #666;
    }
  }

  .details {
    h4 {
      margin: 0;
      color: #333;
    }
    
    .type {
      color: #666;
      font-size: 0.875rem;
    }
  }
`;

const StatusBadge = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  
  ${props => props.status === 'Connected' ? `
    background: #d1fae5;
    color: #065f46;
  ` : `
    background: #fee2e2;
    color: #991b1b;
  `}
`;

const AddSourceButton = styled(Button)`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  background: #f0f9ff;
  color: #0369a1;
  border: 1px dashed #0369a1;

  &:hover {
    background: #e0f2fe;
  }
`;

const ExtensionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`;

const ExtensionCard = styled.div`
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ExtensionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ExtensionIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${props => props.bgColor || '#e3f2fd'};
  color: ${props => props.color || '#1976d2'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  .material-icons {
    font-size: 20px;
  }
`;

const ExtensionInfo = styled.div`
  flex: 1;
  
  h4 {
    margin: 0;
    color: #333;
  }
  
  p {
    margin: 0.25rem 0 0 0;
    color: #666;
    font-size: 0.875rem;
  }
`;

const PublishOption = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  margin-bottom: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  background: ${props => props.selected ? '#f0f7ff' : 'white'};
  border-color: ${props => props.selected ? '#007bff' : '#eee'};

  &:hover {
    border-color: #007bff;
  }
`;

const PublishIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: ${props => props.selected ? '#e3f2fd' : '#f8f9fa'};
  color: ${props => props.selected ? '#007bff' : '#666'};
  display: flex;
  align-items: center;
  justify-content: center;
  
  .material-icons {
    font-size: 24px;
  }
`;

const PublishInfo = styled.div`
  flex: 1;
  
  h4 {
    margin: 0;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
    line-height: 1.4;
  }
`;

const DangerSection = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #dee2e6;
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: ${props => props.isLoading ? 'not-allowed' : 'pointer'};
  font-size: 0.9rem;
  opacity: ${props => props.isLoading ? 0.7 : 1};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  &:hover {
    background-color: ${props => props.isLoading ? '#dc3545' : '#c82333'};
  }
`;

const SettingsModal = ({ isOpen, onClose, settings, onSave }) => {
  const defaultSettings = {
    showSidebar: true,
    showTopBar: true,
    compactMode: false,
    primaryColor: '#007bff',
    darkMode: false,
    fontSize: 'medium',
    components: {
      card: {
        borderRadius: '8px',
        padding: '1rem',
        shadowIntensity: 'medium'
      }
    },
    publishing: {
      type: 'public'
    }
  };

  const [activeTab, setActiveTab] = useState('appearance');
  const [tempSettings, setTempSettings] = useState({ ...defaultSettings, ...settings });
  const [isDeleting, setIsDeleting] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: dataSources = [], isLoading } = useDataSources();
  const removeDataSource = useRemoveDataSource();

  const handleChange = (key, value) => {
    setTempSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleComponentChange = (component, property, value) => {
    setTempSettings(prev => ({
      ...prev,
      components: {
        ...prev.components,
        [component]: {
          ...prev.components[component],
          [property]: value
        }
      }
    }));
  };

  const handleSave = () => {
    onSave(tempSettings);
    onClose();
  };

  const handleDeleteDashboard = async () => {
    if (window.confirm('Are you sure you want to delete this dashboard? This action cannot be undone.')) {
      try {
        setIsDeleting(true);

        // Delete all components
        const { error: componentsError } = await supabase
          .from('dashboard_components')
          .delete()
          .eq('dashboard_id', id);
        
        if (componentsError) throw componentsError;

        // Delete all sections
        const { error: sectionsError } = await supabase
          .from('dashboard_sections')
          .delete()
          .eq('dashboard_id', id);
        
        if (sectionsError) throw sectionsError;

        // Delete preview image
        const { error: storageError } = await supabase.storage
          .from('dashboard-assets')
          .remove([`previews/${id}/dashboard-preview.jpg`]);
        
        if (storageError) throw storageError;

        // Delete the dashboard
        const { error: dashboardError } = await supabase
          .from('dashboards')
          .delete()
          .eq('id', id);
        
        if (dashboardError) throw dashboardError;

        // Invalidate queries and navigate
        await queryClient.invalidateQueries(['dashboards']);
        navigate('/');
      } catch (error) {
        console.error('Error deleting dashboard:', error);
        alert('Failed to delete dashboard. Please try again.');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  useEffect(() => {
    if (isDeleting) {
      document.body.classList.add('deleting');
    } else {
      document.body.classList.remove('deleting');
    }

    const handleBeforeUnload = (e) => {
      if (isDeleting) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.body.classList.remove('deleting');
    };
  }, [isDeleting]);

  // Prevent modal close during deletion
  const handleClose = () => {
    if (!isDeleting) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <ModalOverlay onClick={handleClose}>
        <ModalContent onClick={e => e.stopPropagation()}>
          <ModalHeader>
            <h2>Dashboard Settings</h2>
            <Button className="secondary" onClick={onClose}>
              <span className="material-icons">close</span>
            </Button>
          </ModalHeader>

          <TabContainer>
            <Tab 
              active={activeTab === 'appearance'} 
              onClick={() => setActiveTab('appearance')}
            >
              Appearance
            </Tab>
            <Tab 
              active={activeTab === 'dataSources'} 
              onClick={() => setActiveTab('dataSources')}
            >
              Data Sources
            </Tab>
            <Tab 
              active={activeTab === 'extensions'} 
              onClick={() => setActiveTab('extensions')}
            >
              Extensions
            </Tab>
            <Tab 
              active={activeTab === 'publishing'} 
              onClick={() => setActiveTab('publishing')}
            >
              Publishing
            </Tab>
          </TabContainer>

          {activeTab === 'appearance' && (
            <>
              <Section>
                <h3>Layout Options</h3>
                <SettingRow>
                  <Label>
                    <span className="material-icons">view_sidebar</span>
                    Show Sidebar
                  </Label>
                  <input
                    type="checkbox"
                    checked={tempSettings.showSidebar}
                    onChange={e => handleChange('showSidebar', e.target.checked)}
                  />
                </SettingRow>
                <SettingRow>
                  <Label>
                    <span className="material-icons">horizontal_split</span>
                    Show Top Bar
                  </Label>
                  <input
                    type="checkbox"
                    checked={tempSettings.showTopBar}
                    onChange={e => handleChange('showTopBar', e.target.checked)}
                  />
                </SettingRow>
                <SettingRow>
                  <Label>
                    <span className="material-icons">view_compact</span>
                    Compact Mode
                  </Label>
                  <input
                    type="checkbox"
                    checked={tempSettings.compactMode}
                    onChange={e => handleChange('compactMode', e.target.checked)}
                  />
                </SettingRow>
              </Section>

              <Section>
                <h3>Theme Options</h3>
                <SettingRow>
                  <Label>
                    <span className="material-icons">palette</span>
                    Primary Color
                  </Label>
                  <ColorInput
                    type="color"
                    value={tempSettings.primaryColor}
                    onChange={e => handleChange('primaryColor', e.target.value)}
                  />
                </SettingRow>
                <SettingRow>
                  <Label>
                    <span className="material-icons">dark_mode</span>
                    Dark Mode
                  </Label>
                  <input
                    type="checkbox"
                    checked={tempSettings.darkMode}
                    onChange={e => handleChange('darkMode', e.target.checked)}
                  />
                </SettingRow>
                <SettingRow>
                  <Label>
                    <span className="material-icons">font_download</span>
                    Font Size
                  </Label>
                  <select
                    value={tempSettings.fontSize}
                    onChange={e => handleChange('fontSize', e.target.value)}
                  >
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                  </select>
                </SettingRow>
              </Section>

              <Section>
                <h3>Card Settings</h3>
                <SettingRow>
                  <Label>
                    <span className="material-icons">rounded_corner</span>
                    Border Radius
                  </Label>
                  <RangeInput>
                    <input
                      type="range"
                      min="0"
                      max="24"
                      value={parseInt(tempSettings.components.card.borderRadius)}
                      onChange={e => handleComponentChange('card', 'borderRadius', `${e.target.value}px`)}
                    />
                    <span className="value">{tempSettings.components.card.borderRadius}</span>
                  </RangeInput>
                </SettingRow>
                <SettingRow>
                  <Label>
                    <span className="material-icons">padding</span>
                    Padding
                  </Label>
                  <select
                    value={tempSettings.components.card.padding}
                    onChange={e => handleComponentChange('card', 'padding', e.target.value)}
                  >
                    <option value="0.5rem">Compact</option>
                    <option value="1rem">Normal</option>
                    <option value="1.5rem">Spacious</option>
                  </select>
                </SettingRow>
                <SettingRow>
                  <Label>
                    <span className="material-icons">blur_on</span>
                    Shadow Intensity
                  </Label>
                  <select
                    value={tempSettings.components.card.shadowIntensity}
                    onChange={e => handleComponentChange('card', 'shadowIntensity', e.target.value)}
                  >
                    <option value="none">None</option>
                    <option value="light">Light</option>
                    <option value="medium">Medium</option>
                    <option value="strong">Strong</option>
                  </select>
                </SettingRow>
              </Section>
            </>
          )}

          {activeTab === 'dataSources' && (
            <Section>
              <AddSourceButton onClick={() => alert('Add data source modal')}>
                <span className="material-icons">add_circle</span>
                Connect New Data Source
              </AddSourceButton>

              <DataSourceList>
                {isLoading ? (
                  <div>Loading data sources...</div>
                ) : dataSources.length === 0 ? (
                  <div style={{ textAlign: 'center', color: '#666', padding: '2rem' }}>
                    No data sources connected yet
                  </div>
                ) : (
                  dataSources.map(source => (
                    <DataSourceItem key={source.id}>
                      <DataSourceInfo>
                        <div className="icon">
                          <span className="material-icons">
                            {source.type === 'PostgreSQL' ? 'storage' : 
                             source.type === 'MongoDB' ? 'view_agenda' : 
                             'database'}
                          </span>
                        </div>
                        <div className="details">
                          <h4>{source.name}</h4>
                          <div className="type">{source.type}</div>
                        </div>
                      </DataSourceInfo>
                      
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <StatusBadge status={source.status}>
                          <span className="material-icons" style={{ fontSize: '0.875rem' }}>
                            {source.status === 'Connected' ? 'check_circle' : 'error'}
                          </span>
                          {source.status}
                        </StatusBadge>
                        
                        <Button 
                          className="secondary"
                          onClick={() => {
                            if (window.confirm('Are you sure you want to remove this data source?')) {
                              removeDataSource.mutate(source.id);
                            }
                          }}
                        >
                          <span className="material-icons">delete</span>
                        </Button>
                      </div>
                    </DataSourceItem>
                  ))
                )}
              </DataSourceList>
            </Section>
          )}

          {activeTab === 'extensions' && (
            <Section>
              <h3>Extensions</h3>
              <ExtensionGrid>
                {[
                  {
                    id: 1,
                    name: 'Login Kit',
                    description: 'Add social login options and enhanced authentication features',
                    icon: 'login',
                    color: '#1976d2',
                    bgColor: '#e3f2fd',
                    installed: true
                  },
                  {
                    id: 2,
                    name: 'Email Notifications',
                    description: 'Send automated emails based on dashboard events and triggers',
                    icon: 'mail',
                    color: '#0d9488',
                    bgColor: '#ccfbf1',
                    installed: false
                  },
                  {
                    id: 3,
                    name: 'Data Export',
                    description: 'Export dashboard data in various formats (CSV, PDF, Excel)',
                    icon: 'download',
                    color: '#7c3aed',
                    bgColor: '#ede9fe',
                    installed: false
                  },
                  {
                    id: 4,
                    name: 'Analytics',
                    description: 'Track dashboard usage and user engagement metrics',
                    icon: 'analytics',
                    color: '#2563eb',
                    bgColor: '#dbeafe',
                    installed: true
                  }
                ].map(extension => (
                  <ExtensionCard key={extension.id}>
                    <ExtensionHeader>
                      <ExtensionIcon color={extension.color} bgColor={extension.bgColor}>
                        <span className="material-icons">{extension.icon}</span>
                      </ExtensionIcon>
                      <ExtensionInfo>
                        <h4>{extension.name}</h4>
                        <p>{extension.description}</p>
                      </ExtensionInfo>
                    </ExtensionHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <StatusBadge status={extension.installed ? 'Connected' : 'Available'}>
                        <span className="material-icons" style={{ fontSize: '0.875rem' }}>
                          {extension.installed ? 'check_circle' : 'extension'}
                        </span>
                        {extension.installed ? 'Installed' : 'Available'}
                      </StatusBadge>
                      <Button 
                        className={extension.installed ? 'primary' : 'secondary'}
                        onClick={() => {
                          if (extension.installed) {
                            // Handle manage action
                            console.log('Managing extension:', extension.name);
                          } else {
                            // Handle install action
                            console.log('Installing extension:', extension.name);
                          }
                        }}
                      >
                        {extension.installed ? 'Manage' : 'Install'}
                      </Button>
                    </div>
                  </ExtensionCard>
                ))}
              </ExtensionGrid>
            </Section>
          )}

          {activeTab === 'publishing' && (
            <Section>
              <h3>Publishing Options</h3>
              <PublishOption selected={tempSettings?.publishing?.type === 'public'}>
                <PublishIcon selected={tempSettings?.publishing?.type === 'public'}>
                  <span className="material-icons">public</span>
                </PublishIcon>
                <PublishInfo>
                  <h4>Public URL</h4>
                  <p>Share your dashboard with a public URL that anyone can access. Perfect for public dashboards and reports.</p>
                  {tempSettings?.publishing?.type === 'public' && (
                    <div style={{ marginTop: '1rem' }}>
                      <SettingRow>
                        <Label>Dashboard URL</Label>
                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                          <input 
                            type="text" 
                            value="https://dashboards.app/public/dashboard-123"
                            readOnly
                            style={{ 
                              padding: '0.5rem',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                              width: '300px'
                            }}
                          />
                          <Button className="secondary">
                            <span className="material-icons">content_copy</span>
                          </Button>
                        </div>
                      </SettingRow>
                    </div>
                  )}
                </PublishInfo>
              </PublishOption>

              <PublishOption selected={tempSettings?.publishing?.type === 'custom-domain'}>
                <PublishIcon selected={tempSettings?.publishing?.type === 'custom-domain'}>
                  <span className="material-icons">language</span>
                </PublishIcon>
                <PublishInfo>
                  <h4>Custom Domain</h4>
                  <p>Host your dashboard on your own domain. Great for white-labeled solutions and enterprise deployments.</p>
                  {tempSettings?.publishing?.type === 'custom-domain' && (
                    <div style={{ marginTop: '1rem' }}>
                      <SettingRow>
                        <Label>Domain Name</Label>
                        <input 
                          type="text" 
                          placeholder="dashboard.yourcompany.com"
                          style={{ 
                            padding: '0.5rem',
                            borderRadius: '4px',
                            border: '1px solid #ddd',
                            width: '300px'
                          }}
                        />
                      </SettingRow>
                      <div style={{ marginTop: '1rem', padding: '1rem', background: '#f8f9fa', borderRadius: '4px' }}>
                        <h5 style={{ margin: '0 0 0.5rem 0', color: '#333' }}>DNS Configuration</h5>
                        <p style={{ margin: '0', fontSize: '0.9rem', color: '#666' }}>
                          Add these records to your domain's DNS settings:
                        </p>
                        <pre style={{ 
                          margin: '0.5rem 0 0 0',
                          padding: '0.5rem',
                          background: '#eee',
                          borderRadius: '4px',
                          fontSize: '0.85rem'
                        }}>
                          CNAME dashboard.yourcompany.com dashboards.app
                        </pre>
                      </div>
                    </div>
                  )}
                </PublishInfo>
              </PublishOption>

              <PublishOption selected={tempSettings?.publishing?.type === 'embed'}>
                <PublishIcon selected={tempSettings?.publishing?.type === 'embed'}>
                  <span className="material-icons">code</span>
                </PublishIcon>
                <PublishInfo>
                  <h4>Embed</h4>
                  <p>Embed your dashboard directly into your website or application using an iframe or our JavaScript SDK.</p>
                  {tempSettings?.publishing?.type === 'embed' && (
                    <div style={{ marginTop: '1rem' }}>
                      <SettingRow>
                        <Label>Embed Code</Label>
                        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'flex-start' }}>
                          <textarea 
                            readOnly
                            value={`<iframe
  src="https://dashboards.app/embed/dashboard-123"
  width="100%"
  height="600"
  frameborder="0"
></iframe>`}
                            style={{ 
                              padding: '0.5rem',
                              borderRadius: '4px',
                              border: '1px solid #ddd',
                              width: '300px',
                              height: '100px',
                              fontFamily: 'monospace',
                              fontSize: '0.85rem'
                            }}
                          />
                          <Button className="secondary">
                            <span className="material-icons">content_copy</span>
                          </Button>
                        </div>
                      </SettingRow>
                    </div>
                  )}
                </PublishInfo>
              </PublishOption>
            </Section>
          )}

          <DangerSection>
            <h3 style={{ color: '#dc3545', marginBottom: '1rem' }}>Danger Zone</h3>
            <p style={{ marginBottom: '1rem', color: '#666' }}>
              Once you delete a dashboard, there is no going back. Please be certain.
            </p>
            <DeleteButton 
              onClick={handleDeleteDashboard} 
              disabled={isDeleting}
              isLoading={isDeleting}
            >
              {isDeleting ? (
                <>
                  <span className="material-icons" style={{ animation: 'spin 1s linear infinite' }}>
                    sync
                  </span>
                  Deleting...
                </>
              ) : (
                <>Delete Dashboard</>
              )}
            </DeleteButton>
          </DangerSection>

          <ButtonGroup>
            <Button className="secondary" onClick={onClose}>Cancel</Button>
            <Button className="primary" onClick={handleSave}>Save Changes</Button>
          </ButtonGroup>
        </ModalContent>
      </ModalOverlay>

      {isDeleting && (
        <div className="delete-overlay">
          <span className="material-icons spinner">
            sync
          </span>
          <div>Deleting Dashboard...</div>
          <div style={{ fontSize: '0.875rem', opacity: 0.8 }}>
            Please don't close this window
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsModal;
