import React from 'react';
import styled from '@emotion/styled';

const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  background: white;
  box-shadow: -2px 0 4px rgba(0,0,0,0.1);
  z-index: 1000;
  padding: 5rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    
    &:hover {
      background: #f5f5f5;
      border-radius: 4px;
    }
  }
`;

const ComponentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
`;

const ComponentItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: ${props => props.isAvailable ? 'pointer' : 'not-allowed'};
  transition: background 0.2s;
  opacity: ${props => props.isAvailable ? 1 : 0.6};

  &:hover {
    background: ${props => props.isAvailable ? '#f5f5f5' : 'transparent'};
  }

  .material-icons {
    color: #666;
  }
`;

const Badge = styled.span`
  background: #ff4444;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-left: auto;
`;

const ComponentLabel = styled.span`
  font-weight: ${props => props.isAvailable ? 'bold' : 'normal'};
`;

const ComponentListSidebar = ({ availableComponents, onAddComponent, onClose }) => {
    return (
        <Sidebar>
            <Header>
                <h3>Add Widget</h3>
                <button onClick={onClose}>
                    <span className="material-icons">close</span>
                </button>
            </Header>
            <ComponentList>
                {availableComponents.map(component => (
                    <ComponentItem
                        key={component.type}
                        onClick={() => onAddComponent(component)}
                        isAvailable={component.isAvailable !== false}
                    >
                        <span className="material-icons">{component.icon}</span>
                        <ComponentLabel isAvailable={component.isAvailable !== false}>
                            {component.label}
                        </ComponentLabel>
                        {component.isAvailable === false && (
                            <Badge>not available</Badge>
                        )}
                    </ComponentItem>
                ))}
            </ComponentList>
        </Sidebar>
    );
};

export default ComponentListSidebar;