import React, { useState } from 'react';
import styled from '@emotion/styled';
import IconPickerModal from './IconPickerModal';

const TodoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TodoHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;
`;

const TodoIcon = styled.span`
  color: ${props => props.color || '#666'};
  cursor: ${props => props.isEditing ? 'pointer' : 'default'};
`;

const TodoTitle = styled.h3`
  margin: 0;
  color: #333;
`;

const EditableInput = styled.input`
  background: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  width: 100%;
  padding: 0;
  margin: 0;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  &:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
  }
`;

const EditableHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const TodoInput = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 1px solid #eee;

  input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    
    &:focus {
      outline: none;
      border-color: ${props => props.color};
    }
  }

  button {
    padding: 0.5rem 1rem;
    background: ${props => props.color};
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
    &:hover {
      opacity: 0.9;
    }
  }
`;

const TodoList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const TodoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  
  &:hover {
    background: #f8f9fa;
  }

  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  span {
    flex: 1;
    text-decoration: ${props => props.completed ? 'line-through' : 'none'};
    color: ${props => props.completed ? '#999' : '#333'};
  }

  button {
    padding: 0.25rem;
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    opacity: 0;
    
    &:hover {
      opacity: 1;
    }
  }

  &:hover button {
    opacity: 0.5;
  }
`;

const TodoListWidget = ({ config = { todos: [], color: '#666', icon: 'checklist', title: 'Todo List' }, isEditing, onUpdate }) => {
  const [newTodo, setNewTodo] = useState('');
  const [todos, setTodos] = useState(config?.todos || []);
  const [showIconPicker, setShowIconPicker] = useState(false);
  const iconRef = React.useRef(null);

  const handleUpdate = (updates) => {
    if (onUpdate) {
      onUpdate({
        ...config,
        ...updates,
        todos // Include current todos in the update
      });
    }
  };

  const handleAddTodo = (e) => {
    e.preventDefault();
    if (!newTodo.trim()) return;

    const newTodoItem = {
      id: Date.now(),
      text: newTodo.trim(),
      completed: false
    };

    const updatedTodos = [...todos, newTodoItem];
    setTodos(updatedTodos);
    setNewTodo('');
    
    // Update parent component if in editing mode
    if (isEditing && onUpdate) {
      handleUpdate({ todos: updatedTodos });
    }
  };

  const handleToggleTodo = (id) => {
    const updatedTodos = todos.map(todo =>
      todo.id === id ? { ...todo, completed: !todo.completed } : todo
    );
    setTodos(updatedTodos);
    
    // Update parent component if in editing mode
    if (isEditing && onUpdate) {
      handleUpdate({ todos: updatedTodos });
    }
  };

  const handleDeleteTodo = (id) => {
    const updatedTodos = todos.filter(todo => todo.id !== id);
    setTodos(updatedTodos);
    
    // Update parent component if in editing mode
    if (isEditing && onUpdate) {
      handleUpdate({ todos: updatedTodos });
    }
  };

  return (
    <TodoContainer>
      <TodoHeader>
        <EditableHeader>
          <TodoIcon 
            ref={iconRef}
            className="material-icons" 
            color={config.color}
            isEditing={isEditing}
            onClick={() => isEditing && setShowIconPicker(true)}
          >
            {config.icon}
          </TodoIcon>
          
          {isEditing ? (
            <EditableInput
              value={config.title}
              onChange={(e) => handleUpdate({ title: e.target.value })}
              placeholder="Enter todo list title"
            />
          ) : (
            <TodoTitle>{config.title}</TodoTitle>
          )}
        </EditableHeader>
      </TodoHeader>

      {showIconPicker && isEditing && (
        <IconPickerModal
          onClose={() => setShowIconPicker(false)}
          onSelect={(newIcon) => {
            handleUpdate({ icon: newIcon });
            setShowIconPicker(false);
          }}
          icons={[
            'checklist', 'list', 'task', 'assignment',
            'check_circle', 'done_all', 'pending_actions', 'today'
          ]}
        />
      )}
      
      <TodoInput color={config.color}>
        <input
          type="text"
          placeholder="Add new todo..."
          value={newTodo}
          onChange={(e) => setNewTodo(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleAddTodo(e)}
        />
        <button onClick={handleAddTodo} style={{background: '#017BFE'}}>Add</button>
      </TodoInput>
      
      <TodoList>
        {todos.map(todo => (
          <TodoItem key={todo.id} completed={todo.completed}>
            <input
              type="checkbox"
              checked={todo.completed}
              onChange={() => handleToggleTodo(todo.id)}
            />
            <span>{todo.text}</span>
            <button onClick={() => handleDeleteTodo(todo.id)}>
              <span className="material-icons">delete</span>
            </button>
          </TodoItem>
        ))}
      </TodoList>
    </TodoContainer>
  );
};

export default TodoListWidget;
