import React, { useState } from 'react';
import styled from '@emotion/styled';
import LogoDisplay from './LogoDisplay';
import LogoEditor from './LogoEditor';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadButton = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;
  color: ${props => props.textColor || '#fff'};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  .material-icons {
    font-size: 1.5rem;
  }
`;

const LogoManager = ({
    logo,
    settings,
    isEditing,
    textColor,
    onUpload,
    onSettingsChange,
    onRemove
}) => {
    const [isEditingLogo, setIsEditingLogo] = useState(false);

    const handleUploadClick = () => {
        document.getElementById('logo-upload').click();
    };

    const handleSaveSettings = (newSettings) => {
        onSettingsChange(newSettings);
        setIsEditingLogo(false);
    };

    if (!logo && !isEditing) return null;

    if (isEditingLogo) {
        return (
            <Container>
                <LogoEditor
                    logo={logo}
                    initialSettings={settings}
                    onSave={handleSaveSettings}
                    onCancel={() => setIsEditingLogo(false)}
                />
            </Container>
        );
    }

    return (
        <Container>
            {logo ? (
                <LogoDisplay
                    logo={logo}
                    settings={settings}
                    isEditing={isEditing}
                    onEdit={() => setIsEditingLogo(true)}
                    onReplace={handleUploadClick}
                    onRemove={onRemove}
                />
            ) : isEditing ? (
                <UploadButton
                    onClick={handleUploadClick}
                    textColor={textColor}
                >
                    <span className="material-icons">upload</span>
                    Upload Logo
                </UploadButton>
            ) : null}

            <input
                id="logo-upload"
                type="file"
                accept="image/*"
                onChange={onUpload}
                style={{ display: 'none' }}
            />
        </Container>
    );
};

export default LogoManager;