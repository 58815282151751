import React from 'react';
import styled from '@emotion/styled';
import StatisticsWidget from './StatisticsWidget';
import TableWidget from './TableWidget';
import FormWidget from './FormWidget';
import TodoListWidget from './TodoListWidget';
import EmptySpaceWidget from './EmptySpaceWidget';
import SummaryWidget from './SummaryWidget';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  padding: 2rem 0.5rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: ${props => props.className?.includes('with-top-bar') ? '2rem' : '0'};
`;

const GridItem = styled.div`
  padding: ${props => props.type === 'text' || props.type === 'emptyspace' ? '0' : props.settings?.components?.card?.padding || '1rem'};
  border-radius: ${props => props.type === 'text' || props.type === 'emptyspace' ? '0' : props.settings?.components?.card?.borderRadius || '8px'};
  background: ${props => props.type === 'text' || props.type === 'emptyspace' ? 'transparent' : 'white'};
  grid-column: span ${props => props.width || 3};
  grid-row: span ${props => props.height || 1};
  box-shadow: ${props => {
        if (props.type === 'text' || props.type === 'emptyspace') return 'none';
        const intensity = props.settings?.components?.card?.shadowIntensity || 'medium';
        const shadows = {
            none: 'none',
            light: '0 1px 2px rgba(0,0,0,0.1)',
            medium: '0 2px 4px rgba(0,0,0,0.1)',
            strong: '0 4px 8px rgba(0,0,0,0.1)'
        };
        return shadows[intensity];
    }};
`;

const TextRenderer = ({ config }) => {
    const { textType, content, color, alignment } = config;

    const style = {
        color,
        textAlign: alignment,
        margin: 0
    };

    switch (textType) {
        case 'heading1':
            return <h1 style={style}>{content || 'No content'}</h1>;
        case 'heading2':
            return <h2 style={style}>{content || 'No content'}</h2>;
        case 'heading3':
            return <h3 style={style}>{content || 'No content'}</h3>;
        default:
            return <p style={style}>{content || 'No content'}</p>;
    }
};

const DashboardPreviewMode = ({ components, settings }) => {
    const renderWidget = (widget) => {
        switch (widget.type) {
            case 'Statistics':
                return <StatisticsWidget config={widget.config} />;
            case 'Table':
                return <TableWidget config={widget.config} />;
            case 'Form':
                return <FormWidget config={widget.config} />;
            case 'Text':
                return <TextRenderer config={widget.config} />;
            case 'TodoList':
                return <TodoListWidget config={widget.config} />;
            case 'EmptySpace':
                return <EmptySpaceWidget config={widget.config} />;
            case 'Summary':
                return <SummaryWidget config={widget.config} />;
            default:
                return (
                    <>
                        <span className="material-icons">{widget.icon}</span>
                        <span>{widget.label}</span>
                    </>
                );
        }
    };

    return (
        <GridLayout className={settings?.showTopBar ? 'with-top-bar' : ''}>
            {components.map((widget) => (
                <GridItem
                    key={widget.id}
                    width={widget.width}
                    height={widget.height}
                    type={widget.type.toLowerCase()}
                    settings={settings}
                >
                    {renderWidget(widget)}
                </GridItem>
            ))}
        </GridLayout>
    );
};

export default DashboardPreviewMode;