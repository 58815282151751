import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import Sidebar from './Sidebar';
import AdminToolbar from './AdminToolbar';
import SettingsModal from './SettingsModal';
import { useDashboard } from '../hooks/useQueries';
import { supabase } from '../supabase';
import { useQueryClient } from '@tanstack/react-query';
import html2canvas from 'html2canvas';

const getGridBackground = (isEditing) => ({
    backgroundSize: isEditing ? 'calc(100% / 12) 100px' : 'initial',
    backgroundColor: isEditing ? '#e6f3ff' : '#f5f5f5',
    border: isEditing ? '2px solid #007bff' : 'none'
});

const DashboardEditor = ({
    isEditing,
    setIsEditing,
    availableComponents
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [showSettings, setShowSettings] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [localSettings, setLocalSettings] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [localTitle, setLocalTitle] = useState('');

    // Edit history management
    const [editHistory, setEditHistory] = useState([]);
    const [currentEditIndex, setCurrentEditIndex] = useState(-1);

    // Use the dashboard query hook
    const { data: dashboard, isLoading, error } = useDashboard(id);

    // Add new state for local components
    const [localComponents, setLocalComponents] = useState([]);

    // Add state for local menu items
    const [localMenuItems, setLocalMenuItems] = useState([]);

    // Add effect to sync title with selected menu item
    useEffect(() => {
        if (selectedMenuItem?.label) {
            setLocalTitle(selectedMenuItem.label);
        }
    }, [selectedMenuItem]);

    // Sync localSettings when dashboard data changes
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const { data, error } = await supabase
                    .from('dashboards')
                    .select('settings')
                    .eq('id', id)
                    .single();

                if (error) throw error;
                
                setLocalSettings(data?.settings || {});
            } catch (err) {
                console.error('Error fetching dashboard settings:', err);
            }
        };

        fetchSettings();
    }, [id]);

    const captureAndUploadPreview = async () => {
        try {
            // Wait for fonts and content to load
            await document.fonts.ready;

            // Additional delay to ensure all rendering is complete
            await new Promise(resolve => setTimeout(resolve, 1000));

            const editorLayout = document.querySelector('.editor-layout');
            if (!editorLayout) {
                console.error('Editor layout not found');
                return null;
            }

            const canvas = await html2canvas(editorLayout, {
                backgroundColor: null,
                scale: 2,
                logging: false,
                useCORS: true,
                allowTaint: true,
                foreignObjectRendering: true,
                ignoreElements: (element) => {
                    return element.classList.contains('admin-toolbar') ||
                        element.classList.contains('settings-modal');
                }
            });

            // Convert to blob and upload
            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', 0.8));
            const fileName = `previews/${id}/dashboard-preview.jpg`;

            const { error: uploadError } = await supabase.storage
                .from('dashboard-assets')
                .upload(fileName, blob, {
                    upsert: true,
                    contentType: 'image/jpeg'
                });

            if (uploadError) throw uploadError;

            // Get a private URL that requires authentication
            const { data } = supabase.storage
                .from('dashboard-assets')
                .getPublicUrl(fileName);

            const privateUrl = data?.publicUrl;

            const { error: updateError } = await supabase
                .from('dashboards')
                .update({
                    preview_image: privateUrl,
                    last_edited: new Date().toISOString()
                })
                .eq('id', id);

            if (updateError) throw updateError;

            return privateUrl;
        } catch (err) {
            console.error('Error capturing preview:', err);
            return null;
        }
    };

    // Track edits
    const addEdit = (edit) => {
        // Remove any future edits if we're not at the latest edit
        const newHistory = editHistory.slice(0, currentEditIndex + 1);
        setEditHistory([...newHistory, edit]);
        setCurrentEditIndex(newHistory.length);
        setUnsavedChanges(true);
    };

    const handleSave = async () => {
        try {
            setIsEditing(false);
            
            // Wait for the UI to update
            await new Promise(resolve => setTimeout(resolve, 500));

            // Save dashboard settings including sidebar color
            const { error: settingsError } = await supabase
                .from('dashboards')
                .update({
                    settings: localSettings,
                    last_edited: new Date().toISOString()
                })
                .eq('id', id);

            if (settingsError) {
                console.error('Error saving settings:', settingsError);
                throw settingsError;
            }

            // Save menu items (sections) with only the necessary fields
            const menuUpdates = localMenuItems.map((item, index) => ({
                id: item.id,
                label: item.label,
                icon: item.icon,
                order: index,
                dashboard_id: id
            }));

            const { error: menuError } = await supabase
                .from('dashboard_sections')
                .upsert(menuUpdates);

            if (menuError) throw menuError;

            // First handle any deletions
            const deletedComponents = editHistory
                .filter(edit => edit.type === 'COMPONENT_DELETE')
                .map(edit => edit.component.id);

            if (deletedComponents.length > 0) {
                const { error: deleteError } = await supabase
                    .from('dashboard_components')
                    .delete()
                    .in('id', deletedComponents);

                if (deleteError) throw deleteError;
            }

            // Then handle updates and additions with proper ordering
            const componentsToUpsert = localComponents.map((comp, index) => ({
                id: comp.id,
                type: comp.type,
                width: comp.width || 1,
                height: comp.height || 1,
                order: index,
                config: comp.config || {},
                dashboard_id: id,
                section_id: selectedMenuItem.id
            }));

            if (componentsToUpsert.length > 0) {
                const { error: upsertError } = await supabase
                    .from('dashboard_components')
                    .upsert(componentsToUpsert, {
                        onConflict: 'id',
                        ignoreDuplicates: false
                    });

                if (upsertError) throw upsertError;
            }

            // Update parent settings
            setLocalSettings(localSettings);
            setUnsavedChanges(false);

            // Capture and upload preview
            await captureAndUploadPreview();

            // Clear edit history and reset states
            setEditHistory([]);
            setCurrentEditIndex(-1);

            // Invalidate queries
            await queryClient.invalidateQueries(['dashboard', id]);
            await queryClient.invalidateQueries(['dashboards']);

            // Wait for the query invalidation to complete
            await new Promise(resolve => setTimeout(resolve, 500));

        } catch (err) {
            console.error('Error saving changes:', err);
            throw err;
        }
    };

    const handleSettingsChange = (newSettings) => {
        console.log('Settings changed:', newSettings); // Debug log
        setLocalSettings(newSettings);
        setUnsavedChanges(true);
    };

    // Get current section's components
    const currentSection = dashboard?.dashboard_sections?.find(
        section => section.id === selectedMenuItem?.id
    );

    const components = currentSection?.dashboard_components || [];

    useEffect(() => {
        if (dashboard?.dashboard_sections?.length > 0 && !selectedMenuItem) {
            setSelectedMenuItem({
                id: dashboard.dashboard_sections[0].id,
                label: dashboard.dashboard_sections[0].label
            });
        }
    }, [dashboard]);

    useEffect(() => {
        if (currentSection?.dashboard_components) {
            setLocalComponents(currentSection.dashboard_components);
            setEditHistory([]);
            setCurrentEditIndex(-1);
        }
    }, [currentSection, selectedMenuItem]);

    useEffect(() => {
        if (dashboard?.dashboard_sections) {
            setLocalMenuItems(dashboard.dashboard_sections);
        }
    }, [dashboard]);

    const handleUpdateDashboard = (updatedComponents) => {
        if (!selectedMenuItem) return;
        setLocalComponents(updatedComponents);
        setUnsavedChanges(true);
    };

    const handleUpdateMenuItem = (itemId, updatedItem) => {
        if (Array.isArray(updatedItem)) {
            // Handle reordering
            const updatedItems = updatedItem.map((item, index) => ({
                ...item,
                order: index,
                dashboard_id: id,
                last_edited: new Date().toISOString()
            }));
            setLocalMenuItems(updatedItems);
        } else if (updatedItem === null) {
            // Handle deletion
            setLocalMenuItems(prev => prev.filter(item => item.id !== itemId));
        } else {
            // Handle update/add with title sync
            setLocalMenuItems(prev => {
                const existing = prev.find(item => item.id === itemId);
                const updatedItems = existing
                    ? prev.map(item =>
                        item.id === itemId
                            ? {
                                ...item,
                                ...updatedItem,
                                dashboard_id: id,
                                last_edited: new Date().toISOString()
                            }
                            : item
                    )
                    : [...prev, {
                        ...updatedItem,
                        dashboard_id: id,
                        created_at: new Date().toISOString(),
                        last_edited: new Date().toISOString()
                    }];

                // Update local title if editing current section
                if (itemId === selectedMenuItem?.id) {
                    setLocalTitle(updatedItem.label);
                }

                return updatedItems;
            });
        }
        setUnsavedChanges(true);
    };

    const applyEditToSupabase = async (edit) => {
        switch (edit.type) {
            case 'COMPONENT_UPDATE':
                return await supabase
                    .from('dashboard_components')
                    .update({
                        width: edit.changes.width?.to,
                        height: edit.changes.height?.to,
                        order: edit.changes.order?.to,
                        section_id: selectedMenuItem?.id
                    })
                    .eq('id', edit.componentId);

            case 'COMPONENT_ADD':
                return await supabase
                    .from('dashboard_components')
                    .insert([{
                        ...edit.component,
                        dashboard_id: id
                    }]);

            case 'COMPONENT_DELETE':
                return await supabase
                    .from('dashboard_components')
                    .delete()
                    .eq('id', edit.component.id);

            case 'CONFIG_UPDATE':
                const { error } = await supabase
                    .from('dashboard_components')
                    .update({
                        config: edit.changes.config.to,
                        section_id: edit.sectionId
                    })
                    .eq('id', edit.componentId);

                if (error) throw error;
                break;

            case 'SECTION_UPDATE':
                return await supabase
                    .from('dashboard_sections')
                    .update({
                        label: edit.changes.label?.to,
                        icon: edit.changes.icon?.to,
                        order: edit.changes.order?.to
                    })
                    .eq('id', edit.sectionId);

            default:
                console.warn('Unknown edit type:', edit.type);
                return null;
        }
    };

    const handleUndo = async () => {
        const edit = editHistory[currentEditIndex];
        if (!edit) return;

        try {
            switch (edit.type) {
                case 'COMPONENT_UPDATE':
                    const updatedComponents = components.map(comp =>
                        comp.id === edit.componentId
                            ? {
                                ...comp,
                                width: edit.changes.width?.from || comp.width,
                                height: edit.changes.height?.from || comp.height,
                                order: edit.changes.order?.from || comp.order
                            }
                            : comp
                    );
                    handleUpdateDashboard(updatedComponents);
                    break;

                case 'COMPONENT_ADD':
                    handleUpdateDashboard(components.filter(c => c.id !== edit.component.id));
                    break;

                case 'COMPONENT_DELETE':
                    handleUpdateDashboard([...components, edit.component]);
                    break;

                case 'CONFIG_UPDATE':
                    handleUpdateDashboard(components.map(comp =>
                        comp.id === edit.componentId
                            ? { ...comp, config: edit.changes.config.from }
                            : comp
                    ));
                    break;
            }
            setCurrentEditIndex(prev => prev - 1);
        } catch (err) {
            console.error('Error undoing change:', err);
        }
    };

    const handleRedo = async () => {
        const nextEdit = editHistory[currentEditIndex + 1];
        if (!nextEdit) return;

        try {
            switch (nextEdit.type) {
                case 'COMPONENT_UPDATE':
                    const updatedComponents = components.map(comp =>
                        comp.id === nextEdit.componentId
                            ? {
                                ...comp,
                                width: nextEdit.changes.width?.to || comp.width,
                                height: nextEdit.changes.height?.to || comp.height,
                                order: nextEdit.changes.order?.to || comp.order
                            }
                            : comp
                    );
                    handleUpdateDashboard(updatedComponents);
                    break;

                case 'COMPONENT_ADD':
                    handleUpdateDashboard([...components, nextEdit.component]);
                    break;

                case 'COMPONENT_DELETE':
                    handleUpdateDashboard(components.filter(c => c.id !== nextEdit.component.id));
                    break;

                case 'CONFIG_UPDATE':
                    handleUpdateDashboard(components.map(comp =>
                        comp.id === nextEdit.componentId
                            ? { ...comp, config: nextEdit.changes.config.to }
                            : comp
                    ));
                    break;
            }
            setCurrentEditIndex(prev => prev + 1);
        } catch (err) {
            console.error('Error redoing change:', err);
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading dashboard</div>;
    if (!dashboard) return <div>Dashboard not found</div>;

    return (
        <div className="editor-layout">
            <AdminToolbar
                isEditing={isEditing}
                toggleEditing={() => setIsEditing(!isEditing)}
                onSave={handleSave}
                onSettingsClick={() => setShowSettings(true)}
                onBack={() => navigate('/')}
                onUndo={handleUndo}
                onRedo={handleRedo}
                canUndo={currentEditIndex >= 0}
                canRedo={currentEditIndex < editHistory.length - 1}
                hasUnsavedChanges={unsavedChanges}
            />
            <Sidebar
                isEditing={isEditing}
                onMenuSelect={setSelectedMenuItem}
                menuItems={localMenuItems}
                onUpdateMenuItem={handleUpdateMenuItem}
                selectedMenuItem={selectedMenuItem}
                dashboardId={id}
                settings={localSettings}
                onSettingsChange={handleSettingsChange}
            />
            <main
                className={`main-content dashboard-content ${isEditing ? 'editing' : ''}`}
                style={{
                    ...getGridBackground(isEditing),
                    position: 'relative',
                }}
            >
                <h1 style={{ margin: '1rem 0' }}>
                    {localTitle}
                </h1>
                <Dashboard
                    components={localComponents}
                    onUpdateComponents={handleUpdateDashboard}
                    activeId={activeId}
                    setActiveId={setActiveId}
                    isEditing={isEditing}
                    settings={localSettings}
                    availableComponents={availableComponents}
                    onUndo={handleUndo}
                    onRedo={handleRedo}
                    canUndo={currentEditIndex >= 0}
                    canRedo={currentEditIndex < editHistory.length - 1}
                    hasUnsavedChanges={unsavedChanges}
                    addEdit={addEdit}
                    selectedMenuItem={selectedMenuItem}
                />
            </main>
            <SettingsModal
                isOpen={showSettings}
                onClose={() => setShowSettings(false)}
                settings={localSettings}
                onSave={setLocalSettings}
            />
        </div>
    );
};

export default DashboardEditor;