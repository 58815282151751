import React, { useState, useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';
import { ChromePicker } from 'react-color';
import { iconsList } from '../utils/iconsList';
import IconPickerModal from './IconPickerModal';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabase';
import LogoManager from './LogoManager';

const SidebarWrapper = styled.div`
  background-color: #1a1a1a;
  color: ${props => props.textColor};
  padding: 1rem;
  width: 250px;
  box-sizing: border-box;
  height: calc(100vh - 56px);
  overflow-y: auto;
  position: fixed;
  top: 56px;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

const StyledSidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  width: calc(100% - 1rem);
  box-sizing: border-box;
  overflow: hidden;
  color: ${props => props.textColor};

  &.dragging {
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .drag-handle {
    cursor: grab;
    margin-right: 0.5rem;
    opacity: 0.7;
    
    &:hover {
      opacity: 1;
    }
  }

  .icon {
    margin-right: 0.75rem;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    color: ${props => props.iconColor};
  }

  .label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    margin-right: 0.5rem;
  }

  .edit-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
      background: rgba(255, 255, 255, 0.2);
    }

    .material-icons {
      font-size: 18px;
    }
  }
`;

const EditingItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
  position: relative;
  width: calc(100% - 1rem);
  box-sizing: border-box;

  &.dragging {
    transform: scale(1.02);
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }

  .drag-handle {
    cursor: grab;
    opacity: 0.7;
    display: flex;
    align-items: center;
    
    &:hover {
      opacity: 1;
    }
  }

  .content-row {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 0.25rem;
  }

  .icon-selector {
    cursor: pointer;
    color: ${props => props.iconColor || '#fff'};
    
    &:hover {
      opacity: 0.8;
    }
  }

  .label-input {
    flex: 1;
    background: none;
    border: none;
    color: inherit;
    font-size: inherit;
    padding: 0.25rem;
    border-radius: 4px;
    
    &:hover, &:focus {
      background: rgba(255, 255, 255, 0.1);
    }
    
    &:focus {
      outline: none;
    }
  }

  .delete-button {
    background: none;
    border: none;
    color: #ff4444;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    
    &:hover {
      opacity: 1;
    }

    .material-icons {
      font-size: 20px;
    }
  }
`;

const AddItemButton = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.2s;
  width: calc(100% - 1rem);
  box-sizing: border-box;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  .material-icons {
    margin-right: 0.75rem;
    font-size: 1.2rem;
  }

  .label {
    flex: 1;
  }
`;

const ColorPickerButton = styled.button`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .material-icons {
    color: white;
    font-size: 20px;
  }
`;

const ColorPickerPopover = styled.div`
  position: fixed;
  bottom: 4rem;
  left: 1rem;
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 280px;

  .chrome-picker {
    box-shadow: none !important;
    width: 100% !important;
  }
`;

const GradientPresets = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
`;

const GradientPreset = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: ${props => props.gradient};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const ColorPickerSection = styled.div`
  margin-top: 1rem;
  border-top: 1px solid #eee;
  padding-top: 1rem;

  .section-title {
    font-size: 12px;
    color: #666;
    margin-bottom: 0.5rem;
  }

  .color-pickers {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .color-preview {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid transparent;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;

    &.active {
      border-color: #007bff;
      transform: scale(1.1);
    }

    &:hover {
      transform: scale(1.1);
    }

    .tooltip {
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
      background: rgba(0, 0, 0, 0.8);
      color: white;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 12px;
      white-space: nowrap;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover .tooltip {
      opacity: 1;
    }
  }
`;

const SidebarItem = ({ item, isEditing, onUpdate, onDelete, isSelected, onSelect, textColor, iconColor, dashboardId }) => {
  const [showIconPicker, setShowIconPicker] = useState(false);
  const [editedLabel, setEditedLabel] = useState(item.label);
  const [editedIcon, setEditedIcon] = useState(item.icon);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ 
    id: `menu-${item.id}`,
    disabled: !isEditing 
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleIconSelect = (icon) => {
    const updatedItem = {
      ...item,
      icon: icon,
      dashboard_id: dashboardId
    };
    setEditedIcon(icon);
    onUpdate(item.id, updatedItem);
    setShowIconPicker(false);
  };

  if (isEditing) {
    return (
      <EditingItemWrapper
        ref={setNodeRef}
        style={style}
        className={isDragging ? 'dragging' : ''}
        iconColor={iconColor}
      >
        <div className="drag-handle" {...attributes} {...listeners}>
          <span className="material-icons">drag_indicator</span>
        </div>
        
        <div className="content-row">
          <div 
            className="icon-selector"
            onClick={() => setShowIconPicker(true)}
          >
            <span className="material-icons">{editedIcon}</span>
          </div>
          <input
            type="text"
            value={editedLabel}
            onChange={(e) => setEditedLabel(e.target.value)}
            onBlur={() => onUpdate(item.id, { ...item, label: editedLabel, dashboard_id: dashboardId })}
            className="label-input"
            placeholder="Menu item label"
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        <button 
          className="delete-button"
          onClick={() => onDelete(item.id)}
        >
          <span className="material-icons">delete</span>
        </button>
        
        {showIconPicker && (
          <IconPickerModal
            icons={iconsList}
            onSelect={handleIconSelect}
            onClose={() => setShowIconPicker(false)}
          />
        )}
      </EditingItemWrapper>
    );
  }

  // Regular view mode
  return (
    <StyledSidebarItem 
      ref={setNodeRef}
      style={style}
      className={`${isSelected ? 'selected' : ''} ${isDragging ? 'dragging' : ''}`}
      onClick={onSelect}
      textColor={textColor}
      iconColor={iconColor}
    >
      <span className="material-icons icon">{item.icon}</span>
      <span className="label">{item.label}</span>
    </StyledSidebarItem>
  );
};

const Sidebar = ({ isEditing, onMenuSelect, menuItems, onUpdateMenuItem, selectedMenuItem, dashboardId, settings, onSettingsChange }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [sidebarColor, setSidebarColor] = useState({
    type: settings?.sidebarColor?.type || 'solid',
    color: settings?.sidebarColor?.color || '#1a1a1a',
    gradient: settings?.sidebarColor?.gradient || 'linear-gradient(45deg, #1a1a1a, #2a2a2a)',
    textColor: settings?.sidebarColor?.textColor || '#ffffff',
    iconColor: settings?.sidebarColor?.iconColor || '#ffffff'
  });
  const [activeColorPicker, setActiveColorPicker] = useState('background');
  const [sidebarLogo, setSidebarLogo] = useState(settings?.sidebarLogo || null);

  const gradientPresets = [
    'linear-gradient(45deg, #1a1a1a, #2a2a2a)',
    'linear-gradient(45deg, #2b5876, #4e4376)',
    'linear-gradient(45deg, #000428, #004e92)',
    'linear-gradient(45deg, #434343, #000000)',
    'linear-gradient(45deg, #0f2027, #203a43, #2c5364)',
    'linear-gradient(45deg, #283c86, #45a247)'
  ];

  // Sync with settings when they change
  useEffect(() => {
    if (settings?.sidebarColor) {
      setSidebarColor(settings.sidebarColor);
    }
    if (settings?.sidebarLogo !== undefined) {
      setSidebarLogo(settings.sidebarLogo);
    }
  }, [settings]);

  // Update local state and notify parent of changes
  const handleColorChange = (newColorState) => {
    setSidebarColor(newColorState);
    onSettingsChange({
      ...settings,
      sidebarColor: newColorState
    });
  };

  const handleUpdate = (id, updatedItem) => {
    onUpdateMenuItem(id, updatedItem);
  };

  const handleDelete = (id) => {
    onUpdateMenuItem(id, null);
  };

  const handleAdd = () => {
    const newId = uuidv4();
    const now = new Date().toISOString();
    const newItem = {
        id: newId,
        icon: 'folder',
        label: `New Item ${menuItems.length + 1}`,
        order: menuItems.length,
        dashboard_id: dashboardId,
        created_at: now,
        last_edited: now
    };
    
    onUpdateMenuItem(newId, newItem);
  };

  // Add logo handling
  const handleLogoUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    let localUrl;
    
    try {
      // Create a temporary local URL for immediate display
      localUrl = URL.createObjectURL(file);
      setSidebarLogo(localUrl);

      const fileName = `logos/${dashboardId}/sidebar-logo.${file.name.split('.').pop()}`;
      
      console.log('Uploading file to:', fileName);
      const { error: uploadError } = await supabase.storage
        .from('dashboard-assets')
        .upload(fileName, file, {
          upsert: true,
          contentType: file.type
        });

      if (uploadError) throw uploadError;

      // Get public URL
      const { data } = supabase.storage
        .from('dashboard-assets')
        .getPublicUrl(fileName);

      const logoUrl = data?.publicUrl;
      console.log('Received URL:', logoUrl);

      if (!logoUrl) {
        throw new Error('Failed to get public URL');
      }

      // Get a signed URL for immediate use
      const { data: signedUrlData, error: signedUrlError } = await supabase.storage
        .from('dashboard-assets')
        .createSignedUrl(fileName, 3600);

      if (signedUrlError) throw signedUrlError;

      if (signedUrlData?.signedUrl) {
        const response = await fetch(signedUrlData.signedUrl);
        if (!response.ok) throw new Error('Failed to load logo');
        
        const blob = await response.blob();
        const verifiedUrl = URL.createObjectURL(blob);
        
        // Update settings with the public URL
        const updatedSettings = {
          ...settings,
          sidebarLogo: logoUrl,
          logoSettings: settings?.logoSettings || {
            width: '100%',
            height: '40px',
            padding: '0',
            filters: {
              invert: 0,
              brightness: 100,
              contrast: 100,
              saturate: 100
            }
          }
        };

        // Update state with the verified blob URL
        setSidebarLogo(verifiedUrl);
        onSettingsChange(updatedSettings);
        console.log('Logo state updated with verified URL');
      }

      // Clean up the initial local URL
      if (localUrl) {
        URL.revokeObjectURL(localUrl);
      }

    } catch (err) {
      console.error('Error uploading logo:', err);
      if (localUrl) {
        URL.revokeObjectURL(localUrl);
      }
      setSidebarLogo(null);
    }
  };

  const handleLogoSettingsChange = (newLogoSettings) => {
    const updatedSettings = {
      ...settings,
      logoSettings: newLogoSettings
    };
    onSettingsChange(updatedSettings);
  };

  const handleGradientSelect = (gradient) => {
    setSidebarColor(prev => ({
      ...prev,
      gradient,
      type: 'gradient'
    }));
  };

  // Add sensors configuration
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 8 }
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
        const oldIndex = menuItems.findIndex(item => `menu-${item.id}` === active.id);
        const newIndex = menuItems.findIndex(item => `menu-${item.id}` === over.id);

        const newItems = [...menuItems];
        const [movedItem] = newItems.splice(oldIndex, 1);
        newItems.splice(newIndex, 0, movedItem);

        // Pass the entire reordered array to the update function
        onUpdateMenuItem(null, newItems);
    }
  };

  // Fetch existing logo on mount
  useEffect(() => {
    setSidebarLogo(settings?.sidebarLogo || null);
  }, [settings]);

  // Add handleLogoRemove function
  const handleLogoRemove = () => {
    setSidebarLogo(null);
    const updatedSettings = {
      ...settings,
      sidebarLogo: null
    };
    onSettingsChange(updatedSettings);
  };

  // Add cleanup on component unmount
  useEffect(() => {
    return () => {
      if (sidebarLogo?.startsWith('blob:')) {
        URL.revokeObjectURL(sidebarLogo);
      }
    };
  }, [sidebarLogo]);

  // Add effect to load logo from settings
  useEffect(() => {
    const loadLogo = async () => {
      if (settings?.sidebarLogo) {
        try {
          // Get a signed URL for the image
          const { data: signedUrlData, error: signedUrlError } = await supabase.storage
            .from('dashboard-assets')
            .createSignedUrl(settings.sidebarLogo.replace(/^.*dashboard-assets\//, ''), 3600); // 1 hour expiry

          if (signedUrlError) throw signedUrlError;

          if (signedUrlData?.signedUrl) {
            const response = await fetch(signedUrlData.signedUrl);
            if (!response.ok) throw new Error('Failed to load logo');
            
            const blob = await response.blob();
            const verifiedUrl = URL.createObjectURL(blob);
            setSidebarLogo(verifiedUrl);
          }
        } catch (err) {
          console.error('Error loading logo from settings:', err);
          setSidebarLogo(null);
        }
      }
    };

    loadLogo();

    // Cleanup on unmount or when settings change
    return () => {
      if (sidebarLogo?.startsWith('blob:')) {
        URL.revokeObjectURL(sidebarLogo);
      }
    };
  }, [settings?.sidebarLogo]);

  return (
    <SidebarWrapper 
      style={{
        background: sidebarColor.type === 'gradient' ? sidebarColor.gradient : sidebarColor.color,
        color: sidebarColor.textColor
      }}
    >
      <LogoManager
        logo={sidebarLogo}
        settings={settings?.logoSettings}
        isEditing={isEditing}
        textColor={settings?.sidebarColor?.textColor}
        onUpload={handleLogoUpload}
        onSettingsChange={handleLogoSettingsChange}
        onRemove={handleLogoRemove}
      />

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext 
          items={menuItems.map(item => `menu-${item.id}`)}
          strategy={verticalListSortingStrategy}
        >
          {menuItems.map((item) => (
            <SidebarItem
              key={item.id}
              item={item}
              isEditing={isEditing}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              isSelected={selectedMenuItem?.id === item.id}
              onSelect={() => onMenuSelect(item)}
              textColor={sidebarColor.textColor}
              iconColor={sidebarColor.iconColor}
              dashboardId={dashboardId}
            />
          ))}
        </SortableContext>
      </DndContext>

      {isEditing && (
        <>
          <AddItemButton onClick={handleAdd}>
            <span className="material-icons">add</span>
            <span className="label">Add Item</span>
          </AddItemButton>

          <ColorPickerButton
            onClick={() => setShowColorPicker(!showColorPicker)}
            background={sidebarColor.type === 'gradient' ? sidebarColor.gradient : sidebarColor.color}
          >
            <span className="material-icons">palette</span>
          </ColorPickerButton>

          {showColorPicker && (
            <ColorPickerPopover>
              <ColorPickerSection>
                <div className="section-title">Colors</div>
                <div className="color-pickers">
                  <div
                    className={`color-preview ${activeColorPicker === 'background' ? 'active' : ''}`}
                    style={{ background: sidebarColor.type === 'gradient' ? sidebarColor.gradient : sidebarColor.color }}
                    onClick={() => setActiveColorPicker('background')}
                  >
                    <div className="tooltip">Background Color</div>
                  </div>
                  <div
                    className={`color-preview ${activeColorPicker === 'text' ? 'active' : ''}`}
                    style={{ background: sidebarColor.textColor }}
                    onClick={() => setActiveColorPicker('text')}
                  >
                    <div className="tooltip">Text Color</div>
                  </div>
                  <div
                    className={`color-preview ${activeColorPicker === 'icon' ? 'active' : ''}`}
                    style={{ background: sidebarColor.iconColor }}
                    onClick={() => setActiveColorPicker('icon')}
                  >
                    <div className="tooltip">Icon Color</div>
                  </div>
                </div>
                
                <ChromePicker 
                  color={
                    activeColorPicker === 'background' ? sidebarColor.color :
                    activeColorPicker === 'text' ? sidebarColor.textColor :
                    sidebarColor.iconColor
                  }
                  onChange={(color) => {
                    const newColorState = {
                      ...sidebarColor,
                      ...(activeColorPicker === 'background' ? { color: color.hex, type: 'solid' } :
                          activeColorPicker === 'text' ? { textColor: color.hex } :
                          { iconColor: color.hex })
                    };
                    handleColorChange(newColorState);
                  }}
                />
              </ColorPickerSection>

              <GradientPresets>
                {gradientPresets.map((gradient, index) => (
                  <GradientPreset
                    key={index}
                    gradient={gradient}
                    onClick={() => {
                      const newColorState = {
                        ...sidebarColor,
                        gradient,
                        type: 'gradient'
                      };
                      handleColorChange(newColorState);
                    }}
                  />
                ))}
              </GradientPresets>
            </ColorPickerPopover>
          )}
        </>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
