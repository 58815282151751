import React, { useState, useEffect } from 'react';
import AccountDrawer from './AccountDrawer';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabase';

const PageWrapper = styled.div`
  min-height: 100vh;
  padding-top: 4rem;
`;

const GalleryContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 4rem);
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const DashboardCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  cursor: pointer;
  overflow: hidden;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const CardPreview = styled.div`
  background: ${props => props.background || '#f0f0f0'};
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .material-icons {
    font-size: 3rem;
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
  }
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const CardTitle = styled.div`
  margin: 0;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;

  input {
    font-weight: 600;
    border: none;
    background: transparent;
    color: #333;
    font-size: inherit;
    padding: 2px 4px;
    border-radius: 4px;
    width: 100%;
    
    &:focus {
      outline: 2px solid #007bff;
      background: white;
    }
  }

  .edit-icon {
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;

    &:hover {
      color: #007bff;
    }
  }

  &:hover .edit-icon {
    opacity: 1;
  }
`;

const CardMeta = styled.div`
  margin-top: 0.5rem;
  color: #666;
  font-size: 0.9rem;
`;

const CreateDashboardCard = styled(DashboardCard)`
  border: 2px dashed #ddd;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
  
  &:hover {
    border-color: #007bff;
    background: #f0f7ff;
    
    .material-icons {
      color: #007bff;
    }
  }
  
  .material-icons {
    font-size: 3rem;
    color: #666;
    transition: color 0.2s;
  }
  
  span {
    color: #666;
    font-size: 1.1rem;
  }
`;

const DashboardGallery = ({ dashboards, onCreateNew }) => {
  const [editingId, setEditingId] = useState(null);
  const [editedName, setEditedName] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    const loadImages = async () => {
      // Get current session
      const { data: { session } } = await supabase.auth.getSession();
      
      dashboards.forEach(async dashboard => {
        if (dashboard.preview_image) {
          try {
            // Get a signed URL for the image
            const { data, error } = await supabase.storage
              .from('dashboard-assets')
              .createSignedUrl(`previews/${dashboard.id}/dashboard-preview.jpg`, 3600); // 1 hour expiry

            if (error) throw error;

            if (data?.signedUrl) {
              const response = await fetch(data.signedUrl);
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              setLoadedImages(prev => ({
                ...prev,
                [dashboard.id]: objectUrl
              }));
            }
          } catch (error) {
            console.error('Error loading preview for dashboard:', dashboard.id, error);
          }
        }
      });
    };

    loadImages();

    // Cleanup object URLs on unmount
    return () => {
      Object.values(loadedImages).forEach(url => URL.revokeObjectURL(url));
    };
  }, [dashboards]);

  const handleDashboardSelect = (dashboard) => {
    navigate(`/dashboard/${dashboard.id}`);
  };

  const handleEditClick = (e, dashboard) => {
    e.stopPropagation(); // Prevent card click
    setEditingId(dashboard.id);
    setEditedName(dashboard.name);
  };

  const handleNameChange = async (dashboardId) => {
    try {
      const { error } = await supabase
        .from('dashboards')
        .update({ 
          name: editedName,
          last_edited: new Date().toISOString()
        })
        .eq('id', dashboardId);

      if (error) throw error;
      
      // Reset editing state
      setEditingId(null);
      
      // Invalidate and refetch the dashboards query
      queryClient.invalidateQueries(['dashboards']);
    } catch (err) {
      console.error('Error updating dashboard name:', err);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Never';
    try {
      return new Date(dateString).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      console.error('Date parsing error:', error);
      return 'Invalid date';
    }
  };

  return (
    <PageWrapper>
      <GalleryContainer>
        <DashboardGrid>
          {dashboards.map(dashboard => (
            <DashboardCard key={dashboard.id} onClick={() => handleDashboardSelect(dashboard)}>
              <CardPreview background={dashboard.theme?.background}>
                {loadedImages[dashboard.id] ? (
                  <img 
                    src={loadedImages[dashboard.id]}
                    alt={`Preview of ${dashboard.name}`}
                    style={{ 
                      width: '100%', 
                      height: '100%', 
                      objectFit: 'cover',
                      borderRadius: '8px 8px 0 0'
                    }}
                  />
                ) : (
                  <span className="material-icons">dashboard</span>
                )}
              </CardPreview>
              <CardContent>
                <CardTitle>
                  {editingId === dashboard.id ? (
                    <input
                      type="text"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      onBlur={() => handleNameChange(dashboard.id)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleNameChange(dashboard.id);
                        } else if (e.key === 'Escape') {
                          setEditingId(null);
                        }
                      }}
                      onClick={(e) => e.stopPropagation()}
                      autoFocus
                    />
                  ) : (
                    <>
                      {dashboard.name}
                      <span 
                        className="material-icons edit-icon"
                        onClick={(e) => handleEditClick(e, dashboard)}
                      >
                        edit
                      </span>
                    </>
                  )}
                </CardTitle>
                <CardMeta>
                  Last edited: {formatDate(dashboard.last_edited)}
                </CardMeta>
              </CardContent>
            </DashboardCard>
          ))}
          <CreateDashboardCard onClick={onCreateNew}>
            <span className="material-icons">add</span>
            <span>Create Dashboard</span>
          </CreateDashboardCard>
        </DashboardGrid>
      </GalleryContainer>
    </PageWrapper>
  );
};

export default DashboardGallery;
