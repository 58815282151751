import React, { useState } from 'react';
import styled from '@emotion/styled';

const TextContainer = styled.div`
  color: ${props => props.color || '#333'};
  text-align: ${props => props.alignment || 'left'};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

const TextContent = styled.div`
  width: 100%;
  color: inherit;
  text-align: inherit;
  ${props => {
    switch (props.textType) {
      case 'heading1':
        return `
          font-size: 2rem;
          font-weight: bold;
          line-height: 1.2;
          margin: 0;
          font-family: inherit;
        `;
      case 'heading2':
        return `
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.3;
          margin: 0;
          font-family: inherit;
        `;
      case 'heading3':
        return `
          font-size: 1.25rem;
          font-weight: bold;
          line-height: 1.4;
          margin: 0;
          font-family: inherit;
        `;
      default:
        return `
          font-size: 1rem;
          line-height: 1.5;
          margin: 0;
          font-family: inherit;
        `;
    }
  }}
`;

const EditableInput = styled.input`
  background: none;
  border: none;
  font-family: inherit;
  color: inherit;
  width: 100%;
  padding: 0.5rem;
  margin: 0;
  text-align: inherit;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  &:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
  }

  ${props => {
    switch (props.textType) {
      case 'heading1':
        return `
          font-size: 2rem;
          font-weight: bold;
          line-height: 1.2;
        `;
      case 'heading2':
        return `
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.3;
        `;
      case 'heading3':
        return `
          font-size: 1.25rem;
          font-weight: bold;
          line-height: 1.4;
        `;
      default:
        return `
          font-size: 1rem;
          line-height: 1.5;
        `;
    }
  }}
`;

const Controls = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Select = styled.select`
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  
  &:focus {
    outline: none;
    border-color: #2563eb;
  }
`;

const ColorInput = styled.input`
  width: 40px;
  height: 30px;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
`;

const AlignmentButton = styled.button`
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: ${props => props.isActive ? '#2563eb' : '#666'};
  
  &:hover {
    color: #2563eb;
  }

  .material-icons {
    font-size: 20px;
  }
`;

const TextWidget = ({ config, isEditing, onUpdate }) => {
  const currentConfig = {
    textType: config?.textType || 'paragraph',
    content: config?.content || '',
    color: config?.color || '#333',
    alignment: config?.alignment || 'left'
  };

  const handleUpdate = (updates) => {
    if (onUpdate) {
      const newConfig = {
        ...currentConfig,
        ...updates
      };
      console.log('TextWidget updating with:', {
        currentConfig,
        updates,
        newConfig
      });
      onUpdate(newConfig);
    }
  };

  return (
    <TextContainer color={currentConfig.color} alignment={currentConfig.alignment}>
      {isEditing ? (
        <>
          <EditableInput
            type="text"
            textType={currentConfig.textType}
            value={currentConfig.content}
            onChange={(e) => handleUpdate({ content: e.target.value })}
            placeholder="Enter your text here..."
          />
          <Controls>
            <Select
              value={currentConfig.textType}
              onChange={(e) => handleUpdate({ textType: e.target.value })}
            >
              <option value="paragraph">Paragraph</option>
              <option value="heading1">Heading 1</option>
              <option value="heading2">Heading 2</option>
              <option value="heading3">Heading 3</option>
            </Select>

            <ColorInput
              type="color"
              value={currentConfig.color}
              onChange={(e) => handleUpdate({ color: e.target.value })}
            />

            <AlignmentButton
              isActive={currentConfig.alignment === 'left'}
              onClick={() => handleUpdate({ alignment: 'left' })}
            >
              <span className="material-icons">format_align_left</span>
            </AlignmentButton>
            <AlignmentButton
              isActive={currentConfig.alignment === 'center'}
              onClick={() => handleUpdate({ alignment: 'center' })}
            >
              <span className="material-icons">format_align_center</span>
            </AlignmentButton>
            <AlignmentButton
              isActive={currentConfig.alignment === 'right'}
              onClick={() => handleUpdate({ alignment: 'right' })}
            >
              <span className="material-icons">format_align_right</span>
            </AlignmentButton>
          </Controls>
        </>
      ) : (
        <TextContent textType={currentConfig.textType}>
          {currentConfig.content || 'No content'}
        </TextContent>
      )}
    </TextContainer>
  );
};

export default TextWidget;
