import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { usageLimits } from '../config/usageLimits';
import { useUsage } from '../hooks/useQueries';

const PageWrapper = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding-top: 4rem;
`;

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f8f9fa;
  min-height: calc(100vh - 4rem);
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 1.5rem;
`;

const UsageMetric = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

const MetricLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  .material-icons {
    color: #666;
  }
`;

const ProgressBar = styled.div`
  width: 200px;
  height: 8px;
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  
  div {
    height: 100%;
    background: ${props => props.percentage > 90 ? '#dc3545' : props.percentage > 75 ? '#ffc107' : '#28a745'};
    width: ${props => props.percentage}%;
    transition: width 0.3s ease;
  }
`;

const UsageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const PlanBadge = styled.span`
  background: #e3f2fd;
  color: #1976d2;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
`;

const NotAvailableBadge = styled.span`
  background: #f8d7da;
  color: #721c24;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
`;

const Usage = () => {
  const { data: usage, isLoading, error } = useUsage();

  if (isLoading) {
    return (
      <PageWrapper>
        <PageContainer>
          <Card>
            <Title>Loading usage data...</Title>
          </Card>
        </PageContainer>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <PageContainer>
          <Card>
            <Title>Error loading usage data</Title>
          </Card>
        </PageContainer>
      </PageWrapper>
    );
  }

  const planLimits = usageLimits[usage.plan.id];
  
  const metrics = [
    {
      label: 'Dashboards',
      icon: 'dashboard',
      current: usage.dashboards,
      limit: planLimits.dashboardLimit,
      unit: '',
      description: planLimits.dashboardLimit === 999999 ? 'Unlimited' : `Up to ${planLimits.dashboardLimit}`
    },
    {
      label: 'Team Members',
      icon: 'group',
      current: usage.teamMembers || 0,
      limit: planLimits.teamMemberLimit,
      unit: '',
      description: planLimits.teamMemberLimit === 999999 ? 'Unlimited' : `Up to ${planLimits.teamMemberLimit} members`
    },
    {
      label: 'Data Sources',
      icon: 'source',
      current: usage.dataSources || 0,
      limit: planLimits.dataSourceLimit,
      unit: '',
      description: planLimits.dataSourceLimit === 999999 ? 'Unlimited' : `Up to ${planLimits.dataSourceLimit} sources`
    },
    {
      label: 'Widgets',
      icon: 'widgets',
      current: usage.widgets,
      limit: planLimits.widgetLimit,
      unit: '',
      description: planLimits.widgetLimit === 999999 ? 'Unlimited' : `Up to ${planLimits.widgetLimit}`
    },
    {
      label: 'Storage',
      icon: 'storage',
      current: usage.storage,
      limit: planLimits.storageLimit,
      unit: 'MB',
      description: planLimits.storageLimit === 999999 ? 'Unlimited' : `${planLimits.storageLimit/1000}GB`
    },
    // Features that might be "Not Available"
    {
      label: 'API Calls',
      icon: 'api',
      current: usage.apiCalls || 0,
      limit: planLimits.apiCallsLimit || 0,
      unit: '/month',
      description: planLimits.apiCallsLimit === 999999 ? 'Unlimited' : `${planLimits.apiCallsLimit.toLocaleString()} per month`
    },
    {
      label: 'Custom Themes',
      icon: 'palette',
      current: usage.customThemes || 0,
      limit: planLimits.customThemesEnabled ? 999999 : 0,
      unit: '',
      description: planLimits.customThemesEnabled ? 'Available' : 'Pro plan and above'
    },
    {
      label: 'Priority Support',
      icon: 'support_agent',
      current: usage.prioritySupport ? 1 : 0,
      limit: planLimits.prioritySupport ? 1 : 0,
      unit: '',
      description: 'Pro plan and above'
    },
    {
      label: 'White Labeling',
      icon: 'branding_watermark',
      current: usage.whiteLabelingEnabled ? 1 : 0,
      limit: planLimits.whiteLabelingEnabled ? 1 : 0,
      unit: '',
      description: 'Enterprise plan only'
    },
    {
      label: 'Custom Integrations',
      icon: 'integration_instructions',
      current: usage.customIntegrations || 0,
      limit: planLimits.customIntegrationsEnabled ? 999999 : 0,
      unit: '',
      description: 'Enterprise plan only'
    }
  ];

  return (
    <PageWrapper>
      <PageContainer>
        <Card>
          <UsageHeader>
            <Title>Usage & Limits</Title>
            <PlanBadge>{usage.plan.name} Plan</PlanBadge>
          </UsageHeader>
          {metrics.map((metric) => (
            <UsageMetric key={metric.label}>
              <MetricLabel>
                <span className="material-icons">{metric.icon}</span>
                <div>
                  {metric.label}
                  <div style={{ fontSize: '0.875rem', color: '#666', marginTop: '0.25rem' }}>
                    {metric.description}
                  </div>
                </div>
              </MetricLabel>
              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {metric.limit > 0 ? (
                  <>
                    <span>
                      {(metric.current || 0).toLocaleString()} / {metric.limit === 999999 ? '∞' : metric.limit.toLocaleString()} {metric.unit}
                    </span>
                    {metric.limit !== 999999 && (
                      <ProgressBar percentage={((metric.current || 0) / metric.limit) * 100}>
                        <div />
                      </ProgressBar>
                    )}
                  </>
                ) : (
                  <NotAvailableBadge>Not Available</NotAvailableBadge>
                )}
              </div>
            </UsageMetric>
          ))}
        </Card>
      </PageContainer>
    </PageWrapper>
  );
};

export default Usage;