import { useQuery, useMutation, useQueryClient, useQueries } from '@tanstack/react-query';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';

// Usage data query
export function useUsage() {
  const session = useSession();
  
  return useQuery({
    queryKey: ['usage'],
    queryFn: async () => {
      if (!session?.user) throw new Error('No authenticated user');
      
      // Replace with your actual API call
      return {
        storage: 45,
        apiCalls: 2300,
        users: 8,
        plan: { id: 'pro', name: 'Pro Plan' }
      };
    },
    enabled: !!session?.user,
  });
}

// Team members query
export function useTeamMembers() {
  const session = useSession();
  
  return useQuery({
    queryKey: ['teamMembers'],
    queryFn: async () => {
      if (!session?.user) throw new Error('No authenticated user');
      
      // Replace with your actual API call
      return [
        { id: 1, email: session.user.email, role: 'Admin' },
        { id: 2, email: 'sarah@example.com', role: 'Editor' },
        { id: 3, email: 'mike@example.com', role: 'Viewer' }
      ];
    },
    enabled: !!session?.user,
  });
}

// Data sources query
export function useDataSources() {
  const session = useSession();
  
  return useQuery({
    queryKey: ['dataSources'],
    queryFn: async () => {
      if (!session?.user) throw new Error('No authenticated user');
      
      // Replace with your actual API call
      return [
        { id: 1, name: 'Production Database', type: 'PostgreSQL', status: 'Connected' },
        { id: 2, name: 'Analytics DB', type: 'MongoDB', status: 'Connected' },
        { id: 3, name: 'Legacy System', type: 'MySQL', status: 'Disconnected' }
      ];
    },
    enabled: !!session?.user,
  });
}

// Mock mutations
export function useRemoveTeamMember() {
  const session = useSession();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (memberId) => {
      if (!session?.user) throw new Error('No authenticated user');
      
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 500));
      return { success: true };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teamMembers'] });
    },
  });
}

export function useRemoveDataSource() {
  const session = useSession();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (sourceId) => {
      if (!session?.user) throw new Error('No authenticated user');
      
      // Simulate API delay
      await new Promise(resolve => setTimeout(resolve, 500));
      return { success: true };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dataSources'] });
    },
  });
}

export function useDashboards() {
  const session = useSession();
  const supabase = useSupabaseClient();
  
  return useQuery({
    queryKey: ['dashboards'],
    queryFn: async () => {
      if (!session?.user) throw new Error('No authenticated user');
      
      const { data, error } = await supabase
        .from('dashboards')
        .select(`
          id,
          name,
          theme,
          settings,
          created_at,
          last_edited,
          preview_image,
          dashboard_sections (
            id,
            label,
            order,
            icon,
            dashboard_components (
              id,
              type,
              width,
              height,
              order,
              config
            )
          )
        `)
        .eq('owner_id', session.user.id)
        .order('created_at', { ascending: false });
        
      if (error) throw error;
      return data;
    },
    enabled: !!session?.user,
  });
}

export function useDashboard(dashboardId) {
  const session = useSession();
  const supabase = useSupabaseClient();
  
  return useQuery({
    queryKey: ['dashboard', dashboardId],
    queryFn: async () => {
      if (!session?.user || !dashboardId) throw new Error('No dashboard ID or user');
      
      const { data, error } = await supabase
        .from('dashboards')
        .select(`
          id,
          name,
          theme,
          settings,
          created_at,
          last_edited,
          preview_image,
          dashboard_sections (
            id,
            label,
            order,
            icon,
            dashboard_components (
              id,
              type,
              width,
              height,
              order,
              config,
              section_id
            )
          )
        `)
        .eq('id', dashboardId)
        .eq('owner_id', session.user.id)
        .order('order', { foreignTable: 'dashboard_sections.dashboard_components' })
        .single();
        
      if (error) throw error;
      return data;
    },
    enabled: !!session?.user && !!dashboardId,
    staleTime: 0,
    cacheTime: 1000 * 30, // 30 seconds
    refetchOnWindowFocus: true,
    refetchOnMount: true
  });
}

export function useUserDatabases() {
  const session = useSession();
  const supabase = useSupabaseClient();
  
  return useQuery({
    queryKey: ['userDatabases'],
    queryFn: async () => {
      if (!session?.user) throw new Error('No authenticated user');
      
      const { data, error } = await supabase
        .from('user_databases')
        .select('*')
        .order('created_at', { ascending: false });
        
      if (error) throw error;
      return data;
    },
    enabled: !!session?.user,
  });
}

export function useCreateUserDatabase() {
  const session = useSession();
  const queryClient = useQueryClient();
  const supabase = useSupabaseClient();
  
  return useMutation({
    mutationFn: async (name) => {
      if (!session?.user) throw new Error('No authenticated user');
      
      const { data, error } = await supabase
        .rpc('provision_user_database', { p_name: name });
        
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['userDatabases']);
    },
  });
}

export function useSchemaInfos(schemaNames) {
  const session = useSession();
  const supabase = useSupabaseClient();
  
  return useQueries({
    queries: (schemaNames || []).map(schemaName => ({
      queryKey: ['schemaInfo', schemaName],
      queryFn: async () => {
        if (!session?.user) throw new Error('No authenticated user');
        
        const { data, error } = await supabase
          .rpc('get_schema_info', { p_schema_name: schemaName });
          
        if (error) throw error;
        return data;
      },
      enabled: !!session?.user && !!schemaName,
    }))
  });
}