import React from 'react';
import styled from '@emotion/styled';
import {
  ConfigField,
  Label,
  Input,
  Select,
  ColorInput,
} from './shared/ConfigurationStyles';

const PreviewContainer = styled.div`
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ConfigInput = styled(Input)`
  border: none;
  background: #f8f9fa;
  transition: background 0.2s ease;
  text-align: ${props => props.alignment};
  width: calc(100% - 2rem);
  margin: 0 2rem 0 0;
  padding: 1rem;
  
  &:hover {
    background: #f1f3f5;
  }
  
  &:focus {
    background: #f1f3f5;
    box-shadow: none;
  }

  ${props => {
    switch (props.textType) {
      case 'heading1':
        return `
          font-size: 2rem;
          font-weight: bold;
        `;
      case 'heading2':
        return `
          font-size: 1.5rem;
          font-weight: bold;
        `;
      case 'heading3':
        return `
          font-size: 1.25rem;
          font-weight: bold;
        `;
      default:
        return `
          font-size: 1rem;
          line-height: 1.5;
        `;
    }
  }}
`;

const TextConfiguration = ({ tempConfig, handleChange }) => {
  return (
    <>
      <PreviewContainer>
        <ConfigInput
          as="textarea"
          value={tempConfig?.content || ''}
          onChange={(e) => handleChange('content', e.target.value)}
          placeholder="Enter your text here..."
          textType={tempConfig?.textType || 'paragraph'}
          alignment={tempConfig?.alignment || 'left'}
          style={{ 
            minHeight: '120px',
            color: tempConfig?.color || '#333',
            resize: 'vertical'
          }}
        />
      </PreviewContainer>

      <ConfigField>
        <Label>Text Type</Label>
        <Select
          value={tempConfig?.textType || 'paragraph'}
          onChange={(e) => handleChange('textType', e.target.value)}
        >
          <option value="heading1">Heading 1</option>
          <option value="heading2">Heading 2</option>
          <option value="heading3">Heading 3</option>
          <option value="paragraph">Paragraph</option>
        </Select>
      </ConfigField>

      <ConfigField>
        <Label>Text Color</Label>
        <ColorInput
          type="color"
          value={tempConfig?.color || '#333333'}
          onChange={(e) => handleChange('color', e.target.value)}
        />
      </ConfigField>

      <ConfigField>
        <Label>Text Alignment</Label>
        <Select
          value={tempConfig?.alignment || 'left'}
          onChange={(e) => handleChange('alignment', e.target.value)}
        >
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
          <option value="justify">Justify</option>
        </Select>
      </ConfigField>
    </>
  );
};

export default TextConfiguration;
