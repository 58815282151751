import React, { forwardRef } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const IconGridContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  width: 360px;
  border: 1px solid #eee;
  position: relative;
  z-index: 100000;

  .header {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #f0f0f0;
  }

  .icon-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.75rem;
  }

  .icon-option {
    aspect-ratio: 1;
    cursor: pointer;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    color: #666;
    background: #f8f8f8;
    border: 1px solid transparent;

    &:hover {
      background: white;
      border-color: #e0e0e0;
      color: #333;
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }

    .material-icons {
      font-size: 1.25rem;
    }
  }
`;

const IconPickerModal = forwardRef(function IconPickerModal({ onClose, onSelect, icons }, ref) {
  return ReactDOM.createPortal(
    <ModalOverlay onClick={onClose}>
      <IconGridContainer ref={ref} onClick={e => e.stopPropagation()}>
        <div className="header">Select an icon</div>
        <div className="icon-grid">
          {icons.map((iconName) => (
            <div
              key={iconName}
              className="icon-option"
              onClick={() => {
                onSelect(iconName);
                onClose();
              }}
            >
              <span className="material-icons">{iconName}</span>
            </div>
          ))}
        </div>
      </IconGridContainer>
    </ModalOverlay>,
    document.body
  );
});

IconPickerModal.displayName = 'IconPickerModal';

export default IconPickerModal;
