import React, { useState } from 'react';
import styled from '@emotion/styled';

const EditorContainer = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;
`;

const PreviewContainer = styled.div`
  margin-bottom: 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ControlGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.875rem;
    opacity: 0.8;
  }

  input {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 0.5rem;
    color: inherit;
    font-size: 0.875rem;

    &[type="range"] {
      width: 100%;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const Button = styled.button`
  background: ${props => props.primary ? 'rgba(255, 255, 255, 0.2)' : 'transparent'};
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  color: inherit;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const LogoEditor = ({ logo, onSave, onCancel, initialSettings = {} }) => {
    const [settings, setSettings] = useState({
        width: initialSettings.width || '100%',
        height: initialSettings.height || '40px',
        padding: initialSettings.padding || '0',
        filters: {
            invert: initialSettings.filters?.invert || 0,
            brightness: initialSettings.filters?.brightness || 100,
            contrast: initialSettings.filters?.contrast || 100,
            saturate: initialSettings.filters?.saturate || 100
        }
    });

    const handleFilterChange = (filter, value) => {
        setSettings(prev => ({
            ...prev,
            filters: {
                ...prev.filters,
                [filter]: Number(value)
            }
        }));
    };

    const getFilterString = () => {
        const { filters } = settings;
        return `
      invert(${filters.invert}%)
      brightness(${filters.brightness}%)
      contrast(${filters.contrast}%)
      saturate(${filters.saturate}%)
    `;
    };

    return (
        <EditorContainer>
            <PreviewContainer>
                <img
                    src={logo}
                    alt="Logo Preview"
                    style={{
                        width: settings.width,
                        height: settings.height,
                        padding: settings.padding,
                        filter: getFilterString(),
                        objectFit: 'contain'
                    }}
                />
            </PreviewContainer>

            <ControlsContainer>
                <ControlGroup>
                    <label>Width:</label>
                    <input
                        type="text"
                        value={settings.width}
                        onChange={(e) => setSettings(prev => ({ ...prev, width: e.target.value }))}
                    />
                </ControlGroup>

                <ControlGroup>
                    <label>Height:</label>
                    <input
                        type="text"
                        value={settings.height}
                        onChange={(e) => setSettings(prev => ({ ...prev, height: e.target.value }))}
                    />
                </ControlGroup>

                <ControlGroup>
                    <label>Padding:</label>
                    <input
                        type="text"
                        value={settings.padding}
                        onChange={(e) => setSettings(prev => ({ ...prev, padding: e.target.value }))}
                    />
                </ControlGroup>

                <ControlGroup>
                    <label>Invert: {settings.filters.invert}%</label>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={settings.filters.invert}
                        onChange={(e) => handleFilterChange('invert', e.target.value)}
                    />
                </ControlGroup>

                <ControlGroup>
                    <label>Brightness: {settings.filters.brightness}%</label>
                    <input
                        type="range"
                        min="0"
                        max="200"
                        value={settings.filters.brightness}
                        onChange={(e) => handleFilterChange('brightness', e.target.value)}
                    />
                </ControlGroup>

                <ControlGroup>
                    <label>Contrast: {settings.filters.contrast}%</label>
                    <input
                        type="range"
                        min="0"
                        max="200"
                        value={settings.filters.contrast}
                        onChange={(e) => handleFilterChange('contrast', e.target.value)}
                    />
                </ControlGroup>

                <ControlGroup>
                    <label>Saturation: {settings.filters.saturate}%</label>
                    <input
                        type="range"
                        min="0"
                        max="200"
                        value={settings.filters.saturate}
                        onChange={(e) => handleFilterChange('saturate', e.target.value)}
                    />
                </ControlGroup>

                <ButtonGroup>
                    <Button primary onClick={() => onSave(settings)}>Save Changes</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </ButtonGroup>
            </ControlsContainer>
        </EditorContainer>
    );
};

export default LogoEditor;