import React, { useState } from 'react';

const MATERIAL_ICONS = [
  // Analytics & Charts
  'dashboard', 'analytics', 'bar_chart', 'pie_chart', 'trending_up', 'trending_down',
  // Users & Profiles
  'person', 'group', 'people', 'account_circle', 'admin_panel_settings',
  // Navigation
  'home', 'menu', 'apps', 'arrow_back', 'more_vert',
  // Communication
  'mail', 'chat', 'notifications', 'phone', 'message',
  // Content
  'description', 'article', 'folder', 'cloud', 'image',
  // Actions
  'add', 'edit', 'delete', 'save', 'refresh',
  // Settings & Tools
  'settings', 'build', 'tune', 'code', 'bug_report',
  // Business & Finance
  'business', 'payment', 'account_balance', 'shopping_cart', 'receipt',
  // Time & Schedule
  'schedule', 'event', 'calendar_today', 'access_time', 'update',
  // Misc
  'favorite', 'star', 'help', 'info', 'warning'
];

const IconSelector = ({ currentIcon, onSelectIcon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredIcons = searchTerm
    ? MATERIAL_ICONS.filter(icon => icon.includes(searchTerm.toLowerCase()))
    : MATERIAL_ICONS;

  return (
    <div className="icon-selector">
      <div 
        className="icon-display" 
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="material-icons">{currentIcon}</span>
        <span className="material-icons dropdown-arrow">
          {isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
        </span>
      </div>
      
      {isOpen && (
        <div className="icon-dropdown">
          <input
            type="text"
            placeholder="Search icons..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="icon-search"
            onClick={(e) => e.stopPropagation()}
          />
          <div className="icon-grid">
            {filteredIcons.map((icon) => (
              <span
                key={icon}
                className="material-icons icon-option"
                onClick={() => {
                  onSelectIcon(icon);
                  setIsOpen(false);
                }}
                title={icon}
              >
                {icon}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IconSelector;
