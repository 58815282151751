import React from 'react';
import styled from '@emotion/styled';
import html2canvas from 'html2canvas';

const ToolbarBanner = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  grid-column: 1 / -1;
  grid-row: 1;
  background: #e0e0e0;
  padding: 0.75rem 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  z-index: 1100;

  button {
    background-color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #f8f9fa;
    }

    &:active {
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      transform: translateY(1px);
    }

    .material-icons {
      font-size: 1.2rem;
    }
  }

  .primary-button {
    background-color: #007bff;
    color: white;
    font-weight: 500;
    padding: 0.5rem 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3);
    
    &:hover {
      background-color: #0056b3;
      box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
    }

    &:active {
      background-color: #004085;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .material-icons {
      font-size: 1.2rem;
    }
  }
`;

const AdminToolbar = ({ 
    isEditing, 
    toggleEditing,
    onSave,
    onSettingsClick, 
    onBack,
    onUndo,
    onRedo,
    canUndo,
    canRedo,
    hasUnsavedChanges
}) => {
    const onSaveClicked = () => {
        toggleEditing();
        onSave();
    };

    const handleScreenshot = async () => {
        try {
            const toolbar = document.querySelector('.admin-toolbar');
            if (toolbar) toolbar.style.display = 'none';

            const editorLayout = document.querySelector('.editor-layout');
            const canvas = await html2canvas(editorLayout, {
                backgroundColor: null,
                scale: 2,
                logging: false,
                useCORS: true,
                ignoreElements: (element) => {
                    return element.classList.contains('admin-toolbar') || 
                           element.classList.contains('settings-modal');
                }
            });

            if (toolbar) toolbar.style.display = 'flex';

            const link = document.createElement('a');
            link.download = 'dashboard-screenshot.png';
            link.href = canvas.toDataURL('image/png');
            link.click();
        } catch (error) {
            console.error('Error taking screenshot:', error);
            alert('Failed to take screenshot. Please try again.');
        }
    };

    return (
        <ToolbarBanner className="admin-toolbar">
            {!isEditing && (
                <>
                    <button className="primary-button" onClick={toggleEditing}>
                        <span className="material-icons">edit</span>
                        Edit
                    </button>
                    <button onClick={handleScreenshot}>
                        <span className="material-icons">photo_camera</span>
                        Screenshot
                    </button>
                    <button onClick={onSettingsClick}>
                        <span className="material-icons">settings</span>
                        Settings
                    </button>
                    <button onClick={onBack}>
                        <span className="material-icons">logout</span>
                        Exit
                    </button>
                </>
            )}
            {isEditing && (
                <>
                    <span style={{ marginRight: '1rem', color: '#666', display: 'flex', alignItems: 'center' }}>
                        <span className="material-icons" style={{ marginRight: '0.5rem', fontSize: '1.2rem' }}>
                            edit
                        </span>
                        {hasUnsavedChanges ? 'Unsaved Changes' : 'Editing...'}
                    </span>
                    <button 
                        onClick={onUndo} 
                        disabled={!canUndo}
                        title="Undo"
                    >
                        <span className="material-icons">undo</span>
                    </button>
                    <button 
                        onClick={onRedo} 
                        disabled={!canRedo}
                        title="Redo"
                    >
                        <span className="material-icons">redo</span>
                    </button>
                    <button className="primary-button" onClick={onSaveClicked}>
                        <span className="material-icons">save</span>
                        Save
                    </button>
                </>
            )}
        </ToolbarBanner>
    );
};

export default AdminToolbar; 