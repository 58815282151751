export const plans = [
    {
        id: 'basic',
        name: 'Basic',
        price: 9.99,
        features: [
            'Up to 3 dashboards',
            'Basic widgets',
            'Email support',
            '1GB storage',
            'Up to 2 team members',
            '3 data sources'
        ]
    },
    {
        id: 'pro',
        name: 'Professional',
        price: 29.99,
        features: [
            'Unlimited dashboards',
            'Advanced widgets',
            'Priority support',
            '10GB storage',
            'Custom themes',
            'Up to 10 team members',
            '15 data sources'
        ]
    },
    {
        id: 'enterprise',
        name: 'Enterprise',
        price: 99.99,
        features: [
            'Custom solutions',
            'API access',
            'Dedicated support',
            'Unlimited storage',
            'White labeling',
            'Custom integrations',
            'Unlimited team members',
            'Unlimited data sources'
        ]
    }
];