import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import IconPickerModal from './IconPickerModal';
import { iconsList } from '../utils/iconsList';

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem;
  overflow: hidden;
`;

const StatHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  min-width: 0;
  position: relative;
`;

const StatIcon = styled.span`
  color: ${props => props.color};
  font-size: 1.25rem;
  flex-shrink: 0;
  cursor: ${props => props.isEditing ? 'pointer' : 'default'};
`;

const StatLabel = styled.div`
  color: #000;
  font-size: 1.0rem;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const EditableInput = styled.input`
  background: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  width: 100%;
  padding: 0;
  margin: 0;
  font-weight: bold;
  
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
  
  &:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
  }
`;

const StatValue = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${props => props.color || '#333'};
`;

const ValueInput = styled(EditableInput)`
  font-weight: bold;
  text-align: left;
  font-size: 2.5rem;
  width: 100%;
`;

const StatText = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #666;
  font-size: 0.875rem;
`;

const TextInput = styled(EditableInput)`
  width: 100% !important;
  text-align: left;
  font-size: 0.875rem;
  color: #666;
  font-weight: normal;
`;

const SummaryWidget = ({ config, isEditing, onUpdate }) => {
  const [showIconPicker, setShowIconPicker] = useState(false);
  const [localLabel, setLocalLabel] = useState('');
  const [localValue, setLocalValue] = useState('');
  const [localText, setLocalText] = useState('');
  const [localIcon, setLocalIcon] = useState('summarize');
  const iconRef = useRef(null);
  const modalRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showIconPicker && 
          modalRef.current && 
          !modalRef.current.contains(event.target) &&
          !iconRef.current.contains(event.target)) {
        setShowIconPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showIconPicker]);

  useEffect(() => {
    if (!config) return;
    
    setLocalLabel(config.label || 'New Summary');
    setLocalValue(config.value || '0');
    setLocalText(config.text || '');
    setLocalIcon(config.icon || 'summarize');
  }, [config]);

  const {
    prefix = '',
    suffix = '',
    color = '#181aef',
    icon = localIcon || 'summarize',
    text = ''
  } = config || {};

  const handleUpdate = (updates) => {
    if (onUpdate) {
      onUpdate({
        ...config,
        ...updates
      });
    }
  };

  const handleLabelBlur = () => {
    if (localLabel !== config.label) {
      handleUpdate({ label: localLabel });
    }
  };

  const handleValueBlur = () => {
    const fullValue = localValue;
    const prefixRegex = new RegExp(`^${prefix}`);
    const suffixRegex = new RegExp(`${suffix}$`);
    
    let newValue = fullValue;
    let newPrefix = prefix;
    let newSuffix = suffix;

    if (!prefixRegex.test(fullValue) && fullValue) {
      const match = fullValue.match(/^([^0-9]*)(.*?)([^0-9]*)$/);
      if (match) {
        newPrefix = match[1];
        newValue = match[2];
        newSuffix = match[3];
      }
    } else if (prefix) {
      newValue = fullValue.replace(prefixRegex, '');
    }

    if (suffixRegex.test(newValue)) {
      newValue = newValue.replace(suffixRegex, '');
    }

    handleUpdate({
      value: newValue,
      prefix: newPrefix,
      suffix: newSuffix
    });
  };

  const handleTextBlur = () => {
    if (localText !== config.text) {
      handleUpdate({ text: localText });
    }
  };

  return (
    <StatContainer>
      <StatHeader>
        <StatIcon 
          ref={iconRef}
          className="material-icons" 
          color={color}
          isEditing={isEditing}
          onClick={() => isEditing && setShowIconPicker(true)}
        >
          {localIcon}
        </StatIcon>
        
        {showIconPicker && isEditing && (
          <IconPickerModal
            ref={modalRef}
            onClose={() => setShowIconPicker(false)}
            onSelect={(newIcon) => {
              setLocalIcon(newIcon);
              handleUpdate({ icon: newIcon });
              setShowIconPicker(false);
            }}
            icons={iconsList}
          />
        )}

        {isEditing ? (
          <EditableInput
            type="text"
            value={localLabel}
            onChange={(e) => setLocalLabel(e.target.value)}
            onBlur={handleLabelBlur}
          />
        ) : (
          <StatLabel>{config.label}</StatLabel>
        )}
      </StatHeader>
      
      <StatValue color={color}>
        {isEditing ? (
          <ValueInput
            type="text"
            value={localValue}
            onChange={(e) => setLocalValue(e.target.value)}
            onBlur={handleValueBlur}
          />
        ) : (
          <>{prefix}{config.value}{suffix}</>
        )}
      </StatValue>
      
      <StatText>
        {isEditing ? (
          <TextInput
            type="text"
            value={localText}
            onChange={(e) => setLocalText(e.target.value)}
            onBlur={handleTextBlur}
            placeholder="Add description text"
          />
        ) : (
          config.text && <>{config.text}</>
        )}
      </StatText>
    </StatContainer>
  );
};

export default SummaryWidget;