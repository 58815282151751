// Common Material Icons grouped by category
export const iconsList = [
    // Essential
    'home', 'settings', 'dashboard', 'person', 'notifications', 'search', 'menu',
    'close', 'add', 'edit', 'delete', 'check', 'more_vert', 'refresh',

    // Navigation
    'arrow_back', 'arrow_forward', 'expand_more', 'expand_less', 'chevron_right',
    'chevron_left', 'first_page', 'last_page', 'menu_open', 'logout',

    // Content
    'folder', 'description', 'article', 'image', 'attach_file', 'link',
    'cloud_upload', 'cloud_download', 'save', 'print', 'share',

    // Communication
    'mail', 'message', 'chat', 'phone', 'video_call', 'forum', 'feedback',

    // Data & Analytics
    'analytics', 'trending_up', 'trending_down', 'pie_chart', 'bar_chart',
    'timeline', 'data_usage', 'assessment', 'show_chart', 'table_chart',

    // Status & Info
    'info', 'warning', 'error', 'help', 'update', 'sync', 'check_circle',
    'error_outline', 'star', 'favorite',

    // Social
    'group', 'public', 'share', 'thumb_up', 'thumb_down', 'comment',

    // Device & Hardware
    'computer', 'smartphone', 'tablet', 'print', 'wifi', 'bluetooth',

    // Others
    'calendar_today', 'event', 'location_on', 'room', 'shopping_cart',
    'visibility', 'lock', 'security', 'build', 'code'
];