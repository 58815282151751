import React from 'react';
import {
  ConfigField,
  Label,
  Input,
  ColorInput,
  IconSelectorWrapper
} from './shared/ConfigurationStyles';
import IconSelector from './IconSelector';

const TodoListConfiguration = ({ tempConfig = { title: '', icon: 'checklist', color: '#8b5cf6' }, handleChange }) => {
  return (
    <>
      <ConfigField>
        <Label>Title</Label>
        <Input
          type="text"
          value={tempConfig?.title || ''}
          onChange={(e) => handleChange('title', e.target.value)}
        />
      </ConfigField>

      <ConfigField>
        <Label>Icon</Label>
        <IconSelectorWrapper>
          <IconSelector
            currentIcon={tempConfig?.icon || 'checklist'}
            onSelectIcon={(icon) => handleChange('icon', icon)}
          />
        </IconSelectorWrapper>
      </ConfigField>

      <ConfigField>
        <Label>Color</Label>
        <ColorInput
          type="color"
          value={tempConfig?.color || '#8b5cf6'}
          onChange={(e) => handleChange('color', e.target.value)}
        />
      </ConfigField>
    </>
  );
};

export default TodoListConfiguration;
