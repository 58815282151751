import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import DraggableWidget from './DraggableWidget';
import ConfigurationSidebar from './ConfigurationSidebar';
import AddWidgetButton from './AddWidgetButton';
import ComponentListSidebar from './ComponentListSidebar';
import AdminToolbar from './AdminToolbar';
import { v4 as uuidv4 } from 'uuid';

const GRID_SIZE = 100;

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  padding: 2rem 0.5rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: ${props => props.className?.includes('with-top-bar') ? '2rem' : '0'};
`;

const DashboardEditMode = ({
    components,
    onUpdateComponents,
    settings,
    availableComponents,
    onUndo,
    onRedo,
    canUndo,
    canRedo,
    hasUnsavedChanges,
    addEdit,
    selectedMenuItem
}) => {
    const [showComponentList, setShowComponentList] = useState(false);
    const [selectedWidget, setSelectedWidget] = useState(null);
    const [showConfig, setShowConfig] = useState(false);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 8 }
        })
    );

    const handleShowConfig = (widget) => {
        setSelectedWidget(widget);
        setShowConfig(true);
    };

    const handleCloseConfig = () => {
        setShowConfig(false);
        setTimeout(() => {
            setSelectedWidget(null);
        }, 100);
    };

    const handleDrag = (event) => {
        const { active, delta } = event;

        if (active?.data?.current?.type === 'resize') {
            const widgetId = active.data.current.widgetId;
            const widget = components.find(w => w.id === widgetId);
            if (!widget) return;

            const columnWidth = window.innerWidth / 12;
            const deltaWidth = Math.round(delta.x / columnWidth);
            const deltaHeight = Math.round(delta.y / GRID_SIZE);

            const newWidth = Math.max(1, Math.min(12, widget.width + deltaWidth));
            const newHeight = Math.max(1, widget.height + deltaHeight);

            if (newWidth !== widget.width || newHeight !== widget.height) {
                handleResize(widgetId, newWidth, newHeight);
            }
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!active || !over) return;

        if (active.data.current?.type === 'resize') {
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = components.findIndex(item => item.id === active.id);
            const newIndex = components.findIndex(item => item.id === over.id);

            addEdit({
                type: 'COMPONENT_UPDATE',
                componentId: active.id,
                sectionId: selectedMenuItem?.id,
                changes: {
                    order: {
                        from: oldIndex,
                        to: newIndex
                    }
                }
            });

            const newItems = [...components];
            const [movedItem] = newItems.splice(oldIndex, 1);
            newItems.splice(newIndex, 0, movedItem);

            onUpdateComponents(newItems);
        }
    };

    const handleAddComponent = (template) => {
        const newId = uuidv4();
        const newComponent = {
            id: newId,
            type: template.type,
            width: template.type === 'Table' ? 12 : template.width,
            height: template.height || 1,
            config: getDefaultConfig(template),
            order: components.length
        };

        addEdit({
            type: 'COMPONENT_ADD',
            component: {
                ...newComponent,
                section_id: selectedMenuItem?.id
            }
        });

        onUpdateComponents([...components, newComponent]);
        setShowComponentList(false);
        setSelectedWidget(newComponent);
    };

    const getDefaultConfig = (template) => {
        switch (template.type) {
            case 'Statistics':
                return {
                    label: `New ${template.label}`,
                    value: '0',
                    prefix: '',
                    suffix: '',
                    change: 0,
                    color: '#181aef',
                    icon: 'trending_up'
                };
            case 'Table':
                return {
                    title: 'New Table',
                    icon: 'table_chart',
                    color: '#181aef',
                    columns: [
                        { key: 'id', label: 'ID', type: 'text' },
                        { key: 'name', label: 'Name', type: 'text' }
                    ],
                    data: []
                };
            case 'Form':
                return {
                    title: 'New Form',
                    icon: 'dynamic_form',
                    color: '#181aef',
                    steps: [
                        {
                            title: 'Step 1',
                            fields: [
                                {
                                    id: 'field1',
                                    type: 'text',
                                    label: 'Name',
                                    placeholder: 'Enter your name',
                                    required: true
                                },
                                {
                                    id: 'field2',
                                    type: 'email',
                                    label: 'Email',
                                    placeholder: 'Enter your email',
                                    required: true
                                }
                            ]
                        }
                    ],
                    submitButton: {
                        label: 'Submit',
                        color: '#181aef'
                    }
                };
            case 'Text':
                return {
                    textType: 'paragraph',
                    content: 'New text content',
                    color: '#333333',
                    alignment: 'left'
                };
            case 'Summary':
                return {
                    label: template.label || 'New Summary',
                    value: '0',
                    prefix: '',
                    suffix: '',
                    color: '#181aef',
                    icon: 'summarize',
                    dataSource: null
                };
            default:
                return template.config;
        }
    };

    const handleResize = (id, newWidth, newHeight) => {
        const component = components.find(c => c.id === id);
        if (!component) return;

        addEdit({
            type: 'COMPONENT_UPDATE',
            componentId: id,
            sectionId: component.section_id,
            changes: {
                width: { from: component.width, to: newWidth },
                height: { from: component.height, to: newHeight }
            }
        });

        const newComponents = components.map(component =>
            component.id === id
                ? { ...component, width: newWidth, height: newHeight }
                : component
        );
        onUpdateComponents(newComponents);
    };

    const handleWidgetUpdate = (id, changes) => {
        const component = components.find(c => c.id === id);
        if (!component) return;

        addEdit({
            type: 'CONFIG_UPDATE',
            componentId: id,
            sectionId: selectedMenuItem?.id,
            changes: {
                config: {
                    from: component.config,
                    to: {
                        ...component.config,
                        ...changes
                    }
                }
            }
        });

        const newComponents = components.map(comp => {
            if (comp.id === id) {
                return {
                    ...comp,
                    config: {
                        ...comp.config,
                        ...changes
                    }
                };
            }
            return comp;
        });

        onUpdateComponents(newComponents);
    };

    const handleDeleteWidget = (id) => {
        const component = components.find(c => c.id === id);
        if (!component) return;

        addEdit({
            type: 'COMPONENT_DELETE',
            component: { ...component }
        });

        const newComponents = components.filter(comp => comp.id !== id);
        onUpdateComponents(newComponents);
        setSelectedWidget(null);
    };

    const handleWidgetSelect = (widget) => {
        setSelectedWidget(widget);
    };

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDrag={handleDrag}
            modifiers={[restrictToWindowEdges]}
        >
            <SortableContext
                items={components.map(c => c.id)}
                strategy={rectSortingStrategy}
            >
                <GridLayout className={settings?.showTopBar ? 'with-top-bar' : ''}>
                    {components.map((widget) => (
                        <DraggableWidget
                            key={widget.id}
                            widget={widget}
                            isEditing={true}
                            onResize={handleResize}
                            isSelected={selectedWidget?.id === widget.id}
                            onSelect={() => handleWidgetSelect(widget)}
                            onDelete={handleDeleteWidget}
                            onUpdate={handleWidgetUpdate}
                            onShowConfig={() => handleShowConfig(widget)}
                            settings={settings}
                        />
                    ))}
                </GridLayout>
            </SortableContext>

            <AddWidgetButton onClick={() => setShowComponentList(true)} />

            {showComponentList && (
                <ComponentListSidebar
                    availableComponents={availableComponents}
                    onAddComponent={handleAddComponent}
                    onClose={() => setShowComponentList(false)}
                />
            )}

            {showConfig && selectedWidget && (
                <ConfigurationSidebar
                    widget={selectedWidget}
                    onUpdate={handleWidgetUpdate}
                    onClose={handleCloseConfig}
                    onDelete={handleDeleteWidget}
                />
            )}
        </DndContext>
    );
};

export default DashboardEditMode;