import React, { useState } from 'react';
// import { auth } from '../firebase';
// import { updatePassword, reauthenticateWithCredential, EmailAuthProvider, updateProfile, updateEmail } from 'firebase/auth';
import styled from '@emotion/styled';
import { useTeamMembers, useDataSources, useRemoveTeamMember, useRemoveDataSource, useUserDatabases, useCreateUserDatabase, useSchemaInfos } from '../hooks/useQueries';
import TableViewModal from './TableViewModal';

const PageWrapper = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding-top: 4rem;
`;

const PageContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f8f9fa;
  min-height: calc(100vh - 4rem);
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 1.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-weight: 500;
  color: #666;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Button = styled.button`
  background: #007bff;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s;
  
  &:hover {
    background: #0056b3;
  }
  
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const TabsContainer = styled.div`
  margin-bottom: 2rem;
`;

const TabsList = styled.div`
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 2rem;
`;

const TabButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  color: ${props => props.selected ? '#111827' : '#6B7280'};
  border-bottom: 2px solid ${props => props.selected ? '#007bff' : 'transparent'};
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #111827;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
`;

const Th = styled.th`
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid #e5e7eb;
  color: #6b7280;
  font-weight: 500;
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
`;

const ActionButton = styled(Button)`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  background: ${props => props.variant === 'danger' ? '#dc3545' : '#007bff'};
  
  &:hover {
    background: ${props => props.variant === 'danger' ? '#c82333' : '#0056b3'};
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 2rem;
  color: #6b7280;
`;

const AddButton = styled(Button)`
  margin-bottom: 1rem;
`;

const DatabaseCard = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: white;
`;

const DatabaseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const DatabaseStats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
`;

const StatItem = styled.div`
  text-align: center;
  
  .value {
    font-size: 1.5rem;
    font-weight: 500;
    color: #007bff;
  }
  
  .label {
    color: #666;
    font-size: 0.875rem;
  }
`;

const AccountSettings = () => {
    const auth = {
        currentUser: {
            displayName: 'John Doe',
            email: 'john@example.com',
            uid: '123456789',
            updateProfile: async () => { },
            updateEmail: async () => { },
            updatePassword: async () => { }
        }
    };

    const useRemoveTeamMember = () => {

    };

    const useRemoveDataSource = () => {

    };

    const updateProfile = () => {

    };

    const updateEmail = () => {

    };

    const updatePassword = () => {

    };

    const { data: teamMembers = [], isLoading: loadingTeam } = useTeamMembers();
    const { data: dataSources = [], isLoading: loadingSources } = useDataSources();

    const { data: userDatabases = [], isLoading: loadingDatabases } = useUserDatabases();
    const createUserDatabase = useCreateUserDatabase();

    // Get all schema info at once
    const schemaInfos = useSchemaInfos(userDatabases?.map(db => db.schema_name));

    const removeTeamMember = useRemoveTeamMember();
    const removeDataSource = useRemoveDataSource();

    const [displayName, setDisplayName] = useState(auth.currentUser?.displayName || '');
    const [email, setEmail] = useState(auth.currentUser?.email || '');

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [selectedTable, setSelectedTable] = useState(null);

    const tabs = ['Profile Information', 'Password & Security', 'Team Members', 'Data Sources', 'Databases'];

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            const updates = [];

            if (displayName !== auth.currentUser?.displayName) {
                updates.push(updateProfile(auth.currentUser, { displayName }));
            }

            if (email !== auth.currentUser?.email) {
                updates.push(updateEmail(auth.currentUser, email));
            }

            await Promise.all(updates);
            setSuccess('Profile updated successfully!');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        setIsLoading(true);
        setError('');
        setSuccess('');

        try {
            // const credential = EmailAuthProvider.credential(
            //     auth.currentUser.email,
            //     currentPassword
            // );
            // await reauthenticateWithCredential(auth.currentUser, credential);
            // await updatePassword(auth.currentUser, newPassword);

            setSuccess('Password updated successfully!');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveTeamMember = async (id) => {
        try {
            await removeTeamMember.mutateAsync(id);
        } catch (error) {
            console.error('Failed to remove team member:', error);
            // Handle error (show toast, etc.)
        }
    };

    const handleRemoveDataSource = async (id) => {
        try {
            await removeDataSource.mutateAsync(id);
        } catch (error) {
            console.error('Failed to remove data source:', error);
            // Handle error (show toast, etc.)
        }
    };

    return (
        <PageWrapper>
            <PageContainer>
                <Card>
                    <Title>Account Settings</Title>
                    <TabsContainer>
                        <TabsList>
                            {tabs.map((tab, index) => (
                                <TabButton
                                    key={tab}
                                    selected={activeTab === index}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab}
                                </TabButton>
                            ))}
                        </TabsList>
                    </TabsContainer>

                    {/* Profile Tab */}
                    {activeTab === 0 && (
                        <Form onSubmit={handleProfileUpdate} style={{ maxWidth: '400px' }}>
                            <FormGroup>
                                <Label>Display Name</Label>
                                <Input
                                    type="text"
                                    value={displayName}
                                    onChange={(e) => setDisplayName(e.target.value)}
                                    placeholder="Enter your name"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                />
                            </FormGroup>
                            {error && <div style={{ color: '#dc3545' }}>{error}</div>}
                            {success && <div style={{ color: '#28a745' }}>{success}</div>}
                            <Button type="submit" disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save Changes'}
                            </Button>
                        </Form>
                    )}

                    {/* Password Tab */}
                    {activeTab === 1 && (
                        <Form onSubmit={handleChangePassword} style={{ maxWidth: '400px' }}>
                            <FormGroup>
                                <Label>Current Password</Label>
                                <Input
                                    type="password"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>New Password</Label>
                                <Input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Confirm New Password</Label>
                                <Input
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </FormGroup>
                            {error && <div style={{ color: '#dc3545' }}>{error}</div>}
                            {success && <div style={{ color: '#28a745' }}>{success}</div>}
                            <Button type="submit" disabled={isLoading}>
                                {isLoading ? 'Updating...' : 'Update Password'}
                            </Button>
                        </Form>
                    )}

                    {/* Team Members Tab */}
                    {activeTab === 2 && (
                        <>
                            <AddButton onClick={() => alert('Add team member modal')}>
                                <span className="material-icons" style={{ marginRight: '0.5rem' }}>person_add</span>
                                Add Team Member
                            </AddButton>

                            <Table>
                                <thead>
                                    <tr>
                                        <Th>Email</Th>
                                        <Th>Role</Th>
                                        <Th>Actions</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {teamMembers.map(member => (
                                        <tr key={member.id}>
                                            <Td>{member.email}</Td>
                                            <Td>{member.role}</Td>
                                            <Td>
                                                <ActionButton
                                                    variant="danger"
                                                    onClick={() => handleRemoveTeamMember(member.id)}
                                                >
                                                    Remove
                                                </ActionButton>
                                            </Td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {teamMembers.length === 0 && (
                                <EmptyState>No team members added yet</EmptyState>
                            )}
                        </>
                    )}

                    {/* Data Sources Tab */}
                    {activeTab === 3 && (
                        <>
                            <AddButton onClick={() => alert('Add data source modal')}>
                                <span className="material-icons" style={{ marginRight: '0.5rem' }}>add_circle</span>
                                Add Data Source
                            </AddButton>

                            <Table>
                                <thead>
                                    <tr>
                                        <Th>Name</Th>
                                        <Th>Type</Th>
                                        <Th>Status</Th>
                                        <Th>Actions</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataSources.map(source => (
                                        <tr key={source.id}>
                                            <Td>{source.name}</Td>
                                            <Td>{source.type}</Td>
                                            <Td>
                                                <span style={{
                                                    color: source.status === 'Connected' ? '#28a745' : '#dc3545',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.25rem'
                                                }}>
                                                    <span className="material-icons" style={{ fontSize: '0.875rem' }}>
                                                        {source.status === 'Connected' ? 'check_circle' : 'error'}
                                                    </span>
                                                    {source.status}
                                                </span>
                                            </Td>
                                            <Td>
                                                <ActionButton
                                                    variant="danger"
                                                    onClick={() => handleRemoveDataSource(source.id)}
                                                >
                                                    Remove
                                                </ActionButton>
                                            </Td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {dataSources.length === 0 && (
                                <EmptyState>No data sources connected yet</EmptyState>
                            )}
                        </>
                    )}

                    {/* Databases Tab */}
                    {activeTab === 4 && (
                        <>
                            <AddButton
                                onClick={() => {
                                    const name = prompt('Enter database name:');
                                    if (name) {
                                        createUserDatabase.mutate(name, {
                                            onSuccess: () => {
                                                setSuccess('Database created successfully!');
                                            },
                                            onError: (error) => {
                                                setError(error.message);
                                            }
                                        });
                                    }
                                }}
                                disabled={createUserDatabase.isLoading}
                            >
                                <span className="material-icons" style={{ marginRight: '0.5rem' }}>add_circle</span>
                                {createUserDatabase.isLoading ? 'Creating...' : 'Create New Database'}
                            </AddButton>

                            {loadingDatabases ? (
                                <EmptyState>Loading databases...</EmptyState>
                            ) : (
                                <>
                                    {userDatabases?.map((db, index) => {
                                        const { data: schemaInfo } = schemaInfos[index] || {};
                                        
                                        return (
                                            <DatabaseCard key={db.id}>
                                                <DatabaseHeader>
                                                    <div>
                                                        <h3 style={{ margin: 0 }}>{db.schema_name}</h3>
                                                        <p style={{ margin: '0.5rem 0 0 0', color: '#666' }}>
                                                            Created: {new Date(db.created_at).toLocaleDateString()}
                                                        </p>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                        <ActionButton
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(db.schema_name);
                                                                setSuccess('Schema name copied!');
                                                            }}
                                                        >
                                                            <span className="material-icons">content_copy</span>
                                                        </ActionButton>
                                                        <ActionButton
                                                            onClick={() => {
                                                                // Open table list modal
                                                                alert('View Tables');
                                                            }}
                                                        >
                                                            <span className="material-icons">table_view</span>
                                                        </ActionButton>
                                                    </div>
                                                </DatabaseHeader>
                                                
                                                <DatabaseStats>
                                                    <StatItem>
                                                        <div className="value">{schemaInfo?.table_count || 0}</div>
                                                        <div className="label">Tables</div>
                                                    </StatItem>
                                                    <StatItem>
                                                        <div className="value">{schemaInfo?.total_rows || 0}</div>
                                                        <div className="label">Total Rows</div>
                                                    </StatItem>
                                                    <StatItem>
                                                        <div className="value">{schemaInfo?.size_mb?.toFixed(2) || 0} MB</div>
                                                        <div className="label">Size</div>
                                                    </StatItem>
                                                </DatabaseStats>

                                                {/* Table List */}
                                                {schemaInfo?.tables && (
                                                    <div style={{ marginTop: '1rem' }}>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <Th>Table Name</Th>
                                                                    <Th>Rows</Th>
                                                                    <Th>Size</Th>
                                                                    <Th>Actions</Th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {schemaInfo.tables.map(table => (
                                                                    <tr key={table.name}>
                                                                        <Td>{table.name}</Td>
                                                                        <Td>{table.row_count}</Td>
                                                                        <Td>{table.size_mb.toFixed(2)} MB</Td>
                                                                        <Td>
                                                                            <ActionButton
                                                                                onClick={() => {
                                                                                    setSelectedTable({
                                                                                        schemaName: db.schema_name,
                                                                                        tableName: table.name
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <span className="material-icons">visibility</span>
                                                                            </ActionButton>
                                                                        </Td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                )}
                                            </DatabaseCard>
                                        );
                                    })}

                                    {userDatabases.length === 0 && (
                                        <EmptyState>No databases created yet</EmptyState>
                                    )}
                                </>
                            )}

                            {error && <div style={{ color: '#dc3545', marginTop: '1rem' }}>{error}</div>}
                            {success && <div style={{ color: '#28a745', marginTop: '1rem' }}>{success}</div>}
                        </>
                    )}
                </Card>
            </PageContainer>
            {selectedTable && (
                <TableViewModal
                    schemaName={selectedTable.schemaName}
                    tableName={selectedTable.tableName}
                    onClose={() => setSelectedTable(null)}
                />
            )}
        </PageWrapper>
    );
};

export default AccountSettings;
