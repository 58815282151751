import React from 'react';
import styled from '@emotion/styled';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
`;

const Dialog = styled.div`
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  margin: 0 0 1rem 0;
  color: #333;
`;

const Message = styled.p`
  margin: 0 0 1.5rem 0;
  color: #666;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  border: none;
  
  ${props => props.variant === 'danger' ? `
    background: #dc3545;
    color: white;
    &:hover {
      background: #c82333;
    }
  ` : `
    background: #e9ecef;
    color: #333;
    &:hover {
      background: #dee2e6;
    }
  `}
`;

const ConfirmDialog = ({ title, message, onConfirm, onCancel }) => {
  return (
    <Overlay onClick={onCancel}>
      <Dialog onClick={e => e.stopPropagation()}>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <ButtonGroup>
          <Button onClick={onCancel}>Cancel</Button>
          <Button variant="danger" onClick={onConfirm}>
            Confirm
          </Button>
        </ButtonGroup>
      </Dialog>
    </Overlay>
  );
};

export default ConfirmDialog;
