import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSpring, animated } from '@react-spring/web';

const IllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const CarouselTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => props.currentSlide * 100}%);
`;

const CarouselSlide = styled.div`
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CarouselDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.active ? '#007bff' : '#e0e0e0'};
  transition: background 0.3s ease;
`;

const IllustrationSVG = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease;

  svg {
    width: 100%;
    height: auto;
  }
`;

const MarketingCopy = styled.div`
  max-width: 1200px;
  text-align: center;
  
  h2 {
    color: #1a365d;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    background: linear-gradient(120deg, #1a365d, #2563eb);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  p {
    color: #4a5568;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }

  ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1rem;
    margin-top: 2rem;
  }

  li {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem;
    background: white;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05),
                0 6px 6px rgba(0, 0, 0, 0.03);
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    &::before {
      content: "✓";
      color: #2563eb;
      font-weight: bold;
    }
  }
`;

const CarouselControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  max-width: 1200px;
`;

const AnimatedNumber = ({ value, prefix = '', suffix = '', isVisible }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: isVisible ? value : 0,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 }
  });

  return <animated.tspan>
    {number.to(n => `${prefix}${Math.floor(n).toLocaleString()}${suffix}`)}
  </animated.tspan>;
};

const AnimatedLineChart = ({ isVisible }) => {
  const { dashOffset } = useSpring({
    from: { dashOffset: 1000 },
    to: { dashOffset: isVisible ? 0 : 1000 },
    delay: 500,
    config: { tension: 80, friction: 20 }
  });

  const { dashOffsetGreen } = useSpring({
    from: { dashOffsetGreen: 1000 },
    to: { dashOffsetGreen: isVisible ? 0 : 1000 },
    delay: 800,
    config: { tension: 80, friction: 20 }
  });

  return (
    <>
      {/* Chart Lines */}
      <animated.path
        d="M140 520 Q340 400, 440 450 T700 380"
        stroke="#6366F1"
        strokeWidth="3"
        fill="none"
        strokeDasharray={1000}
        strokeDashoffset={dashOffset}
      />
      <animated.path
        d="M140 540 Q340 480, 440 500 T700 420"
        stroke="#10B981"
        strokeWidth="3"
        fill="none"
        strokeDasharray={1000}
        strokeDashoffset={dashOffsetGreen}
      />
    </>
  );
};

const AnimatedBar = ({ value, label, index, isVisible }) => {
  const { y } = useSpring({
    from: { y: 580 },
    to: { y: isVisible ? 580 - (value * 0.5) : 580 },
    delay: index * 100,
    config: { tension: 120, friction: 14 }
  });

  const { height } = useSpring({
    from: { height: 0 },
    to: { height: isVisible ? value * 0.5 : 0 },
    delay: index * 100,
    config: { tension: 120, friction: 14 }
  });

  return (
    <g transform={`translate(${200 + index * 80}, 0)`}>
      <animated.rect
        x="0"
        y={y.to(v => v)}
        width="50"
        height={height.to(h => h)}
        fill="#10B981"
        fillOpacity="0.8"
        rx="4"
      />
      <text
        x="25"
        y="600"
        textAnchor="middle"
        fill="#666"
        fontSize="12"
      >
        {label}
      </text>
    </g>
  );
};

const DashboardIllustration = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentSlide((prev) => (prev < illustrations.length - 1 ? prev + 1 : 0));
        }, 5000);

        return () => clearInterval(timer);
    }, []);

    const illustrations = [
        {
            title: "Analytics Dashboard",
            render: () => (
                <svg viewBox="0 0 1200 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* Sidebar */}
                    <path 
                        d={`
                            M ${40 + 16} 20 
                            h 64 
                            v 760 
                            h -64 
                            a 16 16 0 0 1 -16 -16 
                            v -728 
                            a 16 16 0 0 1 16 -16
                        `}
                        fill="url(#analyticsGradient)"
                    />
                    {/* Main Content - rounded corners only on right side */}
                    <path 
                        d={`
                            M 120 20 
                            h 1024 
                            a 16 16 0 0 1 16 16 
                            v 728 
                            a 16 16 0 0 1 -16 16 
                            h -1024 
                            v -760
                        `}
                        fill="url(#gradient)"
                        stroke="#E9ECEF"
                        strokeWidth="2"
                    />
                    
                    {/* Main Dashboard Content - adjusted transform */}
                    <g transform="translate(80, 0)">
                        <g className="main-dashboard">
                            {/* Analytics Stats */}
                            <g transform="translate(80, 60)">
                                {[
                                    { label: 'Active Users', value: '2,845', change: '+15.2%' },
                                    { label: 'Page Views', value: '18.2K', change: '+8.1%' },
                                    { label: 'Bounce Rate', value: '42.3%', change: '-3.8%' },
                                    { label: 'Avg. Session', value: '4:23', change: '+12.5%' }
                                ].map((stat, i) => (
                                    <g key={i} transform={`translate(${i * 260}, 0)`}>
                                        <rect width="240" height="140" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                                        <text x="20" y="40" fill="#666" fontSize="14">{stat.label}</text>
                                        <text x="20" y="80" fill="#333" fontSize="24" fontWeight="bold">
                                            <AnimatedNumber 
                                                value={parseFloat(stat.value.replace(/[^0-9.-]/g, ''))}
                                                prefix={stat.value.startsWith('$') ? '$' : ''}
                                                suffix={stat.value.includes('K') ? 'K' : '%'}
                                                isVisible={currentSlide === 0}
                                            />
                                        </text>
                                        <text x="20" y="110" fill={stat.change.startsWith('+') ? '#10B981' : '#EF4444'} fontSize="14">{stat.change}</text>
                                    </g>
                                ))}
                            </g>

                            {/* Main Chart */}
                            <rect x="80" y="220" width="720" height="400" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                            <text x="100" y="260" fill="#333" fontSize="20" fontWeight="bold">User Activity</text>
                            
                            {/* Chart Grid Lines */}
                            {[0, 1, 2, 3, 4].map(i => (
                                <line 
                                    key={i}
                                    x1="140"
                                    y1={320 + i * 60}
                                    x2="700"
                                    y2={320 + i * 60}
                                    stroke="#E2E8F0"
                                    strokeWidth="1"
                                />
                            ))}

                            {/* Chart Lines */}
                            <AnimatedLineChart isVisible={currentSlide === 0} />

                            {/* Chart Labels */}
                            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, i) => (
                                <text 
                                    key={i}
                                    x={140 + i * ((560) / 6)}
                                    y="580"
                                    fill="#666"
                                    fontSize="12"
                                    textAnchor="middle"
                                >
                                    {day}
                                </text>
                            ))}

                            {/* Side Panel - Top Sources */}
                            <rect x="820" y="220" width="280" height="400" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                            <text x="840" y="260" fill="#333" fontSize="16" fontWeight="bold">Top Sources</text>
                            
                            {/* Source List */}
                            {[
                                { name: 'Direct', value: '42%', color: '#6366F1' },
                                { name: 'Organic', value: '28%', color: '#10B981' },
                                { name: 'Referral', value: '18%', color: '#F59E0B' },
                                { name: 'Social', value: '12%', color: '#EF4444' }
                            ].map((source, i) => (
                                <g key={i} transform={`translate(840, ${300 + i * 70})`}>
                                    <rect x="0" y="0" width="240" height="50" rx="8" fill="#F8FAFC" />
                                    <circle cx="25" cy="25" r="15" fill={source.color} opacity="0.2" />
                                    <text x="60" y="20" fill="#333" fontSize="14">{source.name}</text>
                                    <text x="60" y="38" fill="#666" fontSize="12">{source.value}</text>
                                    <rect x="160" y="15" width="60" height="20" rx="10" fill={source.color} opacity="0.1" />
                                    <text x="190" y="29" fill={source.color} fontSize="12" textAnchor="middle">{source.value}</text>
                                </g>
                            ))}
                        </g>
                    </g>

                    {/* Gradient Definition */}
                    <defs>
                        <linearGradient id="analyticsGradient" x1="0" y1="0" x2="0" y2="760">
                            <stop offset="0%" stopColor="#6366F1" />
                            <stop offset="100%" stopColor="#4338CA" />
                        </linearGradient>
                    </defs>

                    {/* Analytics Label */}
                    <g transform="translate(160, 680)">
                        <rect
                            width="160"
                            height="50"
                            rx="8"
                            fill="#6366F1"
                            fillOpacity="0.1"
                        />
                        <text
                            x="80"
                            y="32"
                            textAnchor="middle"
                            fill="#6366F1"
                            fontSize="20"
                            fontWeight="bold"
                        >
                            Analytics
                        </text>
                    </g>
                </svg>
            )
        },
        {
            title: "Payments Dashboard",
            render: () => (
                <svg viewBox="0 0 1200 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* Sidebar */}
                    <path 
                        d={`
                            M ${40 + 16} 20 
                            h 64 
                            v 760 
                            h -64 
                            a 16 16 0 0 1 -16 -16 
                            v -728 
                            a 16 16 0 0 1 16 -16
                        `}
                        fill="url(#paymentsGradient)"
                    />
                    {/* Main Content - rounded corners only on right side */}
                    <path 
                        d={`
                            M 120 20 
                            h 1024 
                            a 16 16 0 0 1 16 16 
                            v 728 
                            a 16 16 0 0 1 -16 16 
                            h -1024 
                            v -760
                        `}
                        fill="url(#gradient)"
                        stroke="#E9ECEF"
                        strokeWidth="2"
                    />
                    
                    {/* Main Dashboard Content - adjusted transform */}
                    <g transform="translate(80, 0)">
                        <g className="main-dashboard">
                            {/* Payment Stats */}
                            <g transform="translate(80, 60)">
                                {[
                                    { label: 'Revenue', value: '$12,845', change: '+12.5%' },
                                    { label: 'Transactions', value: '1,234', change: '+8.3%' },
                                    { label: 'Avg. Value', value: '$94.2', change: '+5.7%' },
                                    { label: 'Customers', value: '892', change: '+15.2%' }
                                ].map((stat, i) => (
                                    <g key={i} transform={`translate(${i * 260}, 0)`}>
                                        <rect width="240" height="140" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                                        <text x="20" y="40" fill="#666" fontSize="14">{stat.label}</text>
                                        <text x="20" y="80" fill="#333" fontSize="24" fontWeight="bold">
                                            <AnimatedNumber 
                                                value={parseFloat(stat.value.replace(/[^0-9.-]/g, ''))}
                                                prefix={stat.value.startsWith('$') ? '$' : ''}
                                                suffix={stat.value.includes('K') ? 'K' : '%'}
                                                isVisible={currentSlide === 1}
                                            />
                                        </text>
                                        <text x="20" y="110" fill="#10B981" fontSize="14">{stat.change}</text>
                                    </g>
                                ))}
                            </g>

                            {/* Payment Chart */}
                            <rect x="80" y="220" width="720" height="400" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                            <text x="100" y="260" fill="#333" fontSize="20" fontWeight="bold">Payment Volume</text>

                            {/* Chart Grid Lines */}
                            {[0, 1, 2, 3, 4].map(i => (
                                <line 
                                    key={i}
                                    x1="180"
                                    y1={320 + i * 60}
                                    x2="700"
                                    y2={320 + i * 60}
                                    stroke="#E2E8F0"
                                    strokeWidth="1"
                                />
                            ))}

                            {/* Bar Chart */}
                            {[
                                { value: 280, label: 'Mon' },
                                { value: 420, label: 'Tue' },
                                { value: 350, label: 'Wed' },
                                { value: 490, label: 'Thu' },
                                { value: 380, label: 'Fri' },
                                { value: 320, label: 'Sat' },
                                { value: 450, label: 'Sun' }
                            ].map((item, i) => (
                                <AnimatedBar key={i} {...item} index={i} isVisible={currentSlide === 1} />
                            ))}

                            {/* Y-axis Labels */}
                            {[0, 100, 200, 300, 400, 500].map((value, i) => (
                                <text
                                    key={i}
                                    x="160"
                                    y={580 - (value * 0.5)}
                                    textAnchor="end"
                                    fill="#666"
                                    fontSize="12"
                                >
                                    ${value}
                                </text>
                            ))}

                            {/* Recent Transactions */}
                            <rect x="820" y="220" width="280" height="400" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                            <text x="840" y="260" fill="#333" fontSize="16" fontWeight="bold">Recent Transactions</text>
                            {[0, 1, 2, 3].map(i => (
                                <g key={i} transform={`translate(840, ${300 + i * 70})`}>
                                    <rect x="0" y="0" width="240" height="50" rx="8" fill="#F8FAFC" />
                                    <circle cx="25" cy="25" r="15" fill="#10B981" opacity="0.2" />
                                    <text x="60" y="20" fill="#333" fontSize="14">Payment #{i + 1}</text>
                                    <text x="60" y="38" fill="#666" fontSize="12">${Math.floor(Math.random() * 1000)}.00</text>
                                    <text x="200" y="30" fill="#10B981" fontSize="14" textAnchor="end">Success</text>
                                </g>
                            ))}
                        </g>
                    </g>

                    {/* Gradient Definition */}
                    <defs>
                        <linearGradient id="paymentsGradient" x1="0" y1="0" x2="0" y2="760">
                            <stop offset="0%" stopColor="#10B981" />
                            <stop offset="100%" stopColor="#059669" />
                        </linearGradient>
                    </defs>

                    {/* Payments Label */}
                    <g transform="translate(160, 680)">
                        <rect
                            width="160"
                            height="50"
                            rx="8"
                            fill="#10B981"
                            fillOpacity="0.1"
                        />
                        <text
                            x="80"
                            y="32"
                            textAnchor="middle"
                            fill="#10B981"
                            fontSize="20"
                            fontWeight="bold"
                        >
                            Finance
                        </text>
                    </g>
                </svg>
            )
        },
        {
            title: "Order Management",
            render: () => (
                <svg viewBox="0 0 1200 800" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {/* Sidebar */}
                    <path 
                        d={`
                            M ${40 + 16} 20 
                            h 64 
                            v 760 
                            h -64 
                            a 16 16 0 0 1 -16 -16 
                            v -728 
                            a 16 16 0 0 1 16 -16
                        `}
                        fill="url(#ordersGradient)"
                    />
                    {/* Main Content - rounded corners only on right side */}
                    <path 
                        d={`
                            M 120 20 
                            h 1024 
                            a 16 16 0 0 1 16 16 
                            v 728 
                            a 16 16 0 0 1 -16 16 
                            h -1024 
                            v -760
                        `}
                        fill="url(#gradient)"
                        stroke="#E9ECEF"
                        strokeWidth="2"
                    />
                    
                    {/* Main Dashboard Content - adjusted transform */}
                    <g transform="translate(80, 0)">
                        <g className="main-dashboard">
                            {/* Order Stats */}
                            <g transform="translate(80, 60)">
                                {[
                                    { label: 'Total Orders', value: '1,845', change: '+8.5%' },
                                    { label: 'Processing', value: '234', change: '-2.3%' },
                                    { label: 'Completed', value: '1,592', change: '+12.7%' },
                                    { label: 'Cancelled', value: '19', change: '-5.2%' }
                                ].map((stat, i) => (
                                    <g key={i} transform={`translate(${i * 260}, 0)`}>
                                        <rect width="240" height="140" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                                        <text x="20" y="40" fill="#666" fontSize="14">{stat.label}</text>
                                        <text x="20" y="80" fill="#333" fontSize="24" fontWeight="bold">
                                            <AnimatedNumber 
                                                value={parseFloat(stat.value.replace(/[^0-9.-]/g, ''))}
                                                prefix={stat.value.startsWith('$') ? '$' : ''}
                                                suffix={stat.value.includes('K') ? 'K' : '%'}
                                                isVisible={currentSlide === 2}
                                            />
                                        </text>
                                        <text x="20" y="110" fill={stat.change.startsWith('+') ? '#10B981' : '#EF4444'} fontSize="14">{stat.change}</text>
                                    </g>
                                ))}
                            </g>

                            {/* Order Table */}
                            <rect x="80" y="220" width="1020" height="400" rx="12" fill="white" stroke="#DEE2E6" strokeWidth="2" filter="url(#shadow)" />
                            <text x="100" y="260" fill="#333" fontSize="20" fontWeight="bold">Recent Orders</text>
                            
                            {/* Table Headers */}
                            <g transform="translate(100, 290)">
                                {['Order ID', 'Customer', 'Status', 'Total', 'Date'].map((header, i) => (
                                    <text key={i} x={i * 200} y="0" fill="#666" fontSize="14">{header}</text>
                                ))}
                            </g>

                            {/* Table Rows */}
                            {[0, 1, 2, 3, 4].map(i => (
                                <g key={i} transform={`translate(100, ${330 + i * 50})`}>
                                    <text x="0" y="20" fill="#333" fontSize="14">#ORD-{1000 + i}</text>
                                    <text x="200" y="20" fill="#333" fontSize="14">Customer {i + 1}</text>
                                    <rect x="400" y="5" width="80" height="24" rx="12" fill={['#DCF5E6', '#FEF3C7', '#FEE2E2'][i % 3]} />
                                    <text x="440" y="20" fill={['#059669', '#D97706', '#DC2626'][i % 3]} fontSize="12" textAnchor="middle">
                                        {['Completed', 'Processing', 'Cancelled'][i % 3]}
                                    </text>
                                    <text x="600" y="20" fill="#333" fontSize="14">${Math.floor(Math.random() * 1000)}.00</text>
                                    <text x="800" y="20" fill="#666" fontSize="14">2024-03-{10 + i}</text>
                                </g>
                            ))}
                        </g>
                    </g>

                    {/* Gradient Definition */}
                    <defs>
                        <linearGradient id="ordersGradient" x1="0" y1="0" x2="0" y2="760">
                            <stop offset="0%" stopColor="#F59E0B" />
                            <stop offset="100%" stopColor="#D97706" />
                        </linearGradient>
                    </defs>

                    {/* Orders Label */}
                    <g transform="translate(160, 680)">
                        <rect
                            width="160"
                            height="50"
                            rx="8"
                            fill="#F59E0B"
                            fillOpacity="0.1"
                        />
                        <text
                            x="80"
                            y="32"
                            textAnchor="middle"
                            fill="#F59E0B"
                            fontSize="20"
                            fontWeight="bold"
                        >
                            E-commerce
                        </text>
                    </g>
                </svg>
            )
        }
    ];

    return (
        <IllustrationContainer>
            <CarouselContainer>
                <CarouselTrack currentSlide={currentSlide}>
                    {illustrations.map((illustration, index) => (
                        <CarouselSlide key={index}>
                            <IllustrationSVG>
                                {illustration.render()}
                            </IllustrationSVG>
                        </CarouselSlide>
                    ))}
                </CarouselTrack>
            </CarouselContainer>
            <CarouselControls>
                <CarouselDots>
                    {illustrations.map((_, index) => (
                        <Dot
                            key={index}
                            active={currentSlide === index}
                        />
                    ))}
                </CarouselDots>
            </CarouselControls>
            <MarketingCopy>
                <h2>Build Customer Dashboards in Minutes</h2>
                <p>Build scalable MVPs faster with our simple and intuitive drag-and-drop dashboard builder. Get your product to market quickly while ensuring your users can grow with your success. Launch your MVP in minutes, not months.</p>
                <ul>
                    <li>Intuitive Drag & Drop Builder</li>
                    <li>Lightning Fast UI</li>
                    <li>Scalable User Growth</li>
                    <li>User Authentication</li>
                    <li>Built-in Database</li>
                    <li>50+ Customizable Widgets</li>
                </ul>
            </MarketingCopy>
        </IllustrationContainer>
    );
};

export default DashboardIllustration;