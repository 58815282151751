import React from 'react';
import styled from '@emotion/styled';

const HeaderContainer = styled.div`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 1100;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const MenuButton = styled.button`
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AppTitle = styled.h1`
  margin: 0;
  color: #1a1a1a;
  font-size: 1.25rem;
  font-weight: 500;
`;

const AppHeader = ({ isVisible, onMenuClick, title }) => {
  if (!isVisible) return null;
  
  return (
    <HeaderContainer>
      <MenuButton onClick={onMenuClick}>
        <span className="material-icons">menu</span>
      </MenuButton>
      <AppTitle>{title}</AppTitle>
    </HeaderContainer>
  );
};

export default AppHeader;