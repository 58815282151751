import React from 'react';
import styled from '@emotion/styled';
import {
  ConfigField,
  Label,
  Input,
  ColorInput,
  IconSelectorWrapper
} from './shared/ConfigurationStyles';
import IconSelector from './IconSelector';
import DataSourceSelect from './shared/DataSourceSelect';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ConfigInput = styled(Input)`
  border: none;
  background: #f8f9fa;
  transition: background 0.2s ease;
  
  &:hover {
    background: #f1f3f5;
  }
  
  &:focus {
    background: #f1f3f5;
    box-shadow: none;
  }
`;

const ConfigPrefixSuffixInput = styled(ConfigInput)`
  width: 60px !important;
  text-align: center;
  
  &::placeholder {
    font-size: 0.75rem;
    opacity: 0.6;
  }
`;

const ConfigChangeInput = styled(ConfigInput)`
  width: 80px;
  color: ${props => props.isPositive ? '#22c55e' : '#ef4444'};
  text-align: center;
`;

const ChangeInput = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  input {
    width: 80px;
    color: ${props => props.isPositive ? '#22c55e' : '#ef4444'};
  }
  
  svg {
    color: ${props => props.isPositive ? '#22c55e' : '#ef4444'};
  }
`;

const StatisticsConfiguration = ({ tempConfig, handleChange }) => {
  const isPositive = (tempConfig?.change || 0) >= 0;

  return (
    <>
      <DataSourceSelect 
        selectedSource={tempConfig.dataSource}
        onSourceSelect={(source) => handleChange('dataSource', source)}
      />

      <ConfigField>
        <Label>Color</Label>
        <ColorInput
          type="color"
          value={tempConfig?.color || '#333333'}
          onChange={(e) => handleChange('color', e.target.value)}
        />
      </ConfigField>
    </>
  );
};

export default StatisticsConfiguration;